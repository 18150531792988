import React, { FC } from "react";

interface IIconMoveNextProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconMoveNext: FC<IIconMoveNextProps> = ({
  className = "",
  width = 20,
  height = 20,
  opacity = 0.5,
  fill = "#4874E4",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={`Icon__MoveNext color-fill ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M13.1055 10L2.5 2.5V17.5L13.1055 10ZM17.5 2.5H14.9805V17.5H17.5V2.5Z" />
    </g>
  </svg>
);

export default IconMoveNext;
