import "form-success.scss";
import React, { FC, ReactNode } from "react";

interface IFormSuccessProps {
  onChange?(): void;
  children?: ReactNode;
  label: string;
  className?: string;
}

const FormSuccess: FC<IFormSuccessProps> = ({
  className = "",
  label = "Success",
}) => <div className={`FormSuccess ${className}`}>{label}</div>;

export default FormSuccess;
