import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { Application } from "../../store";
import { when } from "../../util/when";
import "./finalize.scss";

import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import { ENV } from "../../env";
import Button from "../../ui/button";
import LoadingAnimation from "../../ui/loading";
import { PaymentMethodPicker } from "../../ui/payment/payment-methods";
import AddPaymentMethod from "../account/add-payment-method";

const stripePromise = loadStripe(ENV.stripeKey);

interface IFinalizeMembershipCheckoutProps {
  onComplete?(): void;
  className?: string;
}

// Props
interface IFinalizeMembershipFormProps {
  onComplete?(): void;
  className?: string;
}

// Using this as a guide
const FinalizeMembershipForm: FC<IFinalizeMembershipFormProps> = ({
  className = "",
  onComplete,
}) => {
  const [newCard, setNewCard] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);

  const showNewCardForm = () => {
    setNewCard(true);
  };

  const setDefault = () => {
    Application.domain.setSelectedPaymentId(
      Application.domain.defaultPaymentId
    );
  };

  const hideNewCardForm = () => {
    setNewCard(false);
  };

  const handleSubmit = async () => {
    try {
      setProcessing(true);
      await Application.ui.account.createSubscription();
      setProcessing(false);
      onComplete?.();
    } catch (error) {
      setError(error.message);
      setProcessing(false);
    }
  };

  useEffect(() => {
    // Set default payment method
    setDefault();
  }, []);

  return (
    <div className={`SelectPaymentMethod ${className}`}>
      {when(error, <div className="SelectPaymentMethod--error">{error}</div>)}
      {when(processing, <LoadingAnimation absolute={true} />)}
      {when(
        newCard === false,
        <div className="SelectPaymentMethod--methods">
          <PaymentMethodPicker />
          <div className="SelectPaymentMethod--newCard">
            <a onClick={showNewCardForm}>Use new card</a>
          </div>
          <Button
            label="Pay"
            onClick={handleSubmit}
            className="gold"
            block={true}
          />
        </div>
      )}
      {when(
        newCard === true,
        <div className="SelectPaymentMethod--methods">
          <AddPaymentMethod
            action="Add card & Continue"
            onComplete={hideNewCardForm}
          />
          <div className="SelectPaymentMethod--newCard">
            <a onClick={hideNewCardForm}>Use default card</a>
          </div>
        </div>
      )}
    </div>
  );
};

const FinalizeMembershipCheckout: FC<IFinalizeMembershipCheckoutProps> = ({
  onComplete,
  className = "",
}) => {
  const [success, setSuccess] = useState(false);
  const wrapperClasses = () => {
    const classes = new Array("FinalizeMembership");
    if (className) classes.push(className);
    return classes.join(" ");
  };
  const showSuccess = () => {
    Application.domain.resetPaymentIntent();
    setSuccess(true);
  };
  const complete = () => {
    onComplete?.();
  };
  return (
    <div className={wrapperClasses()}>
      {when(
        success,
        <div className="SelectPaymentMethod--success">
          <h1>Welcome to City Golf Club</h1>
          <p>
            Your membership is now active. You may now return to the
            reservations screen to book a reservation.
          </p>
          <div>
            <Button label="Return to account" onClick={complete} />
          </div>
        </div>
      )}
      {when(
        !success,
        <div className="Due">
          <div>{Application.ui.account.paymentDescription}</div>
          <div>
            <span>{Application.ui.account.formattedAmount}</span>
            <span>{Application.ui.account.billingCycle}</span>
          </div>
        </div>
      )}
      {when(
        Application.domain.paymentIntentLoading === true,
        <LoadingAnimation absolute={true} />
      )}
      {when(Application.domain.paymentIntent?.clientSecret, (clientSecret) => (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: clientSecret }}
        >
          <FinalizeMembershipForm onComplete={showSuccess} />
        </Elements>
      ))}
    </div>
  );
};

export default observer(FinalizeMembershipCheckout);
