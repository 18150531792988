import { action, flow, makeObservable, observable } from "mobx";
import { FlowType } from "../../types";
import { ApplicationStore } from "../application.store";
import { Store } from "../store";

export class LoginUI extends Store {
  @observable loading: boolean = false;
  @observable formError: string | null = null;
  @observable verifyEmail: string | null = null;
  @observable verificationSent: boolean = false;

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  reset() {
    this.formError = null;
    this.verifyEmail = "";
    this.verificationSent = false;
  }

  isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  @flow
  *login(email: string, password: string): FlowType {
    this.loading = true;
    this.application.session.loggingIn = true;
    try {
      if (!email || !password) {
        throw new Error("You must provide an email and password");
      }
      if (this.isValidEmail(email) === false) {
        throw new Error("Please enter a valid email address");
      }
      yield this.application.session.signIn(email, password);
      yield this.application.session.updateUserDoc();
      this.loading = false;
      this.application.session.loggingIn = false;
      this.reset();
    } catch (error) {
      let errorMessage = error.message;
      switch (error.code) {
        case "auth/wrong-password":
          errorMessage = "Incorrect password";
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email";
          break;
        case "auth/user-not-found":
          errorMessage = "User not found";
          break;
        case "verify-email":
          errorMessage = null;
          this.verifyEmail = email;
          break;
      }
      this.formError = errorMessage;
      this.loading = false;
      this.application.session.loggingIn = false;
    }
  }
}
