import { observer } from "mobx-react";
import React from "react";
import LoadingAnimation from "../../../client/ui/loading";
import "./logging-in.scss";

/**
 * Login page
 */
export const LoggingInPage = observer(() => (
  <div className="LoggingInPage full-screen">
    <LoadingAnimation />
  </div>
));
