import { EnvBase } from "./env.base";

/**
 * See env.ts and env.base.ts for more information.
 */
class Env extends EnvBase {
  thirdParty: EnvBase["thirdParty"] = {};

  hostConfig: EnvBase["hostConfig"] = {
    stripe: {
      proxyHost: "",
      host: "NA",
      apiPath: "NA",
    },
    cloud: {
      proxyHost: "",
      host: "https://us-central1-citygolfclub-production.cloudfunctions.net/api",
      apiPath: "",
    },
  };

  // Live Stripe key
  stripeKey: string =
    "pk_live_51HFRE7C7JurdMxChONbT6Zm6HErehJ1lbd28fuCPfxDDU7ebJkqCSt1kp9wnC7ioUXYEz80oKGYoc9E8SvoZmJXH00E0LjdrAu";

  // Resources will come from the same domain and path as the app.
  // https://citygolfclub.co/picture.jpg
  baseStaticRoute = "";
}

export const ENV = new Env().init();
