import React, { FC } from "react";

interface IIconDeleteProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconDelete: FC<IIconDeleteProps> = ({
  className = "",
  width = 14,
  height = 14,
  opacity = 1,
  fill = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    className={`Icon__Delete color-fill ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M11.0742 2.32422V3.5H2.92578V2.32422H4.94922L5.55078 1.75H8.44922L9.05078 2.32422H11.0742ZM3.5 11.0742V4.07422H10.5V11.0742C10.5 11.3841 10.3815 11.6576 10.1445 11.8945C9.90755 12.1315 9.63411 12.25 9.32422 12.25H4.67578C4.36589 12.25 4.09245 12.1315 3.85547 11.8945C3.61849 11.6576 3.5 11.3841 3.5 11.0742Z" />
    </g>
  </svg>
);

export default IconDelete;
