import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../../client/env";
import "./form.scss";
import "./login.scss";

// UI components
import { Application } from "app/client/store";
import IconLogo from "app/client/svg/logo";
import Button from "app/client/ui/button";
import FormError from "app/client/ui/form-error";
import SubmitButton from "app/client/ui/submit-button";
import TextInput from "app/client/ui/text-input";
import { when } from "app/client/util/when";

/**
 * Login page
 */
export const LoginPage = observer(() => {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [email, setEmail] = useState(Application.ui.login.verifyEmail || "");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formRef.current) return false;
    const formData = new FormData(formRef.current);
    const email = String(formData.get("email"));
    const password = String(formData.get("password"));
    Application.ui.login.login(email, password);
    setEmail(email);
    return false;
  };

  const resendVerificationEmail = () => {
    Application.session.resendVerificationEmail();
    Application.ui.login.verifyEmail = "";
    Application.ui.login.verificationSent = true;
  };

  const inputChanged = () => {
    Application.ui.login.reset();
  };

  return (
    <div className="LoginPage FormPage full-screen">
      <div className="FormPage-left"></div>
      <div className="FormPage-right">
        <div className="FormPage-form">
          <div className="FormPage-form-title">
            <div className="FormPage-form-logo">
              <IconLogo />
            </div>
            <h1>Sign In</h1>
          </div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="FormPage-form-fields">
              {when(Application.ui.login.formError, (message) => (
                <FormError label={message} />
              ))}
              {when(Application.ui.login.verifyEmail, (email) => (
                <div className="VerifyEmail">
                  <div className="VerifyEmail-message">
                    <h2>Please verify email</h2>A verification email has been
                    sent to <b>{email}</b>. If you did not receive this email,
                    click the button below to resend.
                  </div>
                  <div className="VerifyEmail-button">
                    <Button
                      label="Resend Verification Email"
                      onClick={resendVerificationEmail}
                    />
                  </div>
                </div>
              ))}
              {when(Application.ui.login.verificationSent, () => (
                <div className="VerifyEmail">
                  <div className="VerifyEmail-message">
                    Email verification sent! Please check your email and login
                    again once verified.
                  </div>
                </div>
              ))}
              <TextInput
                name={"email"}
                placeholder={"Email Address"}
                value={email}
                onChange={inputChanged}
                autofocus
              ></TextInput>
              <TextInput
                name={"password"}
                type="password"
                placeholder={"Password"}
                onChange={inputChanged}
              ></TextInput>
              {/* <Checkbox name="keep" label="Keep me logged in" /> */}
              <SubmitButton label="Submit" name="submit" />
            </div>
          </form>
        </div>
        <div className="FormPage-links">
          <a onClick={() => navigate(ENV.routes.forgotPassword)}>
            Forgot password
          </a>
          <a onClick={() => navigate(ENV.routes.signup)}>
            Don't have an account? Sign up now.
          </a>
        </div>
        <div className="FormPage-copyright">
          Copyright © City Golf Club 2023.
        </div>
      </div>
    </div>
  );
});
