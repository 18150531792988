import { observer } from "mobx-react";
import * as React from "react";
import "./appointments.scss";

import { useNavigate } from "react-router-dom";
import { ENV } from "../../env";
import { Application } from "../../store";
import { IAppointmentRecord } from "../../store/interfaces";
import LoadingAnimation from "../../ui/loading";
import NoReservations from "../../ui/schedule/no-reservations";
import { when } from "../../util/when";
import Appointment from "./appointment";

/**
 * An example of a page being made
 */
export const Appointments = observer(() => {
  const navigate = useNavigate();
  return (
    <div className="Appointments">
      {when(
        Application.domain.loadingAppointments,
        <LoadingAnimation absolute={true} />
      )}
      {when(
        Application.domain.appointments.length === 0,
        <div className="Appointments-none">
          <NoReservations
            className="mobile-disabled"
            title="No upcoming reservations"
            description="Why wait? book a reservation today!"
            onClick={() => navigate(ENV.routes.schedule)}
          />
          <NoReservations
            className="mobile-enabled"
            title="No upcoming reservations"
            description="Why wait? book today!"
            arrowSize={20}
            onClick={() => navigate(ENV.routes.chooseLocation)}
          />
        </div>
      )}
      {Application.domain.appointments.map(
        (appointment: IAppointmentRecord, index) => (
          <Appointment appointment={appointment} key={index} />
        )
      )}
    </div>
  );
});
