import { Modal } from "app/client/ui/modal";
import "appointment.scss";
import { observer } from "mobx-react";
import moment from "moment";
import React, { FC, ReactNode, useState } from "react";
import { Application } from "../../store";
import { IAppointmentRecord } from "../../store/interfaces";
import LoadingAnimation from "../../ui/loading";
import { when } from "../../util/when";
import { ChangeAppointmentModal } from "./change-appointment";

interface IAppointmentProps {
  children?: ReactNode;
  className?: string;
  wide?: boolean;
  name?: string;
  appointment: IAppointmentRecord | null;
  allowChange?: boolean;
}

const Appointment: FC<IAppointmentProps> = ({
  className = "",
  name = "",
  wide = false,
  appointment,
  allowChange = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const changeAppointment = () => {
    setIsModalOpen(true);
  };

  // Just incase closing the modal implies further action
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getClassNames = () => {
    const names = ["Appointment"];
    if (className) names.push(className);
    if (wide) names.push("Appointment-wide");
    return names.join(" ");
  };

  const cancelled = () => {
    setCancelling(true);
  };

  // format times
  const formattedStartTime = Application.ui.appointments.displayMilitaryTime(
    Number(appointment?.startTime)
  );
  const formattedEndTime = Application.ui.appointments.displayMilitaryTime(
    Number(appointment?.endTime)
  );

  // Set background styles for the image
  const imageStyles: { backgroundImage?: string } = {};
  if (appointment?.imageUrl) {
    imageStyles.backgroundImage = `url(${appointment.imageUrl})`;
  }

  return (
    <div className={getClassNames()}>
      {when(cancelling, <LoadingAnimation absolute={true} />)}
      {when(
        isModalOpen,
        <Modal onClose={closeModal}>
          <ChangeAppointmentModal
            calendarId={Number(appointment?.calendarId)}
            appointment={appointment}
            onClose={closeModal}
            onCancel={cancelled}
          ></ChangeAppointmentModal>
        </Modal>
      )}
      <div className="Appointment-image" style={imageStyles}>
        {when(wide, <div className="LocationName">{name}</div>)}
      </div>
      <div className="Appointment__Details">
        <div className="Appointment__Details-row">
          <div className="Appointment-date">
            {moment(appointment?.date).format("dddd, MMMM Do")}
          </div>
          <div className="Appointment-time">
            {formattedStartTime} - {formattedEndTime}
          </div>
        </div>
        <div className="Appointment__Details-row">
          <div className="Appointment-bay">
            <div className="Appointment-bay-letter">
              {appointment?.staff?.substring(0, 1)}
            </div>
            <div className="Appointment-bay-name">{appointment?.staff}</div>
          </div>
          <div className="Appointment-type">
            <div>{appointment?.reason}</div>
            <div>
              <span className="dot"></span>
            </div>
            <div>{appointment?.guests} guests</div>
          </div>
        </div>
        <div className="Appointment__Details-row">
          <div className="Appointment-address">
            <div className="Appointment-address-name">
              {appointment?.location}
            </div>
            {appointment?.address}
            <br />
            {appointment?.city}, {appointment?.state} {appointment?.zip}
          </div>
          {when(
            allowChange,
            <div className="Appointment-change">
              <a onClick={changeAppointment}>Change reservation</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Appointment);
