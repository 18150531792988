import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Application } from "../../../client/store";
import { ENV } from "../../env";
import "./reschedule.scss";
import "./select-location-modal.scss";

// UI Components
import BackButton from "app/client/ui/back-button";
import Button from "app/client/ui/button";
import BayCircle from "app/client/ui/schedule/bay-circle";
import DayNav from "app/client/ui/schedule/day-nav";
import DaySlots from "app/client/ui/schedule/day-slots";
import { when } from "../../util/when";
import Appointment from "../account/appointment";
import RescheduleVariables from "./reschedule-variables";

/**
 * Book a new reservation
 */
export const ReschedulePage = observer(() => {
  const navigate = useNavigate();
  const resizeTimeout: ReturnType<typeof setTimeout> | null = null;

  // Set screen width
  Application.ui.reschedule.setScreenWidth(window.innerWidth);

  // TODO: Remove after done
  // Application.ui.reschedule.setRescheduleView(125270406);

  const handleResize = () => {
    Application.ui.reschedule.setScreenWidth(window.innerWidth);
  };

  const rescheduleAppointment = () => {
    Application.ui.reschedule.updateAppointment();
    navigate(ENV.routes.rescheduleConfirmation);
  };

  const handleMove = (direction: string) => {
    Application.ui.reschedule.move(direction);
  };

  useEffect(() => {
    // TEST: Use this to test this view with a valid calendarId
    // Application.ui.reschedule.setRescheduleView(126148785);

    // Set screensize on resize
    window.addEventListener("resize", handleResize);

    // Go back
    if (Application.ui.reschedule.calendarId <= 0) {
      navigate(ENV.routes.reservations);
    }

    // Initialize the view
    Application.ui.reschedule.setViewWindow();

    // Unmount
    return () => {
      Application.ui.reschedule.reset();
      window.removeEventListener("resize", handleResize);
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
    };
  }, []);

  // TODO: The appointment is not showing the correct address, and should
  // probably make the location name more obvious. Ontop of the picture?

  return (
    <div className="SchedulePage Reschedule">
      {when(Application.domain.testMode, <RescheduleVariables />)}
      {/* Top bar */}
      <div className="SchedulePage__Title">
        <div className="SchedulePage__Title-left">
          <BackButton route={ENV.routes.reservations} label="Cancel" />
        </div>
        <div className="SchedulePage__Title-center">Reschedule Reservation</div>
        <div className="SchedulePage__Title-right">
          <Button
            label={Application.ui.reschedule.finalizeButtonLabel}
            rightArrow={true}
            inline
            onClick={rescheduleAppointment}
            className={
              Application.ui.reschedule.readyToPay ? "active" : "deactivated"
            }
          />
        </div>
      </div>
      {/* Schedule details */}
      <div className="SchedulePage__Details">
        <div className="SchedulePage__Details-cell appointment">
          <div className="SchedulePage__Details-cell-title">
            Current Appoointment
          </div>
          {when(
            Application.domain.appointment,
            <Appointment
              wide
              appointment={Application.domain.appointment}
              name={Application.domain.appointment?.location}
              allowChange={false}
            />
          )}
        </div>
        <div className="SchedulePage__Details-cell bay">
          <div className="SchedulePage__Details-bays">
            {Application.ui.reschedule.location?.bays.map((bay, index) => (
              <BayCircle
                name={bay.name}
                key={index}
                bay={bay}
                className={
                  Application.ui.reschedule.selectedBayId === bay.staffId
                    ? "selected"
                    : ""
                }
              />
            ))}
          </div>
        </div>
        <div className="SchedulePage__Details-cell date">
          <div className="SchedulePage__Details-cell-title">
            New Date & Time
          </div>
          <div className="SchedulePage__Details-cell-info">
            <div className="reservation-date">
              {Application.ui.reschedule.appointmentDateLabel}
            </div>
            <div className="reservation-time">
              <div>
                {Application.ui.reschedule.startTimeDisplay}
                {" - "}
                {Application.ui.reschedule.endTimeDisplay}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Schedule timeslots */}
      <div className="SchedulePage__Timeslots">
        <DayNav
          direction="left"
          onClick={() => handleMove("left")}
          disabled={Application.ui.reschedule.viewDateIsToday}
        />
        {Application.ui.reschedule.days.map((date, index) => (
          <DaySlots
            date={date}
            key={index}
            condensed={false}
            className={index === 0 ? "current" : ""}
            ui={Application.ui.reschedule}
          />
        ))}
        <DayNav direction="right" onClick={() => handleMove("right")} />
      </div>
      {/* Mobile button */}
      <div className="SchedulePage__Mobile">
        <Button
          label={Application.ui.reschedule.finalizeButtonLabel}
          rightArrow={true}
          inline
          onClick={rescheduleAppointment}
          className={
            Application.ui.reschedule.readyToPay ? "active" : "deactivated"
          }
        />
      </div>
    </div>
  );
});
