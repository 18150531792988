import classnames from "classnames";
import React from "react";
import * as ReactDOM from "react-dom";
import { ensureModalRoot } from "../util/ensure-modal-root";
import { hasChild } from "../util/has-child";
import "./modal.scss";
/**
 * Rendering modes for the modal component
 */
export const ModalMode = {} as const;
export type ModalModeType = typeof ModalMode[keyof typeof ModalMode];
/**
 * Modal Props
 */
export interface IModal {
  /** Allows children */
  children?: React.ReactNode;
  /** Provides a custom class name to the container of this component */
  className?: string;
  /** Provide a custom class the the modal panel */
  panelClass?: string;
  /** Props to apply directly to the container div of this component */
  containerProps?: React.HTMLProps<HTMLDivElement>;
  /** Rendering mode of this component */
  mode?: ModalModeType;
  /**
   * If there is already a modal root in the document, you can specify one here.
   * Otherwise, one will automatically be made
   */
  customModalRoot?: HTMLElement;
  /**
   * Executes when a user uses expected interactions that should dismiss this
   * modal.
   */
  onClose?(e?: React.MouseEvent): void;
}
/**
 * The document body needs to have a modal root object to display the menu and
 * modals
 */
let modalRoot: HTMLDivElement | null = null;
/**
 * Component that renders content in a floating region above the entire
 * application.
 */
export class Modal extends React.Component<IModal> {
  bodyRef = React.createRef<HTMLDivElement>();
  outsideRef = React.createRef<HTMLDivElement>();
  mounted = false;
  componentDidMount() {
    this.mounted = true;
    window.addEventListener("keydown", this.handleKeyDown);
    // We must ensure the body document has the modal root available for us to
    // render the dropdown
    modalRoot = ensureModalRoot("__modal__");
    // Little voodoo to make sure the toast stays above this modal
    if (!modalRoot) {
      console.warn(
        "Could not establish the modal root for the document to display Menus and modals"
      );
    }
  }
  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("keydown", this.handleKeyDown);
  }
  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") this.props.onClose?.();
  };
  handleMouseClick = (e: React.MouseEvent) => {
    if (!this.bodyRef.current) return;
    if (
      !hasChild(this.bodyRef.current, e.nativeEvent.target as any) ||
      e.target === this.bodyRef.current
    ) {
      this.props.onClose?.(e);
    }
  };
  render() {
    const {
      customModalRoot = modalRoot,
      className,
      containerProps = {},
      children,
      mode,
    } = this.props;
    if (!customModalRoot) {
      setTimeout(() => this.mounted && this.forceUpdate(), 200);
      return null;
    }
    return ReactDOM.createPortal(
      <div
        className={classnames("Modal", mode, className)}
        {...containerProps}
        onClick={this.handleMouseClick}
      >
        <div ref={this.bodyRef} className="Modal__Body">
          {children}
        </div>
      </div>,
      customModalRoot
    );
  }
}
