import IconArrowRight from "app/client/svg/arrow-right";
import "location.scss";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../env";
import { Application } from "../../store";
import { when } from "../../util/when";

interface ILocationProps {
  className?: string;
  locationId: number;
  name: string;
  address: string;
  bays: number;
  image: string;
}

const Location: FC<ILocationProps> = ({
  className = "",
  locationId,
  name = "",
  address = "",
  bays = 2,
  image = "",
}) => {
  const navigate = useNavigate();

  // Navigate to the schedule page
  const handleClick = (locationId: number) => {
    Application.ui.schedule.setSelectedLocationId(locationId);
    Application.session.updateFirebaseUser({
      lastLocationId: locationId,
    });
    navigate(ENV.routes.schedule);
  };

  return (
    <div
      className={`Location ${className}`}
      onClick={() => handleClick(locationId)}
    >
      {when(
        Application.session.hasLocationMembership(locationId) === true,
        <div className="LocationMember">
          {`${Application.ui.account.membershipLabel} MEMBERSHIP`.toUpperCase()}
        </div>
      )}
      <div className={`Location-image ${image}`}></div>
      <div className="Location__Details">
        <div className="Location__Details-left">
          <div className="Location-address">{address}</div>
          <div className="Location-name">{name}</div>
          <div className="Location-bays">
            <span>{bays}</span>
            bays available
          </div>
        </div>
        <div className="Location__Details-right">
          <a href="" className="Book">
            <span>
              Book
              <IconArrowRight />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default observer(Location);
