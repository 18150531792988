import { observer } from "mobx-react";
import React, { FC } from "react";
import { Application } from "../../store";

interface IReservationVariablesProps {
  className?: string;
}

const ReservationVariables: FC<IReservationVariablesProps> = observer(
  ({ className = "" }) => (
    <div className={`ShowValues ${className}`.trim()}>
      <div>
        <span>ui.appointments.counts.totalL</span>
        <span>{Application.ui.appointments.counts.total}</span>
      </div>
      <div>
        <span>ui.appointments.counts.cancelled</span>
        <span>{Application.ui.appointments.counts.cancelled}</span>
      </div>
      <div>
        <span>ui.appointments.counts.open</span>
        <span>{Application.ui.appointments.counts.open}</span>
      </div>
      <div>
        <span>ui.appointments.counts.completed</span>
        <span>{Application.ui.appointments.counts.completed}</span>
      </div>
      <div>
        <span>session.membership.maxAppointments</span>
        <span>{Application.session.membership?.maxAppointments}</span>
      </div>
      <div>
        <span>session.membership.windowLength</span>
        <span>{Application.session.membership?.windowLength}</span>
      </div>
      <div>
        <span>
          domain.applicationConfig.data.settings.firstAppointment.startHour
        </span>
        <span>
          {
            Application.domain.applicationConfig.data?.settings.firstAppointment
              .startHour
          }
        </span>
      </div>
      <div>
        <span>
          domain.applicationConfig.data.settings.firstAppointment.endHour
        </span>
        <span>
          {
            Application.domain.applicationConfig.data?.settings.firstAppointment
              .endHour
          }
        </span>
      </div>
    </div>
  )
);

export default ReservationVariables;
