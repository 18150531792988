import React, { FC } from "react";

interface IIconMinusProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconMinus: FC<IIconMinusProps> = ({
  className = "",
  width = 40,
  height = 40,
  opacity = 0.5,
  fill = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    className={`Icon__Minus ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M31.6406 21.6406H8.35938V18.3594H31.6406V21.6406Z" />
    </g>
  </svg>
);

export default IconMinus;
