import { Modal } from "app/client/ui/modal";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Application } from "../../../client/store";
import { ENV } from "../../env";
import { classnames, when } from "../../util/when";
import "./schedule.scss";
import "./select-location-modal.scss";

// UI Components
import BackButton from "app/client/ui/back-button";
import Button from "app/client/ui/button";
import CloseButton from "app/client/ui/close-button";
import BayCircle from "app/client/ui/schedule/bay-circle";
import CheckButton from "app/client/ui/schedule/check-button";
import DayNav from "app/client/ui/schedule/day-nav";
import DaySlots from "app/client/ui/schedule/day-slots";
import GuestPicker from "app/client/ui/schedule/guest-picker";
import TypePicker from "app/client/ui/schedule/type-picker";
import LoadingAnimation from "../../ui/loading";
import FinalizeReservationCheckout from "./finalize";
import BookingVariables from "./variables";

/**
 * Book a new reservation
 */
export const SchedulePage = observer(() => {
  const navigate = useNavigate();
  const [isLocationModalOpen, setLocationModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const resizeTimeout: ReturnType<typeof setTimeout> | null = null;
  const [processing, setProcessing] = useState(false);

  // Just incase opening the modal requires further action
  const openLocationModal = () => {
    setLocationModalOpen(true);
  };

  // Just incase closing the modal requires further action
  const closeLocationModal = () => {
    setLocationModalOpen(false);
  };

  const closePaymentModal = () => {
    Application.domain.resetPaymentIntent();
    setPaymentModalOpen(false);
  };

  const setSelectedLocation = (locationId: number) => {
    Application.ui.schedule.setLocationId(locationId);
    Application.session.updateFirebaseUser({
      lastLocationId: locationId,
    });
    Application.ui.schedule.changedLocation();
    closeLocationModal();
  };

  // Set screen width
  Application.ui.schedule.setScreenWidth(window.innerWidth);

  const handleResize = () => {
    Application.ui.schedule.setScreenWidth(window.innerWidth);
  };

  const handleBooking = () => {
    if (!Application.ui.schedule.readyToPay) return;
    if (!Application.ui.schedule.price) return;
    if (!Application.ui.schedule.stripeCustomerId) return;
    const priceId = Application.ui.schedule.price.id;
    const customerId = Application.ui.schedule.stripeCustomerId;
    const description = Application.ui.schedule.paymentDescription;
    // Only get payment intent if there is a price to be paid
    if (Application.ui.schedule.locationAmount > 0) {
      Application.domain.loadPaymentIntent(priceId, customerId, description);
      setPaymentModalOpen(true);
    } else {
      bookAppointment();
    }
  };

  const bookAppointment = async () => {
    Application.ui.schedule.bookAppointment();
    navigate(ENV.routes.scheduleConfirmation);
  };

  const handleComplete = async () => {
    setProcessing(true);
    try {
      await Application.ui.schedule.processPayment();
      await Application.ui.schedule.bookAppointment();
      Application.ui.schedule.reset();
      setProcessing(false);
      navigate(ENV.routes.scheduleConfirmation);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    // Set screensize on resize
    window.addEventListener("resize", handleResize);

    // Initialize the view
    Application.ui.schedule.setViewWindow();

    // Unmount
    return () => {
      Application.ui.schedule.reset();
      window.removeEventListener("resize", handleResize);
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
    };
  }, [resizeTimeout]);

  return (
    <div className="SchedulePage">
      {when(Application.domain.testMode, <BookingVariables />)}
      {when(
        paymentModalOpen,
        <Modal onClose={closePaymentModal}>
          <div className="ModalContainer">
            {when(processing, <LoadingAnimation absolute={true} />)}
            <CloseButton onClick={closePaymentModal} />
            <div className="ModalContainer-title">
              Enter payment information
            </div>
            <div className="ModalContainer-list">
              <FinalizeReservationCheckout onComplete={handleComplete} />
            </div>
          </div>
        </Modal>
      )}
      {when(
        isLocationModalOpen,
        <Modal onClose={closeLocationModal}>
          <div className="ModalContainer">
            <CloseButton onClick={closeLocationModal} />
            <div className="ModalContainer-title">Choose a location</div>
            <div className="ModalContainer-list">
              {Application.domain.applicationConfig.data?.locations.map(
                (item, index) => (
                  <CheckButton
                    title={item.name}
                    subtitle={item.address}
                    selected={
                      item.locationId ===
                      Application.ui.schedule.location?.locationId
                        ? true
                        : false
                    }
                    onClick={() => setSelectedLocation(item.locationId)}
                    key={index}
                  />
                )
              )}
            </div>
          </div>
        </Modal>
      )}
      {/* Top bar */}
      <div className="SchedulePage__Title">
        <div className="SchedulePage__Title-left">
          <BackButton
            route={ENV.routes.reservations}
            label="Back to Reservations"
          />
        </div>
        <div className="SchedulePage__Title-center">Book reservation</div>
        <div className="SchedulePage__Title-right">
          <Button
            label={Application.ui.schedule.finalizeButtonLabel}
            rightArrow={true}
            inline
            onClick={handleBooking}
            className={
              Application.ui.schedule.readyToPay ? "active" : "deactivated"
            }
          />
        </div>
      </div>
      {/* Schedule details */}
      <div className="SchedulePage__Details">
        <div className="SchedulePage__Details-cell location">
          <div className="SchedulePage__Details-cell-title">Location</div>
          <div className="SchedulePage__Details-cell-description">
            Click below to select a different locatrion
          </div>
          <CheckButton
            title={Application.ui.schedule.location?.name || ""}
            subtitle={Application.ui.schedule.location?.address || ""}
            selected={true}
            onClick={openLocationModal}
          />
        </div>
        <div className="SchedulePage__Details-cell guests">
          <div className="SchedulePage__Details-cell-title">Guests</div>
          <div className="SchedulePage__Details-cell-description">
            Your membership allows up to{" "}
            {Application.session.membership?.maxGuests} guests
          </div>
          <GuestPicker />
        </div>
        <div className="SchedulePage__Details-cell type">
          <div className="SchedulePage__Details-cell-title">Type</div>
          <div className="SchedulePage__Details-cell-description">
            Lesson availability is dependent on instructor availability
          </div>
          <TypePicker />
        </div>
        <div className="SchedulePage__Details-cell bay">
          <div className="SchedulePage__Details-cell-title">Bay</div>
          <div className="SchedulePage__Details-bays">
            {Application.ui.schedule.location?.bays.map((bay, index) => (
              <BayCircle
                name={bay.name}
                key={index}
                bay={bay}
                className={
                  Application.ui.schedule.selectedBayId === bay.staffId
                    ? "selected"
                    : ""
                }
              />
            ))}
          </div>
        </div>
        <div className="SchedulePage__Details-cell date">
          <div className="SchedulePage__Details-cell-title">Date & Time</div>
          <div className="SchedulePage__Details-cell-info">
            <div className="reservation-date">
              {Application.ui.schedule.appointmentDateLabel}
            </div>
            <div className="reservation-time">
              <div>
                {Application.ui.schedule.startTimeDisplay}
                {" - "}
                {Application.ui.schedule.endTimeDisplay}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Schedule timeslots */}
      <div
        className={classnames(
          "SchedulePage__Timeslots",
          Application.ui.schedule.loading
            ? "SchedulePage__Timeslots--loading"
            : ""
        )}
      >
        {when(
          Application.ui.schedule.loading,
          <LoadingAnimation absolute={true} />
        )}
        <DayNav
          direction="left"
          onClick={() => Application.ui.schedule.move("left")}
          disabled={Application.ui.schedule.viewDateIsToday}
        />
        {Application.ui.schedule.days.map((date, index) => (
          <DaySlots
            date={date}
            key={index}
            condensed={false}
            className={index === 0 ? "current" : ""}
            ui={Application.ui.schedule}
            loading={Application.ui.schedule.loading}
          />
        ))}
        <DayNav
          direction="right"
          onClick={() => Application.ui.schedule.move("right")}
        />
      </div>
      {/* Mobile button */}
      <div className="SchedulePage__Mobile">
        <Button
          label={Application.ui.schedule.finalizeButtonLabel}
          rightArrow={true}
          inline
          onClick={handleBooking}
          className={
            Application.ui.schedule.readyToPay ? "active" : "deactivated"
          }
        />
      </div>
    </div>
  );
});
