import React, { FC } from "react";

interface IIconArrowLeftProps {
  className?: string;
  size?: number;
}

const IconArrowLeft: FC<IIconArrowLeftProps> = ({
  className = "",
  size = 14,
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 14 14`}
    className={`Icon__ArrowLeft color-stroke ${className}`}
  >
    <path d="M13.0117 6.26172V7.73828H3.87109L8.05469 11.957L7 13.0117L0.988281 7L7 0.988281L8.05469 2.04297L3.87109 6.26172H13.0117Z" />
  </svg>
);

export default IconArrowLeft;
