import Joi from "joi";

export interface IBays {
  name: string;
  username: string;
  staffId: number;
}

// If available is false, the location is not available for booking
export interface ILocation {
  available?: boolean;
  locationId: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  image: string;
  bays: ITimetapStaff[];
}

export interface IType {
  name: string;
  reasonId: number;
}

export interface IStaff {
  name: string;
  staffId: number;
}

export interface IAppointmentCounts {
  TOTAL?: number;
  CANCELLED?: number;
  COMPLETED?: number;
  OPEN?: number;
  CLOSED?: number;
  INPROGRESS?: number;
  READ_ONLY?: number;
  PENDING?: number;
  PENDING_CONFIRMATION?: number;
  PENDING_WAITLIST?: number;
  CHECKEDIN?: number;
  CONFIRMED?: number;
}

export interface IApplicationConfig {
  locations: ILocation[];
  staff: IStaff[];
  types: IType[];
  memberships: IMemberships;
  products: {
    reservation: {
      product: string;
      priceId?: string;
      price?: IStripePrice | null;
      amount?: number;
    };
  };
  settings: {
    firstAppointment: {
      startHour: number;
      endHour: number;
    };
  };
}

export interface IProducts {
  reservation?: {
    product: string;
    priceId?: string;
    price?: IStripePrice;
    amount?: number;
  };
}

export interface IMembership {
  key?: string;
  title: string;
  subtitle?: string;
  all?: boolean;
  monthly?: string;
  monthlyPrice?: IStripePrice | null;
  annually?: string;
  annualPrice?: IStripePrice | null;
  productId?: string;
  level: number;
  features: string[];
  locations: {
    [key: string]: {
      name: string;
      monthly?: string;
      annually?: string;
    };
  };
}

export interface IMemberships {
  [key: string]: IMembership;
}

export interface IMember {
  maxAppointments: number;
  windowLength: number;
  startHour: number;
  endHour: number;
  maxGuests: number;
  window: {
    startDate: string;
    endDate: string;
    startTimestamp: number;
    endTimestamp: number;
  };
}

export interface ITimeslot {
  date: string;
  startTime: number;
  endTime: number;
  staffId: number;
  reasonId: number;
  locationId: number;
}

export const ApplicationConfigValidator = Joi.object({
  locations: Joi.array().items(
    Joi.object({
      available: Joi.boolean().optional(),
      locationId: Joi.number().required(),
      name: Joi.string().required(),
      address: Joi.string(),
      image: Joi.string(),
      city: Joi.string(),
      state: Joi.string(),
      zip: Joi.string(),
      directions: Joi.string(),
      bays: Joi.array().items(
        Joi.object({
          staffId: Joi.number().allow("", null).optional(),
          name: Joi.string().allow("", null).optional(),
          description: Joi.string().allow("", null).optional(),
          fullName: Joi.string().allow("", null).optional(),
          userName: Joi.string().allow("", null).optional(),
          imageUrl: Joi.string().allow("", null).optional(),
          email: Joi.string().allow("", null).optional(),
          phone: Joi.string().allow("", null).optional(),
          mobilePhone: Joi.string().allow("", null).optional(),
          floorplan: Joi.string().allow("", null).optional(),
          locationId: Joi.number().allow("", null).optional(),
          locationName: Joi.string().allow("", null).optional(),
          address: Joi.string().allow("", null).optional(),
          city: Joi.string().allow("", null).optional(),
          state: Joi.string().allow("", null).optional(),
          zip: Joi.string().allow("", null).optional(),
        })
      ),
    })
  ),
  types: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      reasonId: Joi.number().required(),
    })
  ),
  staff: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      staffId: Joi.number().required(),
    })
  ),
  memberships: Joi.object()
    .pattern(
      Joi.string(),
      Joi.object({
        title: Joi.string(),
        subtitle: Joi.string(),
        all: Joi.boolean(),
        monthly: Joi.string().allow(""),
        annually: Joi.string().allow(""),
        productId: Joi.string(),
        level: Joi.number().optional(),
        features: Joi.array().items(Joi.string()),
        locations: Joi.object().pattern(
          Joi.string(),
          Joi.object({
            name: Joi.string(),
            monthly: Joi.string().allow("", null).optional(),
            annually: Joi.string().allow("", null).optional(),
          })
        ),
      }).unknown()
    )
    .unknown(),
  products: Joi.object().pattern(
    Joi.string(),
    Joi.object({
      product: Joi.string(),
      price: Joi.string(),
    }).unknown()
  ),
  settings: Joi.object({
    firstAppointment: Joi.object({
      startHour: Joi.number().allow("", null).optional(),
      endHour: Joi.number().allow("", null).optional(),
    }).unknown(),
  }).unknown(),
}).unknown();

export interface IAppointmentRecord {
  calendarId: number;
  businessId: number;
  locationId: number;
  status: string;
  date: string;
  timestamp: number;
  startTime: number;
  endTime: number;
  note: string;
  createDate: string;
  reasonId: number;
  reason: string;
  clientId: number;
  client: string;
  staffId: number;
  staff?: string;
  location?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  guests?: string;
  directions?: string; // Contains a link to directions
  imageUrl?: string;
  stripeId?: string; // Typically a Stripe chargeId prefix "ch_"
}

export const AppointmentsValidator = Joi.array().items(
  Joi.object({
    calendarId: Joi.number().required(),
    businessId: Joi.number().required(),
    status: Joi.string().required(),
    date: Joi.string().required(),
    startTime: Joi.number().required(),
    endTime: Joi.number().required(),
    note: Joi.string().allow(null),
    createDate: Joi.string().required(),
    reasonId: Joi.number().required(),
    reason: Joi.string().required(),
    clientId: Joi.number().required(),
    client: Joi.string().allow(null),
    staffId: Joi.number().required(),
    staff: Joi.string().allow(null),
    locationId: Joi.number().required(),
    location: Joi.string().allow(null),
    address: Joi.string().allow(null),
    city: Joi.string().allow(null),
    state: Joi.string().allow(null),
    zip: Joi.string().allow(null),
    guests: Joi.string().allow(null),
  }).unknown()
);

// Typeguard for IAppointmentRecord
export function isAppointmentRecords(data: any): data is IAppointmentRecord[] {
  const { error } = AppointmentsValidator.validate(data);
  if (error) {
    throw new Error(error?.message);
  }
  return !error;
}

export interface ITimeslotsParams {
  locationId: number;
  reasonId: number;
  startDate: Date;
  endDate: Date;
}

export interface IStripePrice {
  id: string;
  object: "price";
  active: boolean;
  billing_scheme: "per_unit" | "tiered";
  created: number;
  currency: string;
  livemode: boolean;
  lookup_key: null;
  metadata: Record<string, any>;
  nickname: null;
  product: string;
  recurring?: {
    aggregate_usage: null;
    interval: "day" | "week" | "month" | "year";
    interval_count: number;
    usage_type: "licensed" | "metered";
  };
  tiers_mode: null;
  transform_quantity: null;
  type: "one_time" | "recurring";
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface IStripePrices {
  [key: string]: IStripePrice;
}

// Typeguard for IApplicationConfig
export function isApplicationConfig(data: any): data is IApplicationConfig {
  const { error } = ApplicationConfigValidator.validate(data);
  if (error) {
    throw new Error(error?.message);
  }
  return !error;
}

export interface IAPIPaymentMethod {
  success: boolean;
  method?: IPaymentMethod;
  error?: string;
}

export interface IPaymentMethod {
  id: string;
  object: string;
  billing_details: {
    address: {
      city: string | null;
      country: string | null;
      line1: string | null;
      line2: string | null;
      postal_code: string | null;
      state: string | null;
    };
    email: string | null;
    name: string | null;
    phone: string | null;
  };
  card: {
    brand: string;
    checks: {
      address_line1_check: string | null;
      address_postal_code_check: string | null;
      cvc_check: string | null;
    };
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from: string | null;
    last4: string;
    networks: {
      available: string[];
      preferred: string | null;
    };
    three_d_secure_usage: {
      supported: boolean;
    };
    wallet: string | null;
  };
  created: number;
  customer: string;
  livemode: boolean;
  metadata: Record<string, unknown>;
  type: string;
}

export type IPaymentMethods = IPaymentMethod[] | [];

export interface ICreatePaymentMethodProps {
  paymentId: string;
  customerId: string;
}

export interface IStripeSubscriptions {
  object: string;
  data: IStripeSubscription[];
  has_more: boolean;
  total_count: number;
  url: string;
}

export interface IStripeSubscription {
  id: string;
  object: string;
  application: null;
  application_fee_percent: null;
  automatic_tax: {
    enabled: boolean;
  };
  billing_cycle_anchor: number;
  billing_thresholds: null;
  cancel_at: null;
  cancel_at_period_end: boolean;
  canceled_at: number;
  cancellation_details: {
    comment: null;
    feedback: null;
    reason: null;
  };
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: null;
  default_payment_method: null;
  default_source: null;
  default_tax_rates: [];
  description: null;
  discount: null;
  ended_at: number;
  items: {
    object: string;
    data: [
      {
        id: string;
        object: string;
        billing_thresholds: null;
        created: number;
        metadata: {};
        plan: {
          id: string;
          object: string;
          active: boolean;
          aggregate_usage: null;
          amount: number;
          amount_decimal: string;
          billing_scheme: string;
          created: number;
          currency: string;
          interval: string;
          interval_count: number;
          livemode: boolean;
          metadata: {};
          nickname: null;
          product: string;
          tiers: null;
          tiers_mode: null;
          transform_usage: null;
          trial_period_days: null;
          usage_type: string;
        };
        price: {
          id: string;
          object: string;
          active: boolean;
          billing_scheme: string;
          created: number;
          currency: string;
          custom_unit_amount: null;
          livemode: boolean;
          lookup_key: null;
          metadata: {};
          nickname: null;
          product: string;
          recurring: {
            aggregate_usage: null;
            interval: string;
            interval_count: number;
            trial_period_days: null;
            usage_type: string;
          };
          tax_behavior: string;
          tiers_mode: null;
          transform_quantity: null;
          type: string;
          unit_amount: number;
          unit_amount_decimal: string;
        };
        quantity: number;
        subscription: string;
        tax_rates: [];
      }
    ];
    has_more: boolean;
    url: string;
  };
  latest_invoice: string;
  livemode: boolean;
  metadata: {
    membershipId: string;
  };
  next_pending_invoice_item_invoice: null;
  on_behalf_of: null;
  pause_collection: null;
  payment_settings: {
    payment_method_options: null;
    payment_method_types: null;
    save_default_payment_method: null;
  };
  pending_invoice_item_interval: null;
  pending_setup_intent: null;
  pending_update: null;
  schedule: null;
  start_date: number;
  status: string;
  test_clock: null;
  transfer_data: null;
  trial_end: null;
  trial_settings: {
    end_behavior: {
      missing_payment_method: string;
    };
  };
  trial_start: null;
}

export interface IMembershipSettings {
  [key: string]: {
    maxDailyAppointments: number;
    maxAppointments: number;
    maxGuests: number;
    freeGuests: number;
    windowLength: number;
    startHour: number;
    endHour: number;
    subscribed: boolean;
  };
}

export interface IStripePayment {
  id: string;
  object: string;
  amount: number;
  amount_capturable: number;
  amount_details: {
    tip: Record<string, unknown>;
  };
  amount_received: number;
  application: null;
  application_fee_amount: null;
  automatic_payment_methods: null;
  canceled_at: null;
  cancellation_reason: null;
  capture_method: string;
  charges: {
    object: string;
    data: never[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  client_secret: string;
  confirmation_method: string;
  created: number;
  currency: string;
  customer: string;
  description: string;
  invoice: null;
  last_payment_error: null;
  latest_charge: string;
  livemode: boolean;
  metadata: Record<string, unknown>;
  next_action: null;
  on_behalf_of: null;
  payment_method: string;
  payment_method_options?: {
    card: {
      installments: null;
      mandate_options: null;
      network: null;
      request_three_d_secure: string;
    };
  };
  payment_method_types: string[];
  processing: null;
  receipt_email: null;
  review: null;
  setup_future_usage: null;
  shipping: null;
  source: null;
  statement_descriptor: null;
  statement_descriptor_suffix: null;
  status: string;
  transfer_data: null;
  transfer_group: null;
}

export interface ITimetapStaffFull {
  professionalId: number;
  fullName: string;
  userName: string;
  email: string;
  phone: string;
  mobilePhone: string;
  active: boolean;
  acceptAppointments: boolean;
  businessId: number;
  newApptSms: boolean;
  imageUrl: string;
  emailFooter: string;
  emailInstructions: string;
  emailInstructions2: string;
  locale: string;
  firstDayOfWeek: string;
  staffProfile: string;
  securityRoleId: number;
  sortWeight: number;
  googleSyncPeriod: string;
  googleSyncActive: boolean;
  timeZone: {
    timeZoneId: number;
    timeZoneCode: string;
    timeZoneDesc: string;
    visible: string;
    sortOrder: number;
  };
  locationsWithSchedule: string;
  privateUrl: string;
  embedCode: string;
  skypeId: string;
  excludeEmailCategoryIdList: string;
  changePasswordNextLogin: boolean;
  internalName: string;
  createdUser: string;
  createdDate: number;
  currentStationName: string;
  clockedIn: string;
  staffRateLevel: string;
  syncStaffIdList: string;
  syncStaffUserName: string;
  salesforceUserId: string;
  salesforceParm1: string;
  guestHashEnabled: boolean;
  allowOnline: boolean;
  lastLoginDate: string;
  locationIdAccessList: number[];
  status: string;
  accountLocked: boolean;
  virtualRoomUrl: string;
  virtualRoomId: string;
  internalDisplayName: string;
  syncStaffList: string;
  addnlVirtualRoomUrls: string;
  reasonGroupIdList: string;
  locationGroupIdList: string;
  securityRole: string;
  securityRoles: string;
  reasonIdList: string;
}

export interface ITimetapStaff {
  staffId: number;
  name: string;
  description: string;
  fullName: string;
  userName: string;
  email: string;
  phone: string;
  mobilePhone: string;
  imageUrl: string;
  floorplan: string;
  locationId: number;
  locationName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface IAppointmentCountSummary {
  [key: string]: number | undefined;
  total: number;
  completed: number;
  cancelled: number;
  open: number;
}

export interface IFirstAppointment {
  startHour: number;
  endHour: number;
}

export interface IVideoData {
  tutorial: number;
  folder: string;
  title: string;
  vimeoId: number;
  image: string;
  tags: string[];
}

export interface IVideos {
  baseUrl: string;
  fullSuffix: string;
  thumbSuffix: string;
  videos: IVideoData[];
}

export interface IVideo {
  tutorial: number;
  folder: string;
  title: string;
  vimeoId: number;
  image: string;
  thumbnail: string;
  link: string;
  tags: string[];
}
