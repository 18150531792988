import { observer } from "mobx-react";
import React, { FC } from "react";
import { Application } from "../../store";

interface IMembershipVariablesProps {
  className?: string;
}

const MembershipVariables: FC<IMembershipVariablesProps> = observer(
  ({ className = "" }) => (
    <div className={`ShowValues ${className}`.trim()}>
      <div>
        <span>isMember</span>
        <span>{Application.ui.account.isMember ? "true" : "false"}</span>
      </div>
      <div>
        <span>readyToCheckout</span>
        <span>{Application.ui.account.readyToCheckout ? "true" : "false"}</span>
      </div>
      <div>
        <span>selectedLocationId</span>
        <span>{Application.ui.account.selectedLocationId}</span>
      </div>
      <div>
        <span>selectedMembership</span>
        <span>{Application.ui.account.selectedMembership}</span>
      </div>
      <div>
        <span>selectedPeriods</span>
        <span>
          {
            // Dispplay all plans and selected periods
            Object.entries(Application.ui.account.selectedPeriods)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")
          }
        </span>
      </div>
      <div>
        <span>userLocations</span>
        <span>{Application.ui.account.userLocations?.join(", ")}</span>
      </div>
      <div>
        <span>membership</span>
        <span>{Application.ui.account.membership}</span>
      </div>
      <div>
        <span>membershipLabel</span>
        <span>{Application.ui.account.membershipLabel}</span>
      </div>
      <div>
        <span>selectedAllLocations</span>
        <span>
          {Application.ui.account.selectedAllLocations ? "true" : "false"}
        </span>
      </div>
      <div>
        <span>level</span>
        <span>{Application.ui.account.level}</span>
      </div>
      <div>
        <span>Location Ids</span>
        <span>{Application.ui.account.selectedLocationIds.join(", ")}</span>
      </div>
      <div>
        <span>paymentDescription</span>
        <span>{Application.ui.account.paymentDescription}</span>
      </div>
      <div>
        <span>amount</span>
        <span>{Application.ui.account.amount}</span>
      </div>
      <div>
        <span>formattedAmount</span>
        <span>{Application.ui.account.formattedAmount}</span>
      </div>
      <div>
        <span>billingCycle</span>
        <span>{Application.ui.account.billingCycle}</span>
      </div>
      <div>
        <span>defaultPaymentId</span>
        <span>{Application.ui.account.defaultPaymentId}</span>
      </div>
      <div>
        <span>domain.selectedPaymentId</span>
        <span>{Application.domain.selectedPaymentId}</span>
      </div>
      <div>
        <span>stripeCustomerId</span>
        <span>{Application.ui.account.stripeCustomerId}</span>
      </div>
      <div>
        <span>price.id</span>
        <span>{Application.ui.account.price?.id}</span>
      </div>
      <div>
        <span>stripeSubscriptionId</span>
        <span>{Application.ui.account.stripeSubscriptionId}</span>
      </div>
      <div>
        <span>subscriptionId</span>
        <span>{Application.ui.account.subscriptionId}</span>
      </div>
      <div>
        <span>formattedSubscriptionPrice</span>
        <span>{Application.ui.account.formattedSubscriptionPrice}</span>
      </div>
      <div>
        <span>subscriptionInterval</span>
        <span>{Application.ui.account.subscriptionInterval}</span>
      </div>
      <div>
        <span>subscriptionItemId</span>
        <span>{Application.ui.account.subscriptionItemId}</span>
      </div>
    </div>
  )
);

export default MembershipVariables;
