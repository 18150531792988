import { action, computed, makeObservable, observable } from "mobx";
import { ApplicationStore } from "../application.store";
import { IVideo, IVideos } from "../interfaces";
import { Store } from "../store";

export class VideoUI extends Store {
  @observable loading: boolean = false;
  @observable selectedTag: string | null = null;
  @observable selectedFolder: string | null = null;
  @observable selectedVimeoId: number = -1;
  @observable ready: boolean = false;

  emptyVideo: IVideo = {
    vimeoId: 0,
    title: "",
    folder: "",
    link: "",
    thumbnail: "",
    tutorial: 0,
    image: "",
    tags: [],
  };

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  reset = () => {
    this.loading = false;
    this.selectedTag = null;
    this.selectedVimeoId = -1;
  };

  @action
  resetSelectedVimeoId = () => {
    this.selectedVimeoId = -1;
  };

  @action
  setSelectedTag = (tag: string | null) => {
    this.selectedTag = tag;
  };

  @action
  setSelectedFolder = (folder: string | null) => {
    this.selectedFolder = folder;
    this.setSelectedTag(null);
  };

  @action
  setSelectedVimeoId = (id: number) => {
    // Must now wait for the newly loaded video to be ready
    this.ready = false;
    this.selectedVimeoId = id;
  };

  @action
  setReady = (ready: boolean) => {
    this.ready = ready;
  };

  @action
  selectFirstFolder = () => {
    if (!this.folders) return;
    this.selectedFolder = this.folders[0];
  };

  getThumbnail = (settings: IVideos, image: string): string =>
    `${image}${settings.thumbSuffix}`;

  getWatchUrl = (settings: IVideos, id: string): string =>
    `${settings.baseUrl}/${id}`;

  @computed
  get videos(): IVideo[] {
    if (!this.application.domain.video) {
      this.loading = true;
      return [];
    }

    const settings = this.application.domain.video;
    const videos: IVideo[] = [];
    settings.videos.map((video) => {
      videos.push({
        tutorial: video.tutorial ?? 0,
        folder: video.folder,
        title: video.title,
        vimeoId: video.vimeoId,
        image: video.image,
        thumbnail: this.getThumbnail(settings, video.image),
        link: this.getWatchUrl(settings, String(video.vimeoId)),
        tags: video.tags,
      });
    });

    // Sort videos by tutorial
    videos.sort((a, b) => {
      if (a.tutorial < b.tutorial) {
        return -1;
      }
      if (a.tutorial > b.tutorial) {
        return 1;
      }
      return 0;
    });

    return videos;
  }

  @computed
  get folderVideos(): IVideo[] {
    if (!this.videos) return [];
    if (!this.selectedFolder) return [];
    return this.videos.filter(
      (video: IVideo) => video.folder === this.selectedFolder
    );
  }

  @computed
  get taggedVideos(): IVideo[] {
    if (!this.videos) return [];
    if (!this.selectedTag) return this.folderVideos;
    return this.folderVideos.filter((video: IVideo) => {
      if (this.selectedTag === "") return false;
      if (!video.tags) return false;
      if (!this.selectedTag) return false;
      if (video.folder !== this.selectedFolder) return false;
      return video.tags.includes(this.selectedTag);
    });
  }

  @computed
  get tags(): string[] {
    if (!this.videos) return [];
    const tags: string[] = [];
    this.videos.map((video) => {
      if (!video.tags) return;
      video.tags.map((tag) => {
        if (!tags.includes(tag) && video.folder === this.selectedFolder) {
          tags.push(tag);
        }
      });
    });
    // Sort alphabetically
    tags.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return tags;
  }

  @computed
  get folders(): string[] {
    if (!this.videos) return [];
    const folders: string[] = [];
    this.videos.map((video) => {
      if (!folders.includes(video.folder)) {
        if (video.folder) folders.push(video.folder);
      }
    });
    // Sort alphabetically
    folders.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return folders;
  }

  @computed
  get allTags(): boolean {
    if (!this.selectedTag) return true;
    return false;
  }

  @computed
  get allTagsClass(): string {
    return this.allTags ? "active" : "";
  }

  @computed
  get firstVideo(): IVideo {
    if (!this.videos) return this.emptyVideo;
    return this.videos[0];
  }

  @computed
  get lastVideo(): IVideo {
    if (!this.videos) return this.emptyVideo;
    return this.videos[this.videos.length - 1];
  }

  @computed
  get selectedVideo(): IVideo {
    if (!this.videos) return this.emptyVideo;
    if (!this.selectedVimeoId) return this.firstVideo;
    const video = this.videos.find((video) => {
      if (video.vimeoId === this.selectedVimeoId) return true;
      return false;
    });
    if (video) return video;
    return this.firstVideo;
  }

  @computed
  get nextTutorial(): IVideo {
    if (!this.folderVideos.length) return this.emptyVideo;
    const video = this.selectedVideo;
    if (!video) return this.emptyVideo;
    let nextId = 1;
    for (let i = 0; i < this.folderVideos.length; i++) {
      const v = this.folderVideos[i];
      if (v.tutorial === video.tutorial) {
        nextId = i + 1;
        if (!this.folderVideos[nextId]) {
          nextId = 0;
        }
        break;
      }
    }
    return this.folderVideos[nextId];
  }

  @computed
  get prevTutorial(): IVideo {
    if (!this.folderVideos.length) return this.emptyVideo;
    const video = this.selectedVideo;
    if (!video) return this.videos[1];
    let prevId = 1;
    for (let i = 0; i < this.folderVideos.length; i++) {
      const v = this.folderVideos[i];
      if (v.tutorial === video.tutorial) {
        prevId = i - 1;
        if (!this.folderVideos[prevId]) {
          prevId = this.folderVideos.length - 1;
        }
        break;
      }
    }

    return this.folderVideos[prevId];
  }
}
