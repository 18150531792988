import React, { FC } from "react";

interface IIconCloseProps {
  className?: string;
}

const IconClose: FC<IIconCloseProps> = ({ className = "" }) => (
  <svg
    className={`Icon__Close color-fill ${className}`}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M17.7305 2.02734L10.7578 9L17.7305 15.9727L15.9727 17.7305L9 10.7578L2.02734 17.7305L0.269531 15.9727L7.24219 9L0.269531 2.02734L2.02734 0.269531L9 7.24219L15.9727 0.269531L17.7305 2.02734Z"
      fill="white"
    />
  </svg>
);

export default IconClose;
