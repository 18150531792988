import React, { FC } from "react";

interface IIconCancelReservationProps {
  className?: string;
}

const IconCancelReservation: FC<IIconCancelReservationProps> = ({
  className = "",
}) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    className={`Icon__CancelReservation color-stroke ${className}`}
  >
    <g stroke="white" strokeLinecap="round" strokeLinejoin="round">
      <path d="M27.6676 44.8383L18.7828 46.4433C18.6172 46.4725 18.4468 46.4351 18.3087 46.3393C18.1706 46.2434 18.0759 46.0968 18.0454 45.9315L15.0796 29.5083" />
      <path d="M13.3508 19.933L12.8438 17.1268C12.8144 16.9612 12.8517 16.7908 12.9476 16.6528C13.0435 16.5147 13.1902 16.4202 13.3556 16.39L23.9216 14.4814C24.315 14.4331 24.7114 14.5425 25.0244 14.7856L25.7318 15.4006C25.8602 15.5184 25.9432 15.6774 25.9663 15.8502C25.9895 16.0228 25.9513 16.1982 25.8584 16.3456L25.2146 17.3128C25.1211 17.4658 25.0782 17.6443 25.0922 17.8231C25.1062 18.0019 25.1763 18.1717 25.2926 18.3082L26.1086 19.1956C26.2248 19.3323 26.2951 19.502 26.3097 19.6809C26.3242 19.8597 26.2822 20.0385 26.1896 20.1922L25.5602 21.1456C25.4673 21.3007 25.4237 21.4804 25.4353 21.6609C25.4468 21.8413 25.513 22.014 25.625 22.156L26.9168 23.656C27.032 23.8059 27.1084 23.982 27.139 24.1685C27.1697 24.355 27.1537 24.5463 27.0926 24.7252L26.0222 27.3682C25.8862 27.7558 25.8511 28.1716 25.9202 28.5766L28.1174 38.5756" />
      <path d="M19.2407 29.1384L25.8983 27.936" />
      <path d="M19.7876 32.1643L26.4464 30.9619" />
      <path d="M20.3335 35.1907L27.1063 33.9673" />
      <path d="M21.1733 42.6756L25.8167 41.8374" />
      <path d="M43.9885 32.1037L41.8003 47.0401C41.7882 47.1224 41.7599 47.2013 41.7171 47.2725C41.6743 47.3438 41.6177 47.4057 41.5507 47.4549C41.4838 47.5041 41.4077 47.5394 41.3269 47.559C41.2462 47.5785 41.1624 47.5818 41.0803 47.5687L30.1699 45.8401C29.9972 45.807 29.8408 45.7165 29.726 45.5833C29.6111 45.4501 29.5447 45.282 29.5375 45.1063L29.4307 32.5705C29.44 32.3775 29.4913 32.1887 29.581 32.0175C29.6705 31.8462 29.7964 31.6965 29.9497 31.5787L31.1131 30.7855C31.2554 30.682 31.3586 30.5333 31.4058 30.3637C31.453 30.1941 31.4414 30.0135 31.3729 29.8513L30.4729 27.8917C30.4041 27.7195 30.3836 27.5318 30.4135 27.3488C30.4434 27.1658 30.5227 26.9945 30.6427 26.8531L31.6231 25.8103C31.7441 25.6666 31.8265 25.4943 31.8624 25.3098C31.8983 25.1254 31.8866 24.9348 31.8283 24.7561L31.1509 23.0077C31.0927 22.8384 31.0899 22.655 31.1427 22.484C31.1956 22.313 31.3014 22.1631 31.4449 22.0561L32.0377 21.6475C32.1845 21.5352 32.2976 21.3845 32.3644 21.2121C32.4312 21.0396 32.4491 20.8521 32.4163 20.6701L32.0275 19.0015C32.0035 18.9308 31.9987 18.8551 32.0134 18.7819C32.0281 18.7088 32.0619 18.6408 32.1113 18.5849C32.1608 18.5289 32.2241 18.4871 32.295 18.4635C32.3658 18.44 32.4416 18.4356 32.5147 18.4507L45.1501 19.8001C45.232 19.8083 45.3114 19.8329 45.3835 19.8727C45.4556 19.9125 45.5188 19.9665 45.5693 20.0315C45.6199 20.0966 45.6566 20.1711 45.6774 20.2508C45.6981 20.3305 45.7024 20.4135 45.6901 20.4949L45.3781 22.6219" />
      <path d="M31.9824 27.7036L41.4864 29.0962" />
      <path d="M31.3975 30.3931L41.0779 31.8835" />
      <path d="M29.4253 33.0229L40.6699 34.6705" />
      <path d="M29.4604 36.0571L40.261 37.4569" />
      <path d="M32.8896 43.335L38.9286 44.2194" />
      <path d="M48.5336 39.445L47.4536 36.9953C47.4136 36.905 47.3918 36.8078 47.3894 36.7092C47.387 36.6105 47.4041 36.5124 47.4397 36.4203C47.4754 36.3284 47.5288 36.2442 47.597 36.1729C47.6651 36.1016 47.7467 36.0444 47.837 36.0047L54.9296 32.869C55.0198 32.8291 55.117 32.8072 55.2155 32.8049C55.3142 32.8025 55.4123 32.8195 55.5043 32.8551C55.5962 32.8907 55.6804 32.944 55.7517 33.0121C55.8231 33.0801 55.8803 33.1616 55.9202 33.2519L57.0002 35.6999" />
      <path d="M46.1909 23.7753C46.4165 25.2543 44.6591 24.6513 43.8791 23.7153L41.6189 21.1989C41.5166 21.0773 41.3911 20.9774 41.2497 20.9049C41.1083 20.8325 40.9539 20.7889 40.7955 20.7769C40.6371 20.7648 40.4779 20.7845 40.3272 20.8347C40.1765 20.8849 40.0373 20.9647 39.9178 21.0694C39.7983 21.174 39.7008 21.3015 39.6312 21.4443C39.5615 21.5871 39.5211 21.7423 39.5121 21.9009C39.5032 22.0595 39.526 22.2183 39.5792 22.368C39.6324 22.5177 39.7149 22.6552 39.8219 22.7727C43.1819 28.1583 43.1693 29.4183 45.2603 30.8859C46.4603 31.7259 46.9721 32.5779 47.6729 34.1181" />
      <path d="M53.8235 31.4194C52.3205 24.322 49.7231 17.3194 39.4301 15.748L38.7953 15.649C38.4822 15.6003 38.1626 15.678 37.9068 15.8649C37.651 16.0519 37.4799 16.3328 37.4312 16.6459C37.3825 16.959 37.4602 17.2786 37.6471 17.5344C37.8341 17.7902 38.115 17.9613 38.4281 18.01L39.0629 18.1084C39.4637 18.1684 39.8507 18.2422 40.2233 18.3256" />
      <path d="M55.0667 32.8065L54.6467 31.8549C54.6069 31.7646 54.5497 31.6831 54.4783 31.6149C54.407 31.5468 54.3229 31.4934 54.2309 31.4578C54.1389 31.4222 54.0408 31.4051 53.9422 31.4074C53.8435 31.4098 53.7463 31.4315 53.6561 31.4715L47.6561 34.1241C47.5659 34.1639 47.4843 34.2211 47.4162 34.2924C47.3481 34.3638 47.2947 34.4479 47.259 34.5399C47.2235 34.6319 47.2064 34.73 47.2087 34.8286C47.211 34.9272 47.2328 35.0244 47.2727 35.1147L47.6927 36.0663L55.0667 32.8065Z" />
      <path d="M11.907 37.4007L12.6366 34.8207C12.6635 34.7258 12.6715 34.6266 12.66 34.5287C12.6485 34.4307 12.6179 34.336 12.5698 34.2499C12.5217 34.1638 12.4571 34.088 12.3797 34.0269C12.3023 33.9658 12.2137 33.9205 12.1188 33.8937L4.6572 31.7829C4.46553 31.7285 4.26012 31.7526 4.08616 31.8497C3.9122 31.9468 3.78395 32.109 3.7296 32.3007L3 34.8777" />
      <path d="M12.1696 20.7001C11.488 23.3083 13.5766 22.3705 14.3158 21.1741L16.1998 18.3649C16.2849 18.2319 16.3956 18.1171 16.5253 18.027C16.6551 17.937 16.8013 17.8736 16.9558 17.8405C17.1101 17.8074 17.2696 17.8052 17.4249 17.8342C17.5801 17.8631 17.7281 17.9225 17.8602 18.0089C17.9924 18.0954 18.1061 18.2072 18.1947 18.3379C18.2834 18.4685 18.3453 18.6155 18.3768 18.7702C18.4083 18.925 18.4088 19.0844 18.3783 19.2394C18.3478 19.3943 18.2869 19.5416 18.199 19.6729C15.6268 25.4749 15.8134 26.7211 13.948 28.4665C12.8806 29.4655 12.4894 30.3811 12.0112 32.0065" />
      <path d="M5.54639 30.1935C6.04257 22.9551 7.63497 15.6567 17.6064 12.6633L18.2208 12.4767C18.524 12.3853 18.8511 12.4178 19.1304 12.5672C19.4096 12.7166 19.6183 12.9705 19.7106 13.2735C19.7288 13.3323 19.7423 13.3925 19.7508 13.4535" />
      <path d="M4.50879 31.7402L4.79259 30.7388C4.81943 30.6439 4.86473 30.5551 4.92589 30.4777C4.98705 30.4003 5.06288 30.3357 5.14903 30.2876C5.23519 30.2395 5.32998 30.2089 5.42798 30.1974C5.52599 30.186 5.62529 30.194 5.72019 30.221L12.0322 32.0084C12.2239 32.0628 12.3861 32.191 12.4832 32.365C12.5803 32.5389 12.6043 32.7444 12.55 32.936L12.2668 33.9374L4.50879 31.7402Z" />
      <path d="M33.5999 39.4902C33.4357 39.6224 33.3067 39.7931 33.2244 39.9871C33.142 40.1811 33.1088 40.3925 33.1277 40.6023C33.1466 40.8123 33.2171 41.0143 33.3327 41.1905C33.4485 41.3666 33.6059 41.5115 33.791 41.6122C33.9762 41.7129 34.1833 41.7664 34.3941 41.7678C34.6048 41.7693 34.8127 41.7187 34.9992 41.6205C35.1857 41.5224 35.3451 41.3798 35.4633 41.2052C35.5814 41.0307 35.6546 40.8297 35.6765 40.62L35.6495 40.875C35.6206 41.1456 35.7003 41.4165 35.8712 41.6282C36.0421 41.8399 36.29 41.9751 36.5606 42.0039C36.8311 42.0328 37.102 41.9531 37.3137 41.7822C37.5254 41.6113 37.6606 41.3634 37.6895 41.0928C37.6607 41.3628 37.7404 41.6333 37.911 41.8446C38.0816 42.0558 38.3291 42.1908 38.5991 42.2196L39.3629 42.3012" />
      <path d="M20.46 41.3798C20.2518 41.414 20.0382 41.3856 19.8461 41.2985C19.654 41.2113 19.4921 41.0691 19.3808 40.8899C19.2695 40.7106 19.2138 40.5024 19.2208 40.2916C19.2278 40.0808 19.2972 39.8768 19.4201 39.7053C19.5431 39.5339 19.7141 39.4028 19.9116 39.3285C20.109 39.2543 20.3241 39.2402 20.5295 39.2882C20.735 39.3362 20.9216 39.4439 21.0657 39.5979C21.2099 39.7519 21.3053 39.9452 21.3396 40.1534" />
      <path d="M25.4948 39.1094C25.5177 39.2476 25.513 39.389 25.4812 39.5255C25.4493 39.6619 25.3909 39.7908 25.3091 39.9046C25.2274 40.0184 25.1241 40.1151 25.0051 40.189C24.886 40.2629 24.7535 40.3126 24.6152 40.3352" />
      <path d="M21.3995 40.5041C21.3767 40.3659 21.3813 40.2245 21.4132 40.088C21.445 39.9516 21.5035 39.8227 21.5852 39.7089C21.6669 39.5951 21.7702 39.4984 21.8893 39.4245C22.0084 39.3506 22.1408 39.3009 22.2791 39.2783" />
      <path d="M23.1539 40.2157C23.1311 40.0774 23.1357 39.9361 23.1675 39.7996C23.1993 39.6631 23.2577 39.5343 23.3394 39.4204C23.421 39.3065 23.5242 39.2098 23.6433 39.1358C23.7623 39.0619 23.8947 39.0121 24.0329 38.9893" />
    </g>
  </svg>
);

export default IconCancelReservation;
