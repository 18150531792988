import { Application } from "app/client/store";
import IconLogo from "app/client/svg/logo";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { ENV } from "../../../client/env";

/**
 * Sign out
 */
export const SignoutPage = observer(() => {
  // Full page refresh
  const returnToLogin = () => {
    window.location.href = ENV.routes.login;
  };

  useEffect(() => {
    Application.session.signOut();
  }, []);

  return (
    <div className="LoginPage FormPage full-screen">
      <div className="FormPage-left"></div>
      <div className="FormPage-right">
        <div className="FormPage-form">
          <div className="FormPage-form-title">
            <div className="FormPage-form-logo">
              <IconLogo />
            </div>
            <h1>Signed Out</h1>
          </div>
        </div>
        <div className="FormPage-links">
          <a onClick={returnToLogin}>Back to login</a>
        </div>
        <div className="FormPage-copyright">
          Copyright © City Golf Club 2023.
        </div>
      </div>
    </div>
  );
});
