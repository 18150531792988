import { observer } from "mobx-react";
import React from "react";
import { Application } from "../../store";
import { IVideo } from "../../store/interfaces";
import PlayIcon from "../../svg/play";
import { when } from "../../util/when";
import "./video-list-item.scss";

interface IVideoListItemProps {
  onClick?(): void;
  video: IVideo;
  header?: string;
}

export const VideoListItem = observer(
  ({ onClick, video, header }: IVideoListItemProps) => {
    const handleClick = (id: number) => {
      Application.ui.video.setSelectedVimeoId(id);
      onClick?.();
    };

    return (
      <div className="VideoListItem" onClick={() => handleClick(video.vimeoId)}>
        {when(header, <div className="VideoListItem--header">{header}</div>)}
        <div className="VideoListItem--thumbnail">
          <div className="VideoListItem--thumbnail-play">
            <PlayIcon />
          </div>
          <img src={video.thumbnail} />
        </div>
        <div className="VideoListItem--tutorial">
          Tutorial
          <span>{video.tutorial}</span>
        </div>
        <div className="VideoListItem--title">{video.title}</div>
      </div>
    );
  }
);
