import React, { FC } from "react";

interface IIconLogoutProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconLogout: FC<IIconLogoutProps> = ({
  className = "",
  width = 20,
  height = 20,
  opacity = 0.5,
  fill = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={`Icon__Logout color-stroke ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M5.32422 5.92578V14.0742H10V15.25H5.32422C5.01432 15.25 4.74089 15.1315 4.50391 14.8945C4.28516 14.6576 4.17578 14.3841 4.17578 14.0742V5.92578C4.17578 5.61589 4.28516 5.34245 4.50391 5.10547C4.74089 4.86849 5.01432 4.75 5.32422 4.75H10V5.92578H5.32422ZM12.9258 7.07422L15.8242 10L12.9258 12.9258L12.1055 12.0781L13.6094 10.5742H7.67578V9.42578H13.6094L12.1055 7.89453L12.9258 7.07422Z" />
    </g>
  </svg>
);

export default IconLogout;
