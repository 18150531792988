import React, { FC } from "react";

interface IIconRescheduleReservationProps {
  className?: string;
}

const IconRescheduleReservation: FC<IIconRescheduleReservationProps> = ({
  className = "",
}) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    className={`Icon__RescheduleReservation ${className}`}
  >
    <g stroke="white" strokeLinecap="round" strokeLinejoin="round">
      <path d="M51.2574 52.2855H8.7426C8.01516 52.2854 7.3176 51.9963 6.80328 51.4818C6.28896 50.9674 6 50.2698 6 49.5423V14.5713C6 13.8439 6.28896 13.1462 6.80328 12.6318C7.3176 12.1173 8.01516 11.8282 8.7426 11.8281H51.2574C51.9848 11.8282 52.6824 12.1173 53.1967 12.6318C53.711 13.1462 54 13.8439 54 14.5713V49.5423C54 50.2698 53.711 50.9674 53.1967 51.4818C52.6824 51.9963 51.9848 52.2854 51.2574 52.2855Z" />
      <path d="M54.0002 32.3999V49.5425C54.0002 50.2699 53.7113 50.9676 53.197 51.482C52.6827 51.9964 51.9851 52.2855 51.2576 52.2857H32.0576C32.0576 52.2857 43.0286 48.8573 54.0002 32.3999Z" />
      <path d="M54 24.1713H6V14.5713C6 13.8439 6.28896 13.1462 6.80328 12.6318C7.3176 12.1173 8.01516 11.8282 8.7426 11.8281H51.2574C51.9848 11.8282 52.6824 12.1173 53.1967 12.6318C53.711 13.1462 54 13.8439 54 14.5713V24.1713Z" />
      <path d="M16.2856 15.9427C15.4718 15.9427 14.6764 15.7015 13.9999 15.2494C13.3232 14.7973 12.796 14.1547 12.4846 13.403C12.1732 12.6512 12.0917 11.824 12.2504 11.0259C12.4091 10.2279 12.801 9.49478 13.3764 8.91938C13.9518 8.34398 14.6849 7.95218 15.4829 7.79342C16.281 7.63466 17.1082 7.71614 17.86 8.02754C18.6118 8.33894 19.2543 8.86628 19.7064 9.54284C20.1585 10.2194 20.3998 11.0148 20.3998 11.8285" />
      <path d="M30.686 15.9427C29.8722 15.9427 29.0768 15.7015 28.4003 15.2494C27.7236 14.7973 27.1964 14.1547 26.885 13.403C26.5736 12.6512 26.4921 11.824 26.6508 11.0259C26.8095 10.2279 27.2014 9.49478 27.7768 8.91938C28.3522 8.34398 29.0853 7.95218 29.8833 7.79342C30.6814 7.63466 31.5086 7.71614 32.2604 8.02754C33.0122 8.33894 33.6547 8.86628 34.1068 9.54284C34.5589 10.2194 34.8002 11.0148 34.8002 11.8285" />
      <path d="M45.0859 15.9427C44.2721 15.9427 43.4767 15.7015 42.8002 15.2494C42.1235 14.7973 41.5963 14.1547 41.2849 13.403C40.9735 12.6512 40.892 11.824 41.0507 11.0259C41.2094 10.2279 41.6013 9.49478 42.1767 8.91938C42.7521 8.34398 43.4852 7.95218 44.2832 7.79342C45.0813 7.63466 45.9085 7.71614 46.6603 8.02754C47.4121 8.33894 48.0546 8.86628 48.5067 9.54284C48.9588 10.2194 49.2001 11.0148 49.2001 11.8285" />
      <path d="M6 31.0283H54" />
      <path d="M6 37.8857H34.6884" />
      <path d="M6 44.7427H34.6884" />
      <path d="M15.6001 24.1719V52.2861" />
      <path d="M25.2002 24.1719V52.2861" />
      <path d="M34.7998 24.1719V33.4773" />
      <path d="M44.3999 24.1719V34.7295" />
      <path d="M15.6 31.0283H6V37.8857H15.6V31.0283Z" />
      <path d="M25.2001 44.7427H15.6001V52.2853H25.2001V44.7427Z" />
      <path d="M34.8002 24.1719H25.2002V31.0293H34.8002V24.1719Z" />
      <path d="M54.0002 32.3999C43.0286 48.8573 32.0576 52.2857 32.0576 52.2857C33.6185 49.5559 34.5804 46.5251 34.8797 43.3948C35.179 40.2646 34.8087 37.1064 33.7934 34.1303C33.7587 34.033 33.7511 33.9282 33.7716 33.8269C33.792 33.7257 33.8396 33.6319 33.9093 33.5557C33.9791 33.4795 34.0682 33.4237 34.1673 33.3944C34.2663 33.3651 34.3715 33.3633 34.4714 33.3893C37.6382 34.2527 47.1926 36.3707 54.0002 32.3999Z" />
      <path d="M23.1426 20.0571H36.8574" />
      <path d="M44.3999 20.0571H49.8857" />
      <path d="M15.6001 20.0571H10.1143" />
    </g>
  </svg>
);

export default IconRescheduleReservation;
