import React, { FC } from "react";

interface IIconCheckmarkProps {
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

const IconCheckmark: FC<IIconCheckmarkProps> = ({
  className = "",
  fill = "none",
  width = 20,
  height = 20,
}) => (
  <svg
    className={`Icon__Checkmark color-fill ${className}`}
    width={width}
    height={height}
    viewBox="0 0 15 11"
  >
    <path
      d="M5.0835 8.12891L13.0288 0.183594L14.0835 1.23828L5.0835 10.2383L0.899902 6.05469L1.95459 5L5.0835 8.12891Z"
      fill={fill}
    />
  </svg>
);

export default IconCheckmark;
