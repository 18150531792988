import "back-button.scss";
import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import IconArrowLeft from "../svg/arrow-left";

interface IBackButtonProps {
  onChange?(): void;
  children?: ReactNode;
  className?: string;
  label?: string;
  route?: string;
}

const BackButton: FC<IBackButtonProps> = ({
  className = "",
  label = "Back",
  route = "",
}) => {
  const navigate = useNavigate();
  return (
    <a onClick={() => navigate(route)} className={`BackButton ${className}`}>
      <IconArrowLeft />
      {label}
    </a>
  );
};

export default BackButton;
