import { action, computed, flow, makeObservable, observable } from "mobx";
import moment from "moment";
import { CloudAPI } from "../../api/cloud.api";
import { FlowType } from "../../types";
import { ApplicationStore } from "../application.store";
import {
  IAppointmentCountSummary,
  IAppointmentRecord,
  IFirstAppointment,
} from "../interfaces";
import { Store } from "../store";

export class AppointmentsUI extends Store {
  @observable loading: boolean = false;
  @observable lastAppointment: IAppointmentRecord | undefined;

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  reset = () => {
    this.loading = false;
    this.lastAppointment = undefined;
  };

  @action
  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  @flow
  *cancelAppointment(calendarId: number): FlowType {
    this.loading = true;
    try {
      yield CloudAPI.cancelAppointment(this.application, {
        calendarId: String(calendarId),
      });
      this.application.domain.resetLastPayment();
      this.application.domain.loadAppointments();
      this.loading = false;
    } catch (error) {
      console.error(error.message);
      this.loading = false;
    }
  }

  allowedToCancel = (calendarId: number) => {
    const hoursBefore = 2;
    const now = new Date();
    const appointment = this.getAppointment(calendarId);
    if (!appointment || appointment === undefined) return false;
    const appointmentTime = moment(appointment.date)
      .startOf("date")
      .add(Number(appointment.startTime) / 100, "hours");
    const twoHoursBefore = moment(appointmentTime).subtract(
      hoursBefore,
      "hours"
    );
    return moment(twoHoursBefore).isSameOrAfter(now);
  };

  sortByCreateDate = (appointments: IAppointmentRecord[]) => {
    if (appointments.length === 0) return [];
    return appointments.slice().sort((a, b) => {
      if (!a.createDate) return 0;
      if (!b.createDate) return 0;
      const dateA = new Date(a.createDate);
      const dateB = new Date(b.createDate);
      return dateB.getTime() - dateA.getTime();
    });
  };

  displayMilitaryTime = (militaryTime: number) => {
    if (this.lastAppointment === null) return "00:00";
    return moment(new Date())
      .startOf("date")
      .add(militaryTime / 100, "hours")
      .format("h:mm A");
  };

  getLastAppointment() {
    if (this.application.domain.appointments.length === 0) return;
    const appointments = this.sortByCreateDate(
      this.application.domain.appointments
    );
    this.lastAppointment = appointments[0];
    return this.lastAppointment;
  }

  getAppointment(calendarId: number) {
    if (this.application.domain.appointments.length === 0) return;
    const appointment = this.application.domain.appointments.find(
      (appointment) => appointment.calendarId === calendarId
    );
    return appointment;
  }

  @computed
  get counts(): IAppointmentCountSummary {
    const base = {
      total: -1,
      completed: -1,
      open: -1,
      cancelled: -1,
    };
    if (!this.application.domain.appointmentCounts) return base;
    const counts = this.application.domain.appointmentCounts;

    return {
      total: counts.TOTAL ?? -1,
      completed: counts.COMPLETED ?? -1,
      open: counts.OPEN ? counts.OPEN : -1,
      cancelled: counts.CANCELLED ? counts.CANCELLED : -1,
    };
  }

  @computed
  get first(): IFirstAppointment {
    const base = {
      startHour: 0,
      endHour: 2400,
    };
    if (!this.application.domain.applicationConfig) return base;
    const firstAppointment =
      this.application.domain.applicationConfig.data?.settings.firstAppointment;
    if (!firstAppointment) return base;
    return {
      startHour: firstAppointment.startHour
        ? firstAppointment.startHour
        : base.startHour,
      endHour: firstAppointment.endHour
        ? firstAppointment.endHour
        : base.endHour,
    };
  }
}
