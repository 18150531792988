import { observer } from "mobx-react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { when } from "../../..//client/util/when";
import { ENV } from "../../../client/env";
import { Application } from "../../../client/store";
import "./form.scss";
import "./signup.scss";

// UI components
import IconLogo from "app/client/svg/logo";
import FormError from "app/client/ui/form-error";
import LoadingAnimation from "app/client/ui/loading";
import SubmitButton from "app/client/ui/submit-button";
import TextInput from "app/client/ui/text-input";

/**
 * Signup form
 */
export const SignupPage = observer(() => {
  const navigate = useNavigate();
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formRef.current) return;
    const formData = new FormData(formRef.current);
    const email = String(formData.get("email"));
    const password = String(formData.get("password"));
    const password_retype = String(formData.get("password_retype"));
    const name = String(formData.get("full_name"));
    const data = {
      email: email,
      password: password,
      password_retype: password_retype,
      name: name,
    };
    Application.ui.login.reset();
    Application.ui.signup.submitForm(data);
  };

  const inputChanged = () => {
    Application.ui.signup.resetFormError();
  };

  return (
    <div className={`SignupPage FormPage full-screen`}>
      {when(Application.ui.signup.loading, <LoadingAnimation />)}
      <div className="FormPage-left"></div>
      <div className="FormPage-right">
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="FormPage-form">
            <div className="FormPage-form-title">
              <div className="FormPage-form-logo">
                <IconLogo />
              </div>
              <h1>Create Account</h1>
            </div>
            <div className="FormPage-form-fields">
              {Application.ui.signup.formError ? (
                <FormError label={Application.ui.signup.formError} />
              ) : (
                ""
              )}
              <TextInput
                name={"full_name"}
                placeholder={"Full Name"}
                onChange={inputChanged}
                autofocus
              ></TextInput>
              <TextInput
                name={"email"}
                placeholder={"Email Address"}
                onChange={inputChanged}
              ></TextInput>
              <TextInput
                name={"password"}
                type="password"
                placeholder={"Password"}
                onChange={inputChanged}
              ></TextInput>
              <TextInput
                name={"password_retype"}
                type="password"
                placeholder={"Retype password"}
                onChange={inputChanged}
              ></TextInput>
              <SubmitButton label="Create Account & Login" />
            </div>
          </div>
        </form>
        <div className="FormPage-links">
          <a onClick={() => navigate(ENV.routes.forgotPassword)}>
            Forgot password
          </a>
          <a onClick={() => navigate(ENV.routes.login)}>
            Already have an account? Login in now.
          </a>
        </div>
        <div className="FormPage-copyright">
          Copyright © City Golf Club 2023.
        </div>
      </div>
    </div>
  );
});
