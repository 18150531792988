import { observer } from "mobx-react-lite";
import "payment-methods.scss";
import React, { FC, useState } from "react";
import { Application } from "../../store";
import { IPaymentMethod } from "../../store/interfaces";
import CheckButton from "../schedule/check-button";

interface IPaymentMethodPickerProps {
  onClick?(method: IPaymentMethod): void;
  className?: string;
}

/**
 * An example of a page being made
 */
export const PaymentMethodPicker: FC<IPaymentMethodPickerProps> = observer(
  ({ onClick, className = "" }) => {
    const [paymentId, setPaymentId] = useState<string | null>(
      Application.domain.defaultPaymentId
    );

    // Select a method
    const handleClick = (method: IPaymentMethod) => {
      setPaymentId(method.id);
      Application.domain.setSelectedPaymentId(method.id);
      onClick?.(method);
    };

    return (
      <div className={`PaymentMethodPicker ${className}`.trim()}>
        {Application.ui.account.paymentMethods.map(
          (method: IPaymentMethod, index) => {
            const isDefault = method.id === paymentId ? true : false;
            const title = `${method.card.brand} ****${method.card.last4}`;
            const subtitle = `${method.card.exp_month}/${method.card.exp_year}`;
            return (
              <CheckButton
                onClick={() => handleClick(method)}
                selected={isDefault}
                title={`${title} exp: ${subtitle}`}
                key={index}
              />
            );
          }
        )}
      </div>
    );
  }
);
