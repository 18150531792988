import { Application } from "app/client/store";
import { observer } from "mobx-react";
import React, { FC } from "react";
import "type-picker.scss";
import IconCheckmark from "../../svg/checkmark";

// interface for ITypePickerProps
interface ITypePickerProps {
  className?: string;
}

const TypePicker: FC<ITypePickerProps> = ({ className = "" }) => {
  const handleClick = (reasonId: number) => {
    Application.ui.schedule.setSelectedReasonId(reasonId);
  };

  return (
    <div className={`TypePicker ${className}`}>
      {Application.domain.applicationConfig.data?.types.map((type, index) => (
        <div
          className={`TypePicker__Type ${
            Application.ui.schedule.type?.reasonId === type.reasonId
              ? "selected"
              : ""
          }`}
          onClick={() => handleClick(Number(type.reasonId))}
          key={index}
        >
          <div className="TypePicker__Type-name">{type.name}</div>
          <IconCheckmark width={20} height={20} />
        </div>
      ))}
    </div>
  );
};

export default observer(TypePicker);
