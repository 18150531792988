import { observer } from "mobx-react";
import React, { FC } from "react";
import { Application } from "../../store";

interface IBookingVariablesProps {
  className?: string;
}

const BookingVariables: FC<IBookingVariablesProps> = observer(
  ({ className = "" }) => (
    <div className={`ShowValues ${className}`.trim()}>
      <div>
        <span>readyToPay</span>
        <span>{Application.ui.schedule.readyToPay ? "true" : "false"}</span>
      </div>
      <div>
        <span>readyToCheckout</span>
        <span>
          {Application.ui.schedule.readyToCheckout ? "true" : "false"}
        </span>
      </div>
      <div>
        <span>locationAmount</span>
        <span>{Application.ui.schedule.locationAmount}</span>
      </div>
      <div>
        <span>price.id</span>
        <span>{Application.ui.schedule.price?.id}</span>
      </div>
      <div>
        <span>stripeCustomerId</span>
        <span>{Application.ui.schedule.stripeCustomerId}</span>
      </div>
      <div>
        <span>membership</span>
        <span>{Application.ui.schedule.membership}</span>
      </div>
      <div>
        <span>timeslotStartDate</span>
        <span>{Application.ui.schedule.timeslotStartDate.toDateString()}</span>
      </div>
      <div>
        <span>timeslotEndDate</span>
        <span>{Application.ui.schedule.timeslotEndDate.toDateString()}</span>
      </div>
      <div>
        <span>selectedBayId</span>
        <span>{Application.ui.schedule.selectedBayId}</span>
      </div>
      <div>
        <span>selectedReasonId</span>
        <span>{Application.ui.schedule.selectedReasonId}</span>
      </div>
      <div>
        <span>selectedLocationId</span>
        <span>{Application.ui.schedule.selectedLocationId}</span>
      </div>
      <div>
        <span>guests</span>
        <span>{Application.ui.schedule.guests}</span>
      </div>
      <div>
        <span>bay.name</span>
        <span>{Application.ui.schedule.bay?.name}</span>
      </div>
      <div>
        <span>location.name</span>
        <span>{Application.ui.schedule.location?.name}</span>
      </div>
      <div>
        <span>type.name</span>
        <span>{Application.ui.schedule.type?.name}</span>
      </div>
      <div>
        <span>paymentDescription</span>
        <span>{Application.ui.schedule.paymentDescription}</span>
      </div>
      <div>
        <span>session.timetapClientId</span>
        <span>{Application.session.timetapClientId}</span>
      </div>
      <div>
        <span>staff count</span>
        <span>{Application.domain.staff.length}</span>
      </div>
      <div>
        <span>session.membership.maxAppointments</span>
        <span>{Application.session.membership?.maxAppointments}</span>
      </div>
      <div>
        <span>ui.appointments.counts.completed</span>
        <span>{Application.ui.appointments.counts.completed}</span>
      </div>
      <div>
        <span>ui.appointments.counts.open</span>
        <span>{Application.ui.appointments.counts.open}</span>
      </div>
      <div>
        <span>ui.appointments.counts.cancelled</span>
        <span>{Application.ui.appointments.counts.cancelled}</span>
      </div>
      <div>
        <span>ui.appointments.counts.total</span>
        <span>{Application.ui.appointments.counts.total}</span>
      </div>
      <div>
        <span>ui.appointments.first.startHour</span>
        <span>{Application.ui.appointments.first.startHour}</span>
      </div>
      <div>
        <span>ui.appointments.first.endHour</span>
        <span>{Application.ui.appointments.first.endHour}</span>
      </div>
      <div>
        <span>domain.timeslots.length</span>
        <span>{Application.domain.timeslots.length}</span>
      </div>
      <div>
        <span>userDoc.lastLocationId</span>
        <span>{Application.session.userDoc?.lastLocationId}</span>
      </div>
    </div>
  )
);

export default BookingVariables;
