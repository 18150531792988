import React, { FC } from "react";

interface IIconVideoProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconVideo: FC<IIconVideoProps> = ({
  className = "",
  width = 20,
  height = 20,
  opacity = 0.5,
  fill = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={`Icon__Video color-fill ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M14.6758 5.32422H5.32422C5.01432 5.32422 4.74089 5.44271 4.50391 5.67969C4.28516 5.89844 4.17578 6.17188 4.17578 6.5V13.5C4.17578 13.8281 4.28516 14.1107 4.50391 14.3477C4.74089 14.5664 5.01432 14.6758 5.32422 14.6758H14.6758C14.9857 14.6758 15.25 14.5664 15.4688 14.3477C15.7057 14.1107 15.8242 13.8281 15.8242 13.5V6.5C15.8242 6.17188 15.7057 5.89844 15.4688 5.67969C15.25 5.44271 14.9857 5.32422 14.6758 5.32422ZM8.55078 12.625V7.375L12.625 10L8.55078 12.625Z" />
    </g>
  </svg>
);

export default IconVideo;
