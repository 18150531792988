import "close-button.scss";
import React, { FC } from "react";
import IconClose from "../svg/close";

interface ICloseButtonProps {
  onClick?(): void;
  className?: string;
}

const CloseButton: FC<ICloseButtonProps> = ({ onClick, className = "" }) => (
  <a onClick={onClick} className={`CloseButton ${className}`}>
    <IconClose />
  </a>
);

export default CloseButton;
