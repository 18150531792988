import { action, flow, makeObservable, observable } from "mobx";
import { FlowType } from "../../types";
import { ApplicationStore } from "../application.store";
import { Store } from "../store";

interface IFormData {
  email: string;
  name: string;
  password: string;
  password_retype: string;
}

interface IResetFormData {
  email: string;
}

export class SignupUI extends Store {
  @observable loading: boolean = false;
  @observable formError: string | null = null;
  @observable signupEmail: string;
  @observable signupName: string;

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  resetFormError() {
    this.formError = null;
  }

  isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  @flow
  *sendResetPasswordEmail(data: IResetFormData): FlowType {
    const { email } = data;
    try {
      if (!email) {
        throw new Error(
          "Please complete all fields before submitting the form."
        );
      }
      if (this.isValidEmail(email) === false) {
        throw new Error("The email you entered does not appear to be valid.");
      }
      yield this.application.session.sendResetEmail(email);
    } catch (error) {
      const errorMessage = error.message || "";
      if (errorMessage.includes("requires-recent-login")) {
        this.formError = "You must login again to update your password";
      } else if (errorMessage.includes("weak-password")) {
        this.formError = "Your password is too weak";
      } else if (errorMessage.includes("invalid-password")) {
        this.formError = "Your password is invalid";
      } else if (errorMessage.includes("user-mismatch")) {
        this.formError = "User mismatch";
      } else if (errorMessage.includes("user-not-found")) {
        this.formError = "User does not exist";
      } else if (errorMessage.includes("network-request-failed")) {
        this.formError = "Network error";
      } else {
        this.formError = "Form error";
      }
    }
  }

  @flow
  *submitForm(data: IFormData): FlowType {
    const { email, password, password_retype, name } = data;
    this.signupEmail = email;
    this.signupName = name;
    try {
      if (!email || !password || !password_retype || !name) {
        throw new Error(
          "Please complete all fields before submitting the form."
        );
      }
      if (this.isValidEmail(email) === false) {
        throw new Error("Please enter a valid email address.");
      }
      if (password !== password_retype) {
        throw new Error("Passwords do not match.");
      }
      this.loading = true;
      yield this.application.session.createUser(data);
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.formError = error.message;
      this.loading = false;
    }
  }
}
