import { action, computed, flow, makeObservable, observable } from "mobx";
import moment from "moment";
import { NavigateFunction } from "react-router-dom";
import { CloudAPI } from "../../api/cloud.api";
import { FlowType } from "../../types";
import { ApplicationStore } from "../application.store";
import {
  IAppointmentRecord,
  ILocation,
  IStripePrice,
  ITimetapStaff,
} from "../interfaces";
import { Store } from "../store";

interface ITimeslotsByDate {
  [date: string]: boolean;
}

interface ITimeslotsByDateOnly {
  [date: string]: number;
}

export interface IAppointment {
  calendarId?: number;
  locationId?: number;
  staffId?: number;
  reasonId?: number;
  clientId?: number;
  startDate?: string;
  startTime?: number;
  endTime?: number;
  guests?: number;
  note?: string | null;
  status?: string | null;
  stripeId?: string | null;
}

export interface IValidateAppointment extends Record<string, string> {
  membership: string;
  date: string;
  startTime: string;
  appointments: string;
}

export class ScheduleUI extends Store {
  // Required for booking
  @observable selectedLocationId: number = -1;
  @observable selectedBayId: number = -1;
  @observable selectedReasonId: number = -1;
  @observable startDate: Date = new Date();
  @observable startTime: number = 0;
  @observable endTime: number = 0;
  @observable error: string | null = "Please return to schedule";

  // Other booking data
  @observable guests: number = 0;
  @observable defaultDays: number = 7;
  @observable screenWidth: number = 0;
  @observable viewDate: Date = new Date();
  @observable scheduling: boolean = false;

  @observable timeslotStartDate: Date = new Date();
  @observable timeslotEndDate: Date = new Date();
  @observable appointment: IAppointmentRecord | null = null;

  // Navigate function
  navigate: NavigateFunction;

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  reset() {
    this.startDate = new Date();
    this.timeslotStartDate = new Date();
    this.timeslotEndDate = new Date();
    this.startTime = 0;
    this.endTime = 0;
    this.guests = 0;
    this.application.domain.resetTimeslots();
    this.error = null;
  }

  @action
  setAppointment(appointment: IAppointmentRecord) {
    this.appointment = appointment;
  }

  @action
  changedLocation() {
    this.selectedBayId = -1;
    this.startTime = 0;
    this.endTime = 0;
  }

  @action
  setScreenWidth(width: number) {
    this.screenWidth = width;
  }

  @action
  setViewDate(date: Date) {
    this.viewDate = date;
  }

  @action
  setSelectedLocationId(id: number) {
    this.selectedLocationId = id;
  }

  @computed
  get stripeCustomerId() {
    if (!this.application.session.userDoc) return null;
    return this.application.session.userDoc.stripeCustomerId;
  }

  @computed
  get loading() {
    return this.application.domain.loadingTimeslots;
  }

  @computed
  get membership() {
    if (!this.application.session.userDoc) return null;
    if (!this.application.session.userDoc.membership) return null;
    return this.application.session.userDoc.membership.toLowerCase();
  }

  @computed
  get viewDateIsToday() {
    return new Date().toDateString() === this.viewDate.toDateString()
      ? true
      : false;
  }

  @computed
  get readyToCheckout(): boolean {
    if (
      this.selectedBayId &&
      this.selectedReasonId &&
      this.startTime &&
      this.startDate
    ) {
      return true;
    }
    return false;
  }

  @computed
  get price(): IStripePrice | undefined {
    if (!this.application.domain.products) return;
    if (!this.application.domain.products.reservation) return;
    return this.application.domain.products.reservation.price;
  }

  move = (direction: string) => {
    const moveDayCount =
      direction === "left" ? this.displayDays * -1 : this.displayDays;

    let newDate = new Date(
      this.viewDate.getTime() + moveDayCount * 24 * 60 * 60 * 1000
    );

    // Preview the user from navigating to a date in the past
    if (newDate < new Date()) {
      newDate = new Date();
    }

    // Set the view date
    this.setViewDate(newDate);

    // Set the timeslot window
    this.setViewWindow();
  };

  @computed
  get displayDays() {
    let numDays = this.defaultDays;
    if (this.bays <= 3) {
      if (this.screenWidth <= 768) numDays = 1;
      if (this.screenWidth > 768 && this.screenWidth <= 820) numDays = 2;
      if (this.screenWidth > 820 && this.screenWidth <= 1180) numDays = 3;
      if (this.screenWidth > 1180 && this.screenWidth <= 1400) numDays = 5;
      if (this.screenWidth >= 1400) numDays = 7;
    }
    if (this.bays >= 4) {
      if (this.screenWidth <= 768) numDays = 1;
      if (this.screenWidth > 768 && this.screenWidth <= 1000) numDays = 2;
      if (this.screenWidth > 1000 && this.screenWidth <= 1200) numDays = 3;
      if (this.screenWidth > 1200 && this.screenWidth <= 1500) numDays = 4;
      if (this.screenWidth >= 1500) numDays = 5;
    }
    return numDays;
  }

  @computed
  get displayHelp() {
    return moment(this.viewDate).format("YYYY-MM-DD");
  }

  @computed
  get days() {
    const dates = [];
    for (let i = 0; i < this.displayDays; i++) {
      const date = new Date(this.viewDate.getTime() + i * 24 * 60 * 60 * 1000);
      date.setHours(0, 0, 0, 0);
      dates.push(date);
    }
    return dates;
  }

  @action
  setViewWindow() {
    let startDate = new Date(this.viewDate.getTime());

    // If startDate is before today, set to today
    if (startDate < new Date()) {
      startDate = new Date();
    }

    // Add days to startDate
    const endDate = moment(startDate).add(this.displayDays, "days").toDate();

    // Set defaults for selectedBayId && selectedReasonId if not set
    this.setDefaults();

    this.timeslotStartDate = startDate;
    this.timeslotEndDate = endDate;

    this.application.domain.loadTimeslots({
      locationId: this.selectedLocationId,
      reasonId: this.selectedReasonId,
      startDate: this.timeslotStartDate,
      endDate: this.timeslotEndDate,
    });
  }

  @action
  setLocationId(locationId: number) {
    this.selectedLocationId = locationId;
    this.application.domain.loadTimeslots({
      locationId: this.selectedLocationId,
      reasonId: this.selectedReasonId,
      startDate: this.timeslotStartDate,
      endDate: this.timeslotEndDate,
    });
  }

  @action
  setSelectedBayId(bayId: number) {
    this.selectedBayId = bayId;
  }

  @action
  setSelectedReasonId(typeId: number) {
    const oldTypeId = this.selectedReasonId;
    // Reset if changing type
    if (typeId !== oldTypeId) {
      this.startTime = 0;
      this.endTime = 0;
    }
    this.selectedReasonId = typeId;
    this.application.domain.loadTimeslots({
      locationId: this.selectedLocationId,
      reasonId: this.selectedReasonId,
      startDate: this.timeslotStartDate,
      endDate: this.timeslotEndDate,
    });
  }

  @action
  guestsUp() {
    this.guests = this.guests + 1;
    if (this.guests > this.maxGuests) this.guests = this.maxGuests;
  }

  @action
  guestsDown() {
    this.guests = this.guests - 1;
    if (this.guests <= 0) this.guests = 0;
  }

  @action
  setStartDate(date: Date) {
    this.startDate = date;
  }

  @action
  setStartTime(time: number) {
    this.startTime = time;
  }

  @action
  setEndTime(time: number) {
    this.endTime = time;
  }

  @action
  setGuests(guests: number) {
    this.guests = guests;
  }

  @computed
  get paymentDescription(): string {
    if (!this.type) return "";
    if (!this.location) return "";
    if (!this.bay) return "";
    return `${this.bay.name} ${this.type.name} at ${this.location.name}`;
  }

  @computed
  get finalizeButtonLabel() {
    let label = "Book Now";
    if (this.application.session.userDoc?.membership === "") {
      label = "Finalize";
    }
    if (this.locationAmount) {
      label = `${label} & Pay`;
    }
    return label;
  }

  @computed
  get startTimeDisplay() {
    if (this.startTime <= 0) return "00:00";
    return moment(this.startDate)
      .startOf("date")
      .add(this.startTime / 100, "hours")
      .format("h:mm A");
  }

  @computed
  get endTimeDisplay() {
    if (!this.endTime) return "00:00";
    return moment(this.startDate)
      .startOf("date")
      .add(this.endTime / 100, "hours")
      .format("h:mm A");
  }

  @flow
  *bookAppointment(): FlowType {
    // Make sure the user's information is loaded
    if (!this.application.session.userDoc) return;
    this.scheduling = true;
    this.error = null;
    const verifyData: IValidateAppointment = {
      membership: this.application.session.userDoc.membership,
      date: moment(this.startDate).format("YYYY-MM-DD"),
      startTime: String(this.startTime),
      appointments: String(this.application.domain.appointments.length),
    };

    // Book appointment here
    const data: IAppointment = {
      locationId: Number(this.selectedLocationId),
      staffId: Number(this.selectedBayId),
      reasonId: Number(this.selectedReasonId),
      clientId: Number(this.application.session.timetapClientId),
      startDate: moment(this.startDate).format("YYYY-MM-DD"),
      startTime: this.startTime,
      endTime: this.endTime,
      guests: this.guests,
      stripeId: "",
    };

    // Add latest charge to the appointment
    if (this.application.domain.lastPayment) {
      data.stripeId = this.application.domain.lastPayment.latest_charge;
    }

    try {
      // Verify the appointment is still available
      const verify = yield CloudAPI.validateAppointment(
        this.application,
        verifyData
      );

      if (verify.error) {
        throw new Error(verify.error);
      }

      // Book the appointment
      const results = yield CloudAPI.bookAppointment(this.application, data);

      if (results.error) {
        throw new Error(results.error);
      }

      const appointment = results.appointment;
      this.setAppointment(appointment);
      yield this.application.domain.loadAppointments();
      this.scheduling = false;
    } catch (error) {
      console.error(error.message);
      this.error = error.message;
      this.scheduling = false;
    }
  }

  @flow
  *setDefaults(): FlowType {
    yield this.application.domain.loadApplicationConfigOnce();
    if (this.selectedLocationId <= 0) {
      let locationId = -1;
      // Set default to lastLocationId if set
      if (this.application.session.userDoc?.lastLocationId) {
        locationId = this.application.session.userDoc.lastLocationId;
      }
      // If still unset, get first userDoc.memberLocations
      if (locationId <= 0) {
        if (this.application.session.userDoc?.membershipLocations) {
          locationId = this.application.session.userDoc.membershipLocations[0];
        }
      }
      // If still nothing, then load from locations list
      if (locationId <= 0) {
        // locationId =
        //   this.application.domain.applicationConfig.data?.locations[0]
        //     .locationId || -1;
      }
      this.setLocationId(locationId);
    }
    if (this.selectedReasonId <= 0) {
      this.setSelectedReasonId(
        this.application.domain.applicationConfig.data?.types[0].reasonId || -1
      );
    }
  }

  timeslotAvailable(startDate: string, startTime: number, staffId: number) {
    const key = `${startDate}.${startTime}.${staffId}`;
    return key in this.timeslots ? true : false;
  }

  matchesClientAppointment(
    startDate: string,
    startTime: number,
    staffId: number
  ) {
    const key = `${startDate}.${startTime}.${staffId}`;
    return key in this.appointments ? true : false;
  }

  matchesLocationAppointment(startDate: string, startTime: number) {
    const key = `${startDate}.${startTime}.${this.selectedLocationId}`;
    return key in this.appointmentsByLocation ? true : false;
  }

  insideBookingWindow(date: Date) {
    if (!this.application.session.membership?.window.endDate) return true;
    const day = moment(date);
    const lastBookableDay = moment(
      this.application.session.membership.window.endDate
    );
    return day.isSameOrBefore(lastBookableDay);
  }

  checkMembershipLocation(locationId: number) {
    return this.application.session.hasLocationMembership(locationId);
  }

  @flow
  *processPayment(): FlowType {
    if (!this.application.domain.paymentIntent) return;
    if (!this.application.domain.selectedPaymentId) return;
    const data = {
      paymentId: this.application.domain.selectedPaymentId,
      intentId: this.application.domain.paymentIntent.intentId,
    };
    try {
      const results = yield CloudAPI.processPayment(this.application, data);
      if (!results.success) {
        throw new Error(results.error);
      }
      this.application.domain.setLastPayment(results.payment);
      return results.payment;
    } catch (error) {
      console.error(error.message);
    }
    return;
  }

  @computed
  get isLocationMember() {
    return this.checkMembershipLocation(this.selectedLocationId);
  }

  @computed
  get formattedAmount() {
    return (this.locationAmount / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  @computed
  get locationPriceId(): string {
    if (this.selectedLocationId === -1) return "";
    if (!this.application.domain.applicationConfig.data?.products) return "0";
    if (this.checkMembershipLocation(this.selectedLocationId)) return "0";
    if (!this.application.domain.products) return "";
    if (!this.application.domain.products.reservation) return "";
    return String(this.application.domain.products.reservation.price);
  }

  @computed
  get locationAmount(): number {
    if (this.selectedLocationId === -1) return 0;
    if (!this.application.domain.applicationConfig.data?.products) return 0;
    if (this.checkMembershipLocation(this.selectedLocationId)) return 0;
    if (!this.application.domain.products) return 0;
    if (!this.application.domain.products.reservation) return 0;
    return Number(
      this.application.domain.products.reservation.price?.unit_amount
    );
  }

  @computed
  get maxGuests() {
    if (!this.application.session.membership?.maxGuests) return 1;
    return this.application.session.membership?.maxGuests;
  }

  @computed
  get appointmentDateLabel() {
    if (this.startTime === 0) {
      return "Please select a time";
    }
    // If this.startDate is today, return "Today"
    if (moment(this.startDate).isSame(new Date(), "day")) {
      return "Today";
    }
    // If this.startDate is tomorrow, return "Tomorrow"
    const tomorrow = moment().add(1, "day");
    if (moment(this.startDate).isSame(tomorrow, "day")) {
      return "Tomorrow";
    }
    // Otherwise, return the date in the format "Monday, 1st January"
    return moment(this.startDate).format("dddd, MMMM Do");
  }

  @computed
  get exceededMaxAppointmentMessage(): string {
    let message = "";
    const openAppointments = this.application.ui.appointments.counts.open;
    const maxAppointments =
      this.application.session.membership?.maxAppointments || 0;
    const exceededMaxAppointments =
      Number(openAppointments) >= Number(maxAppointments);

    if (exceededMaxAppointments) {
      message = `Exceeded max appointment${
        maxAppointments > 1 ? "s" : ""
      } of ${maxAppointments}`;
    }
    return message;
  }

  @computed
  get restrictedBookingMessage() {
    let message = "Become a member to book on this day";
    if (!this.application.session.userDoc) {
      return message;
    }
    if (!this.application.session.userDoc.membership === null) {
      return message;
    }

    switch (
      String(this.application.session.userDoc?.membership).toLowerCase()
    ) {
      case "hogan":
        message = "Outside booking window for your membership";
        break;
      case "snead":
        message = "Outside booking window for your membership";
        break;
      case "nelson":
        message = "Outside booking window for your membership";
        break;
    }
    return message;
  }

  @computed
  get bays() {
    if (!this.location?.bays) return 0;
    return this.location?.bays.length;
  }

  @computed
  get readyToPay() {
    return (
      this.selectedLocationId > 0 &&
      this.selectedBayId > 0 &&
      this.selectedReasonId > 0 &&
      this.startTime > 0 &&
      this.endTime > 0
    );
  }

  @computed
  get appointmentCountByDate() {
    if (!this.application.domain.appointments.length) {
      return {};
    }

    const appointmentsRekey: {
      [key: string]: number;
    } = {};

    this.application.domain.appointments.forEach((appointment) => {
      const key = `${appointment.date}`;
      if (key in appointmentsRekey) {
        appointmentsRekey[key]++;
      } else {
        appointmentsRekey[key] = 1;
      }
    });

    return appointmentsRekey;
  }

  @computed
  get appointments(): {
    [key: string]: boolean;
  } {
    if (!this.application.domain.appointments.length) {
      return {};
    }

    const appointmentsRekey: {
      [key: string]: boolean;
    } = {};

    this.application.domain.appointments.forEach((appointment) => {
      const key = `${appointment.date}.${appointment.startTime}.${appointment.staffId}`;
      appointmentsRekey[key] = true;
    });

    return appointmentsRekey;
  }

  @computed
  get appointmentsByLocation(): {
    [key: string]: boolean;
  } {
    if (!this.application.domain.appointments.length) {
      return {};
    }

    const appointmentsRekey: {
      [key: string]: boolean;
    } = {};

    this.application.domain.appointments.forEach((appointment) => {
      const key = `${appointment.date}.${appointment.startTime}.${appointment.locationId}`;
      appointmentsRekey[key] = true;
    });

    return appointmentsRekey;
  }

  @computed
  get timeslots(): ITimeslotsByDate {
    if (!this.application.domain.timeslots.length) {
      return {};
    }

    const availableTimeSlots: {
      [key: string]: boolean;
    } = {};

    this.application.domain.timeslots.forEach((slot) => {
      const key = `${slot.date}.${slot.startTime}.${slot.staffId}`;
      availableTimeSlots[key] = true;
    });

    return availableTimeSlots;
  }

  @computed
  get timeslotCountByDate(): ITimeslotsByDateOnly {
    if (!this.application.domain.timeslots.length) {
      return {};
    }

    const availableTimeSlots: {
      [key: string]: number;
    } = {};

    this.application.domain.timeslots.forEach((slot) => {
      const key = `${slot.date}`;
      if (key in availableTimeSlots) availableTimeSlots[key]++;
      else availableTimeSlots[key] = 1;
    });

    return availableTimeSlots;
  }

  @computed
  get location(): ILocation | null {
    if (this.selectedLocationId < 0) {
      if (this.application.domain.loadingTimeslots) {
        return {
          available: false,
          locationId: -1,
          name: "Loading locations...",
          address: "",
          city: "",
          state: "",
          zip: "",
          image: "",
          bays: [],
        };
      }
      return (
        this.application.domain.applicationConfig.data?.locations[0] ?? null
      );
    }

    return (
      this.application.domain.applicationConfig.data?.locations.find(
        (l) => l.locationId === this.selectedLocationId
      ) ?? null
    );
  }

  @computed
  get bay(): ITimetapStaff | null {
    if (this.selectedBayId < 0) {
      return this.location?.bays[0] ?? null;
    }

    return (
      this.location?.bays.find((b) => b.staffId === this.selectedBayId) ?? null
    );
  }

  @computed
  get type() {
    if (this.selectedReasonId < 0) {
      return this.application.domain.applicationConfig.data?.types[0] ?? null;
    }

    return (
      this.application.domain.applicationConfig.data?.types.find(
        (l) => l.reasonId === this.selectedReasonId
      ) ?? null
    );
  }

  @computed
  get amCount() {
    const count = this.hours.filter((value) => value <= 1200).length;
    return count;
  }

  @computed
  get pmCount() {
    const count = this.hours.filter((value) => value > 1200).length;
    return count;
  }

  @computed
  get hours() {
    if (this.application.domain.loadingTimeslots === true) {
      return [1300, 1400, 1500, 1600, 1700];
    }

    if (
      !this.application.session.membership?.startHour &&
      !this.application.session.membership?.endHour
    ) {
      return [1300, 1400, 1500, 1600, 1700];
    }

    const startHour = this.application.session.membership.startHour;
    const endHour = this.application.session.membership.endHour;

    const hoursArray = [];
    for (let i = startHour; i <= endHour; i += 100) {
      hoursArray.push(i);
    }

    return hoursArray;
  }

  @computed
  get paymentId() {
    return this.application.domain.selectedPaymentId;
  }
}
