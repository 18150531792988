import { ENV } from "../env";
import { ApplicationStore } from "../store/application.store";
import { IAppointment } from "../store/ui/schedule.ui";
import { QueryParams } from "../util/to-query-params";
import { REST } from "./rest/rest";

// Make interface for timeslots query params
interface ICreateTimetapClient {
  firstName: string;
  lastName: string;
  email: string;
}

interface ICreateStripeCustomer {
  name: string;
  email: string;
  firebaseUserId?: string;
  timetapClientId?: string;
}

export interface IAPIPaymentIntent {
  success: boolean;
  error?: string;
  intent?: IPaymentIntent;
}

export interface IPaymentIntent {
  intentId: string;
  clientSecret: string;
  ephemeralKey: string;
  customerId: string;
  publishableKey: string;
  amount: number;
}

export const CloudAPI = {
  /**
   *
   * Get the store configuration
   *
   * No parameters required
   *
   * @returns {object} Containing locations, types, and staff
   */
  store: async (app: ApplicationStore) =>
    REST(app).GET(ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/store`).get),

  /**
   *
   * Get all the videos
   *
   * No parameters required
   *
   * @returns {object} Containing locations, types, and staff
   */
  videos: async (app: ApplicationStore) =>
    REST(app).GET(ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/videos`).get),

  /**
   *
   * Get array of staff
   *
   * No parameters required
   *
   * @returns {object} Array containing staff objects
   */
  getStaff: async (app: ApplicationStore) =>
    REST(app).GET(ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/getStaff`).get),

  /**
   *
   * Get available timeslots
   *
   * @param queryParams
   * @param {string} queryParams.reasonId   - Required: ReasonId to assign "Bay Reservation" or "Lesson"
   * @param {string} queryParams.locationId - Required: LocationId to assign "Dallas", "Preston Center", or "Cypress Waters"
   * @param {string} queryParams.startDate  - Required: "YYYY-MM-DD" beginning date of the requested range
   * @param {string} queryParams.endDate    - Required: "YYYY-MM-DD" ending date of the requested range
   * @param {string} queryParams.membership - Optional: String containing "hogan", "snead", or "nelson" that will determine time window
   * @returns
   */
  timeslots: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/timeslots`, data).get
    ),

  /**
   *
   * Get all future appointments for a client
   *
   * @param queryParams
   * @param {string} queryParams.clientId   - Required: Timetap ClientId
   * @returns
   */
  getAppointments: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/getAppointmentsByClient`, data)
        .get
    ),

  /**
   *
   * Get appointment
   *
   * @param queryParams
   * @param {string} queryParams.calendarId   - Required: Timetap calendarId
   * @returns
   */
  getAppointment: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/getAppointment`, data).get
    ),

  /**
   *
   * Get settings for membership
   *
   * @param queryParams
   * @param {string} queryParams.membership   - Required: Membership of the
   * @returns
   */
  getMembershipSettings: async (app: ApplicationStore, data?: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/membershipSettings`, data).get
    ),

  getTimetapClient: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/getClient`, data).get
    ),

  getStripeCustomer: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/getCustomer`, data).get
    ),

  createSubscription: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/createSubscription`).post,
      data
    ),

  updateSubscription: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).PUT(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/updateSubscription`).put,
      data
    ),

  /**
   *
   * Timetap: Create an appointment
   *
   * @param {Object} data
   * @param {string} data.locationId - Required: The locationId to use
   * @param {string} data.staffId    - Required: The staffId to use
   * @param {string} data.reasonId   - Required: The reasonId to use
   * @param {string} data.clientId   - Required: The clientId to use
   * @param {string} data.startDate  - Required: The startDate to use
   * @param {string} data.startTime  - Required: The startTime to use
   * @param {string} data.endTime    - Required: The endTime to use
   * @param {string} data.note       - Optional: The note to use
   * @param {string} data.status     - Optional: The status to use
   * @param {string} data.guests     - Optional: The number of guests
   *
   */
  bookAppointment: async (app: ApplicationStore, body: IAppointment) =>
    REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/createAppointment`).post,
      body
    ),

  /**
   *
   * Timetap: Update appointment
   *
   * @param {Object} data
   * @param {string} data.calendarId - Required: Existing calendarId to update
   * @param {string} data.clientId   - Required: clientId for verification
   * @param {string} data.startDate  - Required: New date
   * @param {string} data.startTime  - Required: New start time
   * @param {string} data.endTime    - Required: New end time
   *
   */
  updateAppointment: async (app: ApplicationStore, body: IAppointment) =>
    REST(app).PUT(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/updateAppointment`).put,
      body
    ),

  /**
   *
   * Get settings for membership
   *
   * @param queryParams
   * @param {string} queryParams.calendarId   - Required: CalendarId to delete
   * @param {string} queryParams.reason       - Optional: Reason it was deleted
   * @returns
   */
  cancelAppointment: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).PUT(
      ENV.useApi(ENV.hostConfig.cloud, 1, "/cgc/cancelAppointment").put,
      data
    ),

  /**
   *
   * Validate appointment based on membership eligibility
   *
   * @param {Object} data
   * @param {string} data.membership   - Required: The membership to use
   * @param {string} data.date         - Required: Date of the appointment
   * @param {string} data.startTime    - Required: Start time of the appointment
   * @param {string} data.appointments - Required: The number of appointments a client currently has
   */
  validateAppointment: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/cgc/validateAppointment`, data).get
    ),

  /**
   *
   * Create Timetap client
   *
   * @param body
   * @param {string} body.firstName  - Required: First name of the client
   * @param {string} body.lastName   - Required: Last name of the client
   * @param {string} body.email      - Required: Email address of the client
   * @returns
   */
  createTimetapClient: async (
    app: ApplicationStore,
    body: ICreateTimetapClient
  ) =>
    REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, "/cgc/createClient").post,
      body
    ),
  updateTimetapClient: async (app: ApplicationStore, body: QueryParams) =>
    REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, "/cgc/updateClient").post,
      body
    ),

  /**
   *
   * Create stripe customer
   *
   * @param body
   * @param {string} body.name              - Required: Name of the client
   * @param {string} body.email             - Required: Email address of the client
   * @param {string} body.firebaseUserId    - Optional: FIrebase user id
   * @param {string} body.timetapClientId   - Optional: Timetap client id
   * @returns
   */
  createStripeCustomer: async (
    app: ApplicationStore,
    body: ICreateStripeCustomer
  ) =>
    await REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, "/stripe/createCustomer").post,
      body
    ),

  /**
   *
   * Update stripe customer
   *
   * @param body
   * @param {string} body.customerId      - Required: The customer to update
   * @param {string} body.name            - Optional: Customer name
   * @param {string} body.paymentId       - Optional: Update default payment method
   * @returns
   */
  updateStripeCustomer: async (app: ApplicationStore, body: QueryParams) =>
    await REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, "/stripe/updateCustomer").post,
      body
    ),

  /**
   *
   * Get a price object
   *
   * @param data
   * @param {string} data.priceId   - Required: PriceId to retrieve
   * @returns
   */
  getPrice: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/getPrice`, data).get
    ),

  /**
   *
   * Get payment methods
   *
   * @param data
   * @param {string} data.customerId   - Required: CustomerId for stripe customer
   * @returns
   */
  getPaymentMethods: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/getPaymentMethods`, data).get
    ),

  /**
   *
   * Attach payment method to customer
   *
   * @param data
   * @param {string} data.customerId    - Required: CustomerId for stripe customer
   * @param {string} data.paymentId     - Required: PaymentId to attach
   * @returns
   */
  createPaymentMethod: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/createPaymentMethod`).post,
      data
    ),

  /**
   *
   * Delete payment method from customer
   *
   * @param data
   * @param {string} data.paymentId     - Required: PaymentId to attach
   * @returns
   */
  deletePaymentMethod: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).PUT(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/deletePaymentMethod`).put,
      data
    ),

  /**
   *
   * Get an array of price objects
   *
   * @param data
   * @param {string} data.priceId   - Required: String of comma separated priceIds to retrieve
   * @returns
   */
  getPrices: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/getPrices`, data).get
    ),

  /**
   *
   * Create payment intent
   *
   * @param data
   * @param {string} data.priceId       - Required: Stripe price Id
   * @param {string} data.customerId    - Required: Stripe customer Id
   * @param {string} data.description   - Required: Description for the transaction
   * @returns
   */
  getPaymentIntent: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/getPaymentIntent`, data).get
    ),

  /**
   *
   * Cancel payment intent
   *
   * @param data
   * @param {string} data.intentId       - Required: The intentId to cancel
   * @returns
   */
  cancelPaymentIntent: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).GET(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/cancelPaymentIntent`, data)
        .get
    ),

  /**
   *
   * Process payment
   *
   * @param data
   * @param {string} data.paymentId   - Required: id for the payment method
   * @param {string} data.intentId    - Required: ID for the payment intent
   * @returns
   */
  processPayment: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/processPayment`).post,
      data
    ),
  refund: async (app: ApplicationStore, data: QueryParams) =>
    REST(app).POST(
      ENV.useApi(ENV.hostConfig.cloud, 1, `/stripe/refund`).post,
      data
    ),
};
