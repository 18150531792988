import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { Application } from "../../store/application.store";
import { IStripePrice } from "../../store/interfaces";
import { when } from "../../util/when";

interface IPricePicker {
  onClick?(price?: IStripePrice): void;
  className?: string;
  title: string;
  monthly?: IStripePrice | null | undefined;
  annually?: IStripePrice | null | undefined;
}

const PricePicker: FC<IPricePicker> = ({
  className = "",
  monthly = null,
  annually = null,
  title,
}) => {
  // state variable for price
  const [price, setPrice] = useState<IStripePrice | null | undefined>(
    monthly ? monthly : annually
  );

  const handlePriceChange = (option: "monthly" | "annually") => {
    setPrice(option === "monthly" ? monthly : annually);
    Application.ui.account.setSelectedPeriod(title, option);
  };

  // set price on initial render
  useEffect(() => {
    if (monthly) {
      setPrice(monthly);
      Application.ui.account.setSelectedPeriod(title, "monthly");
    } else if (annually) {
      setPrice(annually);
      Application.ui.account.setSelectedPeriod(title, "annually");
    }
  }, [monthly, annually]);

  return (
    <div className={`PricePicker ${className}`.trim()}>
      <div className="PricePicker-price">
        {price
          ? (price.unit_amount / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : ""}
      </div>
      <div className="PricePicker-buttons">
        {when(
          monthly,
          <a
            className={
              Application.ui.account.selectedPeriods[title] === "monthly"
                ? "selected"
                : ""
            }
            onClick={() => handlePriceChange("monthly")}
          >
            Monthly
          </a>
        )}
        {when(
          annually,
          <a
            className={
              Application.ui.account.selectedPeriods[title] === "annually"
                ? "selected"
                : ""
            }
            onClick={() => handlePriceChange("annually")}
          >
            Annually
          </a>
        )}
      </div>
    </div>
  );
};

export default observer(PricePicker);
