import {
  CloudAPI,
  IAPIPaymentIntent,
  IPaymentIntent,
} from "app/client/api/cloud.api";
import { action, computed, flow, makeObservable, observable } from "mobx";
import moment from "moment";
import { FlowType } from "../../types";
import { PromiseResolver } from "../../util/promise-resolver";
import { ApplicationStore } from "../application.store";
import { IAPIResource } from "../generic/api-resource";
import {
  IApplicationConfig,
  IAppointmentCounts,
  IAppointmentRecord,
  ILocation,
  IMember,
  IProducts,
  isApplicationConfig,
  IStripePayment,
  IStripePrice,
  ITimeslot,
  ITimeslotsParams,
  ITimetapStaff,
  IVideos,
} from "../interfaces";
import { Store } from "../store";

/**
 * This stores data that is loaded from the domain server. This helps manage
 * pagination and other nuances to loading data from the server.
 */
export class DomainStore extends Store {
  /**
   * This is the static configuration object that handles that lacking of
   * Timetaps abilities to make deeper associations.
   */
  @observable applicationConfig: IAPIResource<
    IApplicationConfig | null,
    PromiseResolver<void>
  > = {
    isLoading: false,
    data: null,
    ctx: new PromiseResolver<void>(),
  };

  @observable membershipPrices: IStripePrice[] = [];

  // Timeslot data
  @observable timeslots: ITimeslot[] = [];
  @observable appointment: IAppointmentRecord | null;
  @observable appointments: IAppointmentRecord[] = [];
  @observable staff: ITimetapStaff[] = [];
  @observable appointmentCounts: IAppointmentCounts = {};
  @observable loadingAppointments = false;
  @observable loadingTimeslots = true;

  // Member data
  @observable member: IMember | null;
  @observable memberStartHour: number = 0;
  @observable memberEndHour: number = 0;
  @observable products: IProducts | null = null;
  @observable paymentIntent: IPaymentIntent | null = null;
  @observable paymentIntentLoading: boolean = false;
  @observable selectedPaymentId: string | null;
  @observable lastPayment: IStripePayment | null = null;
  @observable video: IVideos | null = null;

  // TEST MODE
  @observable testMode: boolean = false;

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  toggleTestMode() {
    this.testMode = !this.testMode;
  }

  @action
  reset() {
    this.timeslots = [];
    this.appointments = [];
    this.appointment = null;
    this.member = null;
    this.memberStartHour = 0;
    this.memberEndHour = 0;
    this.paymentIntent = null;
    this.selectedPaymentId = null;
    this.lastPayment = null;
    this.video = null;
  }

  getLocation(locationId: number): ILocation | undefined {
    if (!this.applicationConfig.data) return;
    const location = this.applicationConfig.data.locations.filter(
      (location) => location.locationId === locationId
    )[0];
    return location;
  }

  getType(reasonId: number) {
    if (!this.applicationConfig.data) return;
    const type = this.applicationConfig.data.types.filter(
      (type) => type.reasonId === reasonId
    )[0];
    return type;
  }

  getStaff(staffId: number) {
    if (!this.applicationConfig.data) return;
    const staff = this.applicationConfig.data.staff.filter(
      (staff) => staff.staffId === staffId
    )[0];
    return staff;
  }

  appointmentMatchesTimeslot(date: string, startTime: number, bayId: number) {
    if (!this.appointment) return false;
    return (
      this.appointment.date === date &&
      startTime === this.appointment.startTime &&
      bayId === this.appointment.staffId
    );
  }

  getLocationPrice(locationId: number) {
    return locationId;
  }

  @action
  setLastPayment(payment: IStripePayment) {
    this.lastPayment = payment;
  }

  @action
  setSelectedPaymentId(paymentId: string) {
    this.selectedPaymentId = paymentId;
  }

  @action
  resetLastPayment() {
    this.lastPayment = null;
  }

  @flow
  *resetPaymentIntent(): FlowType {
    if (this.paymentIntent?.intentId) {
      CloudAPI.cancelPaymentIntent(this.application, {
        intentId: this.paymentIntent.intentId,
      });
    }
    this.paymentIntent = null;
  }

  @action
  resetAppointment() {
    this.appointment = null;
  }

  @action
  resetTimeslots() {
    this.timeslots = [];
  }

  @action
  setMemberStartHour(hour: number) {
    this.memberStartHour = hour;
  }

  @action
  setMemberEndHour(hour: number) {
    this.memberEndHour = hour;
  }

  @action
  setAppointment(appointment: IAppointmentRecord) {
    this.appointment = appointment;
  }

  @action
  setTimeslots = (timeslots: ITimeslot[]) => {
    this.timeslots = timeslots;
  };

  @flow
  *loadAppointment(calendarId: number): FlowType {
    const data = {
      calendarId: String(calendarId),
    };
    try {
      const response = yield CloudAPI.getAppointment(this.application, data);
      const appointment = this.normalizeAppointment(response.appointment);
      this.setAppointment(appointment);
    } catch (error) {
      console.error(error);
    }
    return this.appointment;
  }

  @flow
  *loadTimeslots(data: ITimeslotsParams): FlowType {
    const { locationId, reasonId, startDate, endDate } = data;
    if (!locationId || locationId === -1) return;
    if (!reasonId || reasonId === -1) return;

    this.loadingTimeslots = true;

    // Make sure the user is loaded
    yield this.application.session.getUserDoc();

    const queryParams = {
      locationId: locationId.toString(),
      reasonId: reasonId.toString(),
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      membership: this.application.session.membershipName,
    };

    try {
      const response = yield CloudAPI.timeslots(this.application, queryParams);
      if (!response) return;
      this.setMemberStartHour(response.member.startHour);
      this.setMemberEndHour(response.member.endHour);
      this.setTimeslots(response.timeslots);
      this.loadingTimeslots = false;
    } catch (error) {
      console.error(error);
      this.loadingTimeslots = false;
    }
  }

  @flow
  *getPrice(priceId: string): FlowType {
    const response = yield CloudAPI.getPrice(this.application, {
      priceId: priceId,
    });
    return response.price;
  }

  @flow
  *getPrices(): FlowType {
    if (!this.applicationConfig.data || !this.applicationConfig.data.products) {
      return;
    }
    try {
      const reservation = this.applicationConfig.data.products.reservation;
      if (!reservation.priceId) {
        throw new Error("Must provide a priceId");
      }
      const price = yield this.getPrice(reservation.priceId);
      this.products = {
        reservation: {
          product: reservation.product,
          priceId: reservation.priceId,
          price: price,
        },
      };
    } catch (error) {
      console.error(error);
    }
  }

  @flow
  *loadMembershipPrices(): FlowType {
    // The data is already loaded
    if (!this.applicationConfig.data) return;

    const memberships = this.applicationConfig.data.memberships;
    const priceIds = new Array();
    Object.keys(memberships).map((key) => {
      const membership = memberships[key];
      if (membership.monthly) {
        priceIds.push(membership.monthly);
      }
      if (membership.annually) {
        priceIds.push(membership.annually);
      }
      if (membership.locations) {
        Object.keys(membership.locations).map((key) => {
          const location = membership.locations[key];
          if (location.monthly) priceIds.push(location.monthly);
          if (location.annually) priceIds.push(location.annually);
        });
      }
    });
    const results = yield CloudAPI.getPrices(this.application, {
      priceId: priceIds.join(","),
    });
    // Convert prices to an array
    const pricesArray = new Array();
    Object.keys(results.prices).map((key) => {
      pricesArray.push(results.prices[key]);
    });
    this.membershipPrices = pricesArray;
  }

  @flow
  *loadApplicationConfigOnce(): FlowType {
    // The data is already loaded
    if (this.applicationConfig.data) return;

    // Someone else is loading the data
    if (this.applicationConfig.isLoading) {
      yield this.applicationConfig.ctx?.promise;
      return;
    }

    try {
      this.applicationConfig.isLoading = true;
      const response = yield CloudAPI.store(this.application);
      this.applicationConfig.isLoading = false;

      if (response) {
        const store = response.store;

        if (isApplicationConfig(store)) {
          this.applicationConfig.data = store;
          this.getPrices();
          this.loadStaff();
          this.loadMembershipPrices();
          this.loadVideos();
        } else {
          throw new Error(
            "Failed to pass ApplicationConfigValidator alidation"
          );
        }
      } else {
        throw new Error("No data from server");
      }
    } catch (error) {
      console.error(error);
    }

    // Indicate the resource is indeed loaded
    this.applicationConfig.ctx?.resolve();
  }

  @flow
  *loadStaff(): FlowType {
    try {
      const response = yield CloudAPI.getStaff(this.application);
      if (!response.staff) {
        throw new Error("The staff are missing in action!");
      }
      this.staff = response.staff;
    } catch (error) {
      console.error(error);
    }
  }

  // Set; name / address / etc. based; on; server; config, not; Timetap;
  normalizeAppointment = (
    appointment: IAppointmentRecord
  ): IAppointmentRecord => {
    const location = this.getLocation(appointment.locationId);
    if (!location) return appointment;
    const bay = location.bays.find((b) => b.staffId === appointment.staffId);
    return {
      ...appointment,
      address: location.address,
      city: location.city,
      state: location.state,
      zip: location.zip,
      staff: bay?.name,
      imageUrl: bay?.imageUrl,
    };
  };

  normalizeAppointments(
    appointments: IAppointmentRecord[]
  ): IAppointmentRecord[] | [] {
    if (!appointments.length) return [];
    const newAppointments = appointments
      .filter((appointment: IAppointmentRecord) => !!appointment.locationId)
      .map(this.normalizeAppointment);
    return newAppointments;
  }

  @flow
  *loadAppointments(): FlowType {
    if (!this.application.session.timetapClientId) return;
    if (!this.application.domain.applicationConfig) return;
    try {
      const data = {
        clientId: String(this.application.session.timetapClientId),
      };
      this.loadingAppointments = true;
      const response = yield CloudAPI.getAppointments(this.application, data);
      this.loadingAppointments = false;
      if (!response) {
        throw new Error("No response from server");
      }
      // Set all appointment counts
      this.appointmentCounts = response.counts;
      this.appointments = this.normalizeAppointments(response.appointments);
      this.appointments = this.appointments.sort(
        (a: IAppointmentRecord, b: IAppointmentRecord) => {
          if (!a.timestamp) return 0;
          if (!b.timestamp) return 0;
          return a.timestamp - b.timestamp;
        }
      );
    } catch (error) {
      this.loadingAppointments = false;
      console.error(error.message);
    }
  }

  @flow
  *loadPaymentIntent(
    priceId: string,
    stripeCustomerId: string,
    description: string
  ): FlowType {
    this.paymentIntentLoading = true;
    this.resetAppointment();
    try {
      if (!priceId || !stripeCustomerId || !description) {
        throw new Error("Missing required parameters");
      }
      const data = {
        priceId: String(priceId),
        customerId: String(stripeCustomerId),
        description: description,
      };
      const paymentIntent: IAPIPaymentIntent = yield CloudAPI.getPaymentIntent(
        this.application,
        data
      );
      if (!paymentIntent || !paymentIntent.intent) {
        throw new Error("Error creating payment intent");
      }
      this.paymentIntent = paymentIntent.intent;
      this.paymentIntentLoading = false;
    } catch (error) {
      console.error(error.message);
      this.paymentIntentLoading = false;
    }
  }

  @flow
  *loadVideos(): FlowType {
    if (this.video) return;
    try {
      const results = yield CloudAPI.videos(this.application);
      this.video = results.video;
      this.application.ui.video.selectFirstFolder();
    } catch (error) {
      console.error(error.message);
    }
  }

  @computed
  get locations() {
    if (!this.applicationConfig.data) return [];
    return this.applicationConfig.data.locations;
  }

  @computed
  get stripeCustomerId(): string | undefined {
    if (!this.application.session.userDoc) return;
    return String(this.application.session.userDoc.stripeCustomerId);
  }

  @computed
  get timetapClientId(): number | undefined {
    if (!this.application.session.timetapClientId) return;
    return this.application.session.timetapClientId;
  }

  @computed
  get defaultPaymentId(): string {
    if (!this.application.session.stripeCustomer) return "";
    const default_method =
      this.application.session.stripeCustomer.invoice_settings
        .default_payment_method;
    if (!default_method) return "";
    return default_method;
  }

  @computed
  get paymentId(): string {
    if (!this.selectedPaymentId) return "";
    return this.selectedPaymentId;
  }
}
