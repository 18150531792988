import IconLogo from "app/client/svg/logo";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { ENV } from "../../../client/env";
import { Application } from "../../../client/store";

/**
 * Inform the user that the password reset email has been sent
 */
export const SignupSuccessPage = observer(() => {
  // Full page refresh
  const returnToLogin = () => {
    window.location.href = ENV.routes.login;
  };

  // Redirect the user away from this page if they didn't just sign up
  useEffect(() => {
    Application.session.signOut();
    if (!Application.ui.signup.signupEmail) {
      returnToLogin();
    }
  }, []);

  return (
    <div className="LoginPage FormPage full-screen">
      <div className="FormPage-left"></div>
      <div className="FormPage-right">
        <div className="FormPage-form">
          <div className="FormPage-form-title">
            <div className="FormPage-form-logo">
              <IconLogo />
            </div>
            <h1>Welcome {Application.ui.signup.signupName}!</h1>
            <div>
              A verification email has been sent to{" "}
              {Application.ui.signup.signupEmail}.
            </div>
          </div>
        </div>
        <div className="FormPage-links">
          <a onClick={returnToLogin}>Back to login</a>
        </div>
        <div className="FormPage-copyright">
          Copyright © City Golf Club 2023.
        </div>
      </div>
    </div>
  );
});
