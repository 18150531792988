import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { Application } from "../../store";
import { when } from "../../util/when";
import "./add-payment-method.scss";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import {
  loadStripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { ENV } from "../../env";
import { IPaymentMethod } from "../../store/interfaces";
import LoadingAnimation from "../../ui/loading";
import SubmitButton from "../../ui/submit-button";

const stripePromise = loadStripe(ENV.stripeKey);

interface IAddPaymentMethodProps {
  className?: string;
  onComplete?(method?: IPaymentMethod | any): void;
  action?: string;
}

// Props
interface IAddPaymentMethodFormProps {
  className?: string;
  onComplete?(method?: IPaymentMethod | any): void;
  action?: string;
}

// Using this as a guide
const AddPaymentMethodForm: FC<IAddPaymentMethodFormProps> = ({
  onComplete,
  className = "",
  action = "",
}) => {
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Do nothing
  }, []);

  const handleChange = async (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent
  ) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setError(e.error ? e.error.message : "");
  };

  const autoFocus = (e: StripeCardNumberElement) => {
    if (e) {
      e.focus();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe has not yet loaded
      return;
    }

    try {
      // const card = elements.getElement(CardElement);
      const card = elements.getElement(CardNumberElement);
      if (card) {
        setProcessing(true);
        const results = await stripe.createPaymentMethod({
          type: "card",
          card: card,
        });

        if (results.error) {
          throw new Error(results.error.message);
        }

        if (results.paymentMethod) {
          const paymentId = results.paymentMethod.id;
          const paymentMethod =
            await Application.ui.account.createPaymentMethod(paymentId);

          // Load all payment methods for the user
          Application.session.loadPaymentMethods();

          // Set default payment method if first
          if (Application.ui.account.paymentMethods.length <= 0) {
            await Application.ui.account.setDefaultPaymentId(paymentId);
            await Application.session.loadStripeCustomer();
            Application.domain.setSelectedPaymentId(paymentId);
          }

          // Stop processing
          setProcessing(true);

          // Execute onComplete callback
          onComplete?.(paymentMethod);
        }
      }
    } catch (error) {
      setProcessing(false);
      setError(error.message);
    }
  };

  return (
    <div className={`CreditCardForm ${className}`}>
      {when(processing === true, <LoadingAnimation absolute={true} />)}
      {when(error, <div className="CreditCardForm-error">{error}</div>)}
      <form id="add-payment-method" onSubmit={handleSubmit}>
        <fieldset className="FormGroup">
          {/* This was the old CardElement form, I believe was causing problems for some users */}
          {/* <CardElement
            options={Application.ui.payment.styles}
            onChange={handleChange}
          /> */}
          {/* Using each element individually should work better */}
          <div className="FormGroup--field">
            <label>Card Number</label>
            <CardNumberElement
              onReady={autoFocus}
              onChange={handleChange}
              options={Application.ui.payment.cardFieldOptions}
            />
          </div>
          <div className="FormGroup--row">
            <div className="FormGroup--field">
              <label>Expiration</label>
              <CardExpiryElement
                onChange={handleChange}
                options={Application.ui.payment.cardFieldOptions}
              />
            </div>
            <div className="FormGroup--field">
              <label>CVC</label>
              <CardCvcElement
                onChange={handleChange}
                options={Application.ui.payment.cardFieldOptions}
              />
            </div>
          </div>
        </fieldset>
        <SubmitButton label={action} />
      </form>
    </div>
  );
};

const AddPaymentMethod: FC<IAddPaymentMethodProps> = ({
  className = "",
  action = "Add card",
  onComplete,
}) => {
  const wrapperClasses = () => {
    const classes = new Array("AddPaymentMethod");
    if (className) classes.push(className);
    return classes.join(" ");
  };
  return (
    <div className={wrapperClasses()}>
      <Elements stripe={stripePromise}>
        <AddPaymentMethodForm action={action} onComplete={onComplete} />
      </Elements>
    </div>
  );
};

export default observer(AddPaymentMethod);
