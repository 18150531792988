import { observer } from "mobx-react";
import * as React from "react";
import { Locations } from "../account/locations";
import "./choose-location.scss";

// UI components
import BackButton from "app/client/ui/back-button";

/**
 * An example of a page being made
 */
export const ChooseLocationPage = observer(() => (
  <div className="ChooseLocationPage">
    <div className="ChooseLocationPage-top">
      <BackButton label="Back to Account" route="/reservations" />
    </div>
    <div className="ChooseLocationPage-locations">
      <Locations />
    </div>
  </div>
));
