import { observer } from "mobx-react";
import * as React from "react";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./app.scss";
import { buildFonts } from "./build-fonts";
import { ENV } from "./env";
import * as Page from "./page";
import { Application } from "./store";

/**
 * This is the top level component and entry point of this application. It is
 * responsible for setting up session and initial state as well as configuring
 * routes etc.
 */
export const App = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleHotkeys = (e: KeyboardEvent) => {
    if (e.shiftKey && e.key === "~") {
      Application.domain.toggleTestMode();
    }
  };

  // onMount
  useEffect(() => {
    // Font instantiating
    const destroyFonts = buildFonts();
    // Load in the application configuration
    // Get location, staff, and type data
    Application.domain.loadApplicationConfigOnce();
    // Application.ui.schedule.setDefaults();

    // Hotkeys
    window.addEventListener("keydown", handleHotkeys);

    // TODO: Find a way to do this at the DNS level
    // Redirect to https if we are not already
    if (window.location.protocol === "http:") {
      window.location.href = window.location.href.replace("http", "https");
    }

    return () => {
      // Ensure all fonts associated with this context are removed so we don't
      // clutter the DOM.
      destroyFonts();
      Application.session.authDisposer();
      window.removeEventListener("keydown", handleHotkeys);
    };
  }, []);

  // Keep track of location changes so we can update the session as needed.
  useEffect(() => {
    // Update the sessions search params with the latest params in the URL
    Application.session.updateSearchParams();
    // Perform Application state changes based on page
    Application.session.pageDidLoad(location, navigate);
  }, [location]);

  if (Application.session.loggingIn) {
    return (
      <>
        <Routes>
          <Route path={"*"} element={<Page.LoggingInPage />} />
        </Routes>
      </>
    );
  }

  // User is evil and forgot to login
  if (!Application.session.user) {
    return (
      <>
        <Routes>
          <Route path={ENV.routes.login} element={<Page.LoginPage />} />
          <Route path={ENV.routes.signup} element={<Page.SignupPage />} />
          <Route path={ENV.routes.signout} element={<Page.SignoutPage />} />
          <Route
            path={ENV.routes.signupSuccess}
            element={<Page.SignupSuccessPage />}
          />
          <Route
            path={ENV.routes.passwordResetSent}
            element={<Page.PasswordResetSentPage />}
          />
          <Route
            path={ENV.routes.forgotPassword}
            element={<Page.ForgotPasswordPage />}
          />
          <Route path="/*" element={<Page.LoginPage />} />
        </Routes>
      </>
    );
  }

  // If the user has not yet verified their email address
  if (Application.session.user.emailVerified === false) {
    // TODO: Give users the ability to send a verification email
    return (
      <>
        <Routes>
          <Route path={ENV.routes.login} element={<Page.LoginPage />} />
          <Route path={ENV.routes.signup} element={<Page.SignupPage />} />
          <Route path={ENV.routes.signout} element={<Page.SignoutPage />} />
          <Route
            path={ENV.routes.signupSuccess}
            element={<Page.SignupSuccessPage />}
          />
          <Route
            path={ENV.routes.passwordResetSent}
            element={<Page.PasswordResetSentPage />}
          />
          <Route
            path={ENV.routes.forgotPassword}
            element={<Page.ForgotPasswordPage />}
          />
          <Route path="/*" element={<Page.LoginPage />} />
        </Routes>
      </>
    );
  }

  // User is logged in
  return (
    <>
      <Routes>
        <Route
          path={ENV.routes.reservations}
          element={<Page.ReservationsPage />}
        />
        <Route path={ENV.routes.signout} element={<Page.SignoutPage />} />
        <Route path={ENV.routes.schedule} element={<Page.SchedulePage />} />
        <Route
          path={ENV.routes.scheduleConfirmation}
          element={<Page.ConfirmationPage />}
        />
        <Route path={ENV.routes.videos} element={<Page.VideoPage />} />
        <Route
          path={ENV.routes.videoViewer}
          element={<Page.VideoViewerPage />}
        />
        <Route
          path={ENV.routes.rescheduleConfirmation}
          element={<Page.RescheduleConfirmationPage />}
        />
        <Route path={ENV.routes.reschedule} element={<Page.ReschedulePage />} />
        <Route path={ENV.routes.account} element={<Page.AccountPage />} />
        <Route
          path={ENV.routes.manageMembership}
          element={<Page.ManageMembershipPage />}
        />
        <Route
          path={ENV.routes.changePassword}
          element={<Page.ChangePasswordPage />}
        />
        <Route
          path={ENV.routes.chooseLocation}
          element={<Page.ChooseLocationPage />}
        />
        <Route
          path={ENV.routes.forgotPassword}
          element={<Page.ForgotPasswordPage />}
        />
        <Route path="*" element={<Page.ReservationsPage />} />
      </Routes>
    </>
  );
});

// Initialize the session with everything we are able to determine from the
// current state of the browser.
Application.session.init();

const root = createRoot(document.getElementById("main")!);

// Start up the application with the appropriate HTML.
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
