import "button.scss";
import { observer } from "mobx-react";
import React, { FC, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconArrowLeft from "../svg/arrow-left";
import IconArrowRight from "../svg/arrow-right";

interface IButtonProps {
  onClick?(): void;
  children?: ReactNode;
  className?: string;
  label: string;
  route?: string;
  leftArrow?: boolean;
  rightArrow?: boolean;
  inline?: boolean;
  leftIcon?: JSX.Element;
  block?: boolean;

  rightIcon?: JSX.Element;
  style?: "default" | "blue" | "nav";
}

const Button: FC<IButtonProps> = observer(
  ({
    onClick,
    className = "",
    label = "Error",
    route = "",
    rightArrow = false,
    leftArrow = false,
    inline = false,
    leftIcon = <IconArrowLeft />,
    rightIcon = <IconArrowRight />,
    style = "default",
    block = false,
  }) => {
    const props: { href?: string } = {};
    if (route) {
      props.href = route;
    }

    const { pathname } = useLocation();
    const navigate = useNavigate();

    // Test if the current route matches "/account"
    const isAccountRoute = pathname === route ? true : false;

    const handleClick = () => {
      if (route) {
        navigate(route);
      }

      // Allow the user to do stuff too
      onClick?.();
    };

    const buttonClasses = () => {
      const classes = new Array();
      classes.push("Button");
      if (className) classes.push(className);
      if (inline) classes.push("inline");
      if (isAccountRoute) classes.push("active-route");
      if (leftArrow) classes.push("has-left-icon");
      if (rightArrow) classes.push("has-right-icon");
      if (block) classes.push("flex-grow");
      classes.push(`style-${style.toLowerCase()}`);
      return classes.join(" ");
    };

    return (
      <a className={buttonClasses()} onClick={handleClick}>
        {leftArrow && leftIcon}
        <span>{label}</span>
        {rightArrow && rightIcon}
      </a>
    );
  }
);

export default Button;
