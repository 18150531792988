import React, { FC } from "react";

interface IIconPersonProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconPerson: FC<IIconPersonProps> = ({
  className = "",
  width = 20,
  height = 20,
  opacity = 0.5,
  fill = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={`Icon__Person color-fill ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M6.91016 11.832C7.98568 11.3945 9.01562 11.1758 10 11.1758C10.9844 11.1758 12.0052 11.3945 13.0625 11.832C14.138 12.2513 14.6758 12.8073 14.6758 13.5V14.6758H5.32422V13.5C5.32422 12.8073 5.85286 12.2513 6.91016 11.832ZM11.6406 9.31641C11.1849 9.77214 10.638 10 10 10C9.36198 10 8.8151 9.77214 8.35938 9.31641C7.90365 8.86068 7.67578 8.3138 7.67578 7.67578C7.67578 7.03776 7.90365 6.49089 8.35938 6.03516C8.8151 5.5612 9.36198 5.32422 10 5.32422C10.638 5.32422 11.1849 5.5612 11.6406 6.03516C12.0964 6.49089 12.3242 7.03776 12.3242 7.67578C12.3242 8.3138 12.0964 8.86068 11.6406 9.31641Z" />
    </g>
  </svg>
);

export default IconPerson;
