import { observer } from "mobx-react";
import React, { FC } from "react";
import { Application } from "../../store";

interface IVideoVariablesProps {
  className?: string;
}

const VideoVariables: FC<IVideoVariablesProps> = observer(
  ({ className = "" }) => (
    <div className={`ShowValues ${className}`.trim()}>
      <div>
        <span>selectedFolder</span>
        <span>{Application.ui.video.selectedFolder}</span>
      </div>
    </div>
  )
);

export default VideoVariables;
