import IconMoveNext from "app/client/svg/move-next";
import IconMovePrev from "app/client/svg/move-prev";
import "day-nav.scss";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { Application } from "../../../client/store";
import { classnames } from "../../util/when";

interface IDayNavProps {
  onClick?(): void;
  className?: string;
  direction?: string;
  disabled?: boolean;
  loading?: boolean;
}

const DayNav: FC<IDayNavProps> = ({
  onClick,
  className = "",
  direction = "left",
  disabled = false,
  loading = false,
}) => {
  // Set styles for the noon line
  const styles = {
    gridTemplateRows:
      Application.ui.schedule.amCount > 0
        ? `${Application.ui.schedule.amCount}fr 20px ${Application.ui.schedule.pmCount}fr`
        : `1fr`,
  };

  const noonStyles = {
    display: Application.ui.schedule.amCount > 0 ? "flex" : "none",
  };

  return (
    <div
      className={classnames(
        "DayNav",
        className,
        disabled ? "disabled" : "",
        loading ? "DayNav--loading" : ""
      )}
    >
      <div className="DayNav__Top">
        <div className={`DayNav-arrow ${direction}`} onClick={onClick}>
          {direction === "left" ? (
            <IconMovePrev fill="#FFFFFF" opacity={1} />
          ) : (
            <IconMoveNext fill="#FFFFFF" opacity={1} />
          )}
        </div>
      </div>
      <div className="DayNav__Bottom" style={styles}>
        <div style={noonStyles} className="am">
          AM
        </div>
        <div style={noonStyles} className="noon">
          noon
        </div>
        <div className="pm">PM</div>
      </div>
    </div>
  );
};

export default observer(DayNav);
