import { observer } from "mobx-react";
import "no-reservations.scss";
import React, { CSSProperties, FC } from "react";
import IconArrowRight from "../../svg/arrow-right";

interface INoReservationsProps {
  onClick?(): void;
  className?: string;
  title: string;
  description: string;
  arrowSize?: number;
}

interface ICustomCSSProperties extends CSSProperties {
  "--size"?: string | number;
}

const NoReservations: FC<INoReservationsProps> = ({
  onClick,
  className = "",
  title = "",
  description = "",
  arrowSize = 40,
}) => {
  const arrowStyles: ICustomCSSProperties = {
    "--size": `${arrowSize}px`,
  };
  return (
    <div onClick={onClick} className={`NoReservations ${className}`}>
      <div className="NoReservations-title">{title}</div>
      <div className="NoReservations-description">{description}</div>
      <div className="NoReservations-icon" style={arrowStyles}>
        <IconArrowRight size={arrowSize} />
      </div>
    </div>
  );
};

export default observer(NoReservations);
