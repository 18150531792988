import React, { FC } from "react";

interface IIconCalendarProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconCalendar: FC<IIconCalendarProps> = ({
  className = "",
  width = 20,
  height = 20,
  opacity = 0.5,
  fill = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={`Icon__Calendar color-fill ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M14.0742 14.0742V7.67578H5.92578V14.0742H14.0742ZM12.3242 3.57422H13.5V4.75H14.0742C14.3841 4.75 14.6576 4.86849 14.8945 5.10547C15.1315 5.34245 15.25 5.61589 15.25 5.92578V14.0742C15.25 14.3841 15.1315 14.6576 14.8945 14.8945C14.6576 15.1315 14.3841 15.25 14.0742 15.25H5.92578C5.59766 15.25 5.3151 15.1406 5.07812 14.9219C4.85938 14.6849 4.75 14.4023 4.75 14.0742V5.92578C4.75 5.61589 4.85938 5.34245 5.07812 5.10547C5.3151 4.86849 5.59766 4.75 5.92578 4.75H6.5V3.57422H7.67578V4.75H12.3242V3.57422ZM12.9258 10V12.9258H10V10H12.9258Z" />
    </g>
  </svg>
);

export default IconCalendar;
