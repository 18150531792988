import "form-error.scss";
import React, { FC, ReactNode } from "react";

interface IFormErrorProps {
  onChange?(): void;
  children?: ReactNode;
  label: string;
  className?: string;
}

const FormError: FC<IFormErrorProps> = ({
  className = "",
  label = "Error",
}) => <div className={`FormError ${className}`}>{label}</div>;

export default FormError;
