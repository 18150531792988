import { observer } from "mobx-react";
import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { ENV } from "../../env";
import { Application } from "../../store";
import BackButton from "../../ui/back-button";
import { when } from "../../util/when";
import { VideoListItem } from "./video-list-item";

// Styles
import "./viewer.scss";

interface IVideoViewerProps {
  className?: string;
}

// ReactPlayer docs
// https://github.com/CookPete/react-player#props

export const VideoViewerPage = observer(
  ({ className = "" }: IVideoViewerProps) => {
    const wrapperClasses = (): string => {
      const classes = ["VideoViewer"];
      if (className) {
        classes.push(className);
      }
      return classes.join(" ").trim();
    };

    const handleReady = () => {
      Application.ui.video.setReady(true);
    };

    const videoConfig = {
      vimeo: {
        title: Application.ui.video.selectedVideo?.title,
      },
    };

    // Mount / Unmount
    useEffect(
      () => () => {
        Application.ui.video.resetSelectedVimeoId();
      },
      []
    );

    return (
      <div className={wrapperClasses()}>
        {/* Top bar */}
        <div className="VideoViewer__Navigation">
          <div className="ManageMembership__Title">
            <div className="ManageMembership__Title-left">
              <BackButton route={ENV.routes.videos} label="Back to Videos" />
            </div>
          </div>
        </div>
        {/* Video player */}
        <div className="VideoViewer__Content">
          <div className="VideoViewer--video">
            <div className="VideoViewer--video--box">
              <ReactPlayer
                url={Application.ui.video.selectedVideo?.link}
                config={videoConfig}
                controls={true}
                width={"100%"}
                height={"100%"}
                onReady={handleReady}
              />
            </div>
          </div>
          <div className="VideoViewer--title">
            <div className="VideoViewer--title--tutorial">
              Tutorial
              <span>{Application.ui.video.selectedVideo?.tutorial}</span>
            </div>
            {Application.ui.video.selectedVideo?.title}
          </div>
          {/* Previous and next tutorials */}
          {when(
            Application.ui.video.ready,
            <div className="VideoViewer__Content--prevnext">
              <VideoListItem
                header="Previous tutorial"
                video={Application.ui.video.prevTutorial}
              />
              <VideoListItem
                header="Next tutorial"
                video={Application.ui.video.nextTutorial}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
