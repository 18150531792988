import { Application } from "app/client/store";
import Navigation from "app/client/ui/navigation";
import TextInput from "app/client/ui/text-input";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../env";
import { IPaymentMethod } from "../../store/interfaces";
import IconDelete from "../../svg/delete";
import IconLogout from "../../svg/logout";
import Button from "../../ui/button";
import CloseButton from "../../ui/close-button";
import { Modal } from "../../ui/modal";
import { when } from "../../util/when";
import MembershipVariables from "../membership/variables";

// Styles
import "./account.scss";
import AddPaymentMethod from "./add-payment-method";

/**
 * An example of a page being made
 */
export const AccountPage = observer(() => {
  const navigate = useNavigate();
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [deletePaymentMethod, setDeletePaymentMethod] =
    useState<IPaymentMethod | null>(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<IPaymentMethod | null>(null);

  // Save the user's name after they are done typing
  const saveName = (newName: string) => {
    Application.session.updateDisplayName(newName.trim());
  };

  const updatePaymentMethod = async () => {
    if (!defaultPaymentMethod) return;
    try {
      await Application.ui.account.setDefaultPaymentId(defaultPaymentMethod.id);
      // Reload the customer because the default payment is stored
      // on the customer object
      await Application.session.loadStripeCustomer();
    } catch (error) {
      console.error(error);
    }
    closeModals();
  };

  const detachPaymentMethod = async () => {
    try {
      if (!deletePaymentMethod) {
        throw new Error("Must provide a paymentId");
      }
      await Application.ui.account.deletePaymentMethod(deletePaymentMethod.id);
      await Application.session.loadPaymentMethods();
    } catch (error) {
      console.error(error);
    }
    closeModals();
  };

  const addPaymentMethod = () => {
    setPaymentMethodModalOpen(true);
  };

  const closeModals = () => {
    setPaymentMethodModalOpen(false);
    setDeletePaymentMethod(null);
    setDefaultPaymentMethod(null);
  };

  return (
    <div className={`AccountPage`}>
      <Navigation />
      <div className="AccountPage__Content">
        {when(Application.domain.testMode, <MembershipVariables />)}
        <div className="AccountPage__Content-split Split">
          <div className="Split-left">
            <div className="AccountPage__Edit">
              <div className="AccountPage__Edit-field large">
                <TextInput
                  placeholder="Full name"
                  className="large"
                  name="displayName"
                  value={Application.session.userDoc?.name}
                  stopTyping={(e) => saveName(e.target.value)}
                />
              </div>
            </div>
            <div className="AccountPage__Edit">
              <div className="Data">
                <div className="Data-title">Email Address</div>
                <div className="Data-value">
                  {Application.session.user?.email}
                </div>
              </div>
            </div>
            <div className="AccountPage__Edit">
              <div className="AccountPage__Edit-field">*********</div>
              <div className="AccountPage__Edit-link">
                <a onClick={() => navigate(ENV.routes.changePassword)}>
                  Change password
                </a>
              </div>
            </div>
          </div>
          <div className="Split-right">
            <Button
              label="Sign Out"
              rightArrow={true}
              style="blue"
              inline={true}
              route={ENV.routes.signout}
              rightIcon={<IconLogout opacity={1} />}
            />
          </div>
        </div>
        <div className="AccountPage__Membership">
          <div className="Membership">
            {/* Current membership */}
            <div className="Membership__Data">
              <div className="Membership__Data-title">Current Membership</div>
              <div className="Membership__Data-membership">
                {Application.ui.account.membershipLabel}
              </div>
              {when(
                Application.ui.account.subscriptionPrice,
                <div className="Membership__Data-text price">
                  <span>
                    {Application.ui.account.formattedSubscriptionPrice}
                  </span>
                  <span>{Application.ui.account.subscriptionInterval}</span>
                </div>
              )}
              <div className="Membership__Data-link">
                <a onClick={() => navigate(ENV.routes.manageMembership)}>
                  {Application.ui.account.currentMembershipLabel}
                </a>
              </div>
            </div>
            {/* Membership locations */}
            <div className="Membership__Data">
              <div className="Membership__Data-title">
                {Application.ui.account.membershipLocationLabel}
              </div>
              <div className="Membership__Data-text price">
                {Application.ui.account.membershipLocationDescription}
              </div>
              <div className="Membership__Data-bullets">
                <ul>
                  {Application.ui.account.membershipLocations.map(
                    (location, index) => (
                      <li key={index}>{location.name}</li>
                    )
                  )}
                </ul>
              </div>
              <div className="Membership__Data-link">
                <a onClick={() => navigate(ENV.routes.manageMembership)}>
                  {Application.ui.account.changeSubscriptionLabel}
                </a>
              </div>
            </div>
            {/* YSour benefits */}
            <div className="Membership__Data">
              <div className="Membership__Data-title">Your benefits</div>
              <div className="Membership__Data-bullets">
                <ul>
                  {Application.ui.account.membershipFeatures.map(
                    (feature, index) => (
                      <li key={index}>{feature}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
            {/* Payment method */}
            <div className="Membership__Data">
              {/* Modal to add payment method */}
              {when(
                paymentMethodModalOpen,
                <Modal onClose={closeModals}>
                  <div className="ModalContainer">
                    <CloseButton onClick={closeModals} />
                    <div className="ModalContainer-title">
                      Enter payment info
                    </div>
                    <AddPaymentMethod onComplete={closeModals} />
                  </div>
                </Modal>
              )}
              {/* Modal to delete payment method */}
              {when(
                deletePaymentMethod !== null,
                <Modal onClose={closeModals}>
                  <div className="ModalContainer">
                    <CloseButton onClick={closeModals} />
                    <div className="ModalContainer-title">
                      Delete this card?
                    </div>
                    <div className="ModalContainer-text">
                      <div>{`${deletePaymentMethod?.card.brand} ****${deletePaymentMethod?.card.last4}`}</div>
                      <div>{`expires ${deletePaymentMethod?.card.exp_month}/${deletePaymentMethod?.card.exp_year}`}</div>
                    </div>
                    <div className="ModalContainer-buttons">
                      <Button label="Keep it" onClick={closeModals} />
                      <Button
                        label="Yes, delete now"
                        onClick={detachPaymentMethod}
                        className="pink"
                      />
                    </div>
                  </div>
                </Modal>
              )}
              {/* Modal to set default payment method */}
              {when(
                defaultPaymentMethod !== null,
                <Modal onClose={closeModals}>
                  <div className="ModalContainer">
                    <CloseButton onClick={closeModals} />
                    <div className="ModalContainer-title">Set as default?</div>
                    <div className="ModalContainer-text">
                      <div>{`${defaultPaymentMethod?.card.brand} ****${defaultPaymentMethod?.card.last4}`}</div>
                      <div>{`expires ${defaultPaymentMethod?.card.exp_month}/${defaultPaymentMethod?.card.exp_year}`}</div>
                    </div>
                    <div className="ModalContainer-buttons">
                      <Button label="No" onClick={closeModals} />
                      <Button label="Yes" onClick={updatePaymentMethod} />
                    </div>
                  </div>
                </Modal>
              )}
              <div className="Membership__Data-title">Payment methods</div>
              <div className="PaymentMethods">
                {Application.ui.account.paymentMethods.map((method, index) => {
                  const isDefault =
                    method.id ===
                    Application.ui.account.stripeCustomer?.invoice_settings
                      ?.default_payment_method
                      ? true
                      : false;
                  const methodClass = isDefault ? "Default" : "";
                  return (
                    <div
                      className={`PaymentMethod ${methodClass}`.trim()}
                      key={index}
                    >
                      <div className="PaymentMethod-card">
                        <span>
                          {`${method.card.brand} ****${method.card.last4} ${method.card.exp_month}/${method.card.exp_year}`}
                          <span>default</span>
                        </span>
                      </div>
                      <div className="PaymentMethod-default">
                        {when(
                          isDefault === false,
                          <a onClick={() => setDefaultPaymentMethod(method)}>
                            Make default
                          </a>
                        )}
                      </div>
                      <div className="PaymentMethod-delete">
                        {when(
                          isDefault === false,
                          <a onClick={() => setDeletePaymentMethod(method)}>
                            <IconDelete />
                          </a>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="Membership__Data-link">
                <a onClick={addPaymentMethod}>Add payment method</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
