import IconCancelReservation from "app/client/svg/cancel-reservation";
import IconRescheduleReservation from "app/client/svg/reschedule-reservation";
import Button from "app/client/ui/button";
import CloseButton from "app/client/ui/close-button";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../env";
import { Application } from "../../store";
import { IAppointmentRecord } from "../../store/interfaces";
import LoadingAnimation from "../../ui/loading";
import { when } from "../../util/when";
import "./change-appointment.scss";

interface IChangeAppointmentModalProps {
  onClose?(): void;
  onCancel?(): void;
  calendarId: number;
  appointment: IAppointmentRecord | null;
}

/**
 * An example of a page being made
 */
export const ChangeAppointmentModal: React.FC<IChangeAppointmentModalProps> =
  observer(({ onClose, onCancel, calendarId = 0, appointment }) => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    const cancelAppointment = () => {
      if (!appointment) return;
      Application.ui.appointments.cancelAppointment(appointment.calendarId);
      onCancel?.();
      setStep(3);
    };

    const rescheduleAppointment = () => {
      if (!appointment) return;
      Application.ui.reschedule.setRescheduleView(appointment.calendarId);
      navigate(ENV.routes.reschedule);
    };

    const handleClose = () => {
      onClose?.();
    };

    function handleStep(i: number) {
      setStep(i);
    }

    function confirmCancellation() {
      handleStep(2);
    }

    return (
      <div className="ChangeAppointmentModal">
        <CloseButton onClick={onClose} />
        <div className="ChangeAppointmentModal__Steps">
          {when(
            Application.ui.appointments.loading,
            <LoadingAnimation absolute={true} />
          )}
          {/* Step 1 */}
          {step === 1 && (
            <div className="ChangeAppointmentModal__Steps-step">
              <div className="ChangeAppointmentModal-title">
                Change reservation
              </div>
              <div className="ChangeAppointmentModal-description">
                You may cancel your reservation and receive a full refund up to
                24hrs before your reservation.
              </div>
              <div className="ChangeAppointmentModal-buttons">
                {when(
                  Application.ui.appointments.allowedToCancel(calendarId),
                  <div className="BigButton pink" onClick={confirmCancellation}>
                    <IconCancelReservation />
                    <div>Cancel</div>
                  </div>
                )}
                <div className="BigButton" onClick={rescheduleAppointment}>
                  <IconRescheduleReservation />
                  <div>Reschedule</div>
                </div>
              </div>
            </div>
          )}
          {/* Step 2 */}
          {step === 2 && (
            <div className="ChangeAppointmentModal__Steps-step">
              <div className="ChangeAppointmentModal-title">
                Are you sure you want to cancel your reservation?
              </div>
              <div className="ChangeAppointmentModal-buttons">
                <Button label="Keep it" onClick={onClose} block={true} />
                <Button
                  label="Yes, cancel now"
                  onClick={cancelAppointment}
                  className="pink"
                  block={true}
                />
              </div>
            </div>
          )}
          {/* Step 3 */}
          {step === 3 && (
            <div className="ChangeAppointmentModal__Steps-step">
              <div className="ChangeAppointmentModal-title">
                Reservation cancelled
              </div>
              <div className="ChangeAppointmentModal-description">
                Your reservation has been successfully cancelled and any
                payments associated with this reservation have been refunded.
              </div>
              <Button
                label={"Done!"}
                rightArrow={true}
                onClick={handleClose}
                block={true}
                className="gold"
              />
            </div>
          )}
        </div>
      </div>
    );
  });
