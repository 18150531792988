import { observer } from "mobx-react";
import React from "react";
import "./locations.scss";

import { Application } from "app/client/store";
import Location from "./location";

/**
 * An example of a page being made
 */
export const Locations = observer(() => (
  <div className="Locations">
    {Application.domain.applicationConfig.data?.locations.map((item, index) => (
      <Location
        key={index}
        locationId={item.locationId}
        bays={item.bays.length}
        address={item.address}
        name={item.name}
        image={item.image}
      />
    ))}
  </div>
));
