import { observer } from "mobx-react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../../client/env";
import { Application } from "../../../client/store";
import "./forgot-password.scss";
import "./form.scss";

// UI components
import IconLogo from "app/client/svg/logo";
import FormError from "app/client/ui/form-error";
import SubmitButton from "app/client/ui/submit-button";
import TextInput from "app/client/ui/text-input";

/**
 * Forgot password form
 */
export const ForgotPasswordPage = observer(() => {
  const navigate = useNavigate();
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formRef.current) return;
    const formData = new FormData(formRef.current);
    const data = {
      email: String(formData.get("email")),
    };
    Application.ui.signup.sendResetPasswordEmail(data);
    return false;
  };

  const inputChanged = () => {
    Application.ui.signup.resetFormError();
  };

  return (
    <div className="ForgotPasswordPage FormPage full-screen">
      <div className="FormPage-left"></div>
      <div className="FormPage-right">
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="FormPage-form">
            <div className="FormPage-form-title">
              <div className="FormPage-form-logo">
                <IconLogo />
              </div>
              <h1>Forgot Password</h1>
            </div>
            <div className="FormPage-form-fields">
              {Application.ui.signup.formError ? (
                <FormError label={Application.ui.signup.formError} />
              ) : (
                ""
              )}
              <TextInput
                onChange={inputChanged}
                name="email"
                placeholder={"Email Address"}
                autofocus
              ></TextInput>
              <SubmitButton label="Submit" />
            </div>
          </div>
        </form>
        <div className="FormPage-links">
          <a onClick={() => navigate(ENV.routes.login)}>Login</a>
          <a onClick={() => navigate(ENV.routes.signup)}>Sign up</a>
        </div>
        <div className="FormPage-copyright">
          Copyright © City Golf Club 2023.
        </div>
      </div>
    </div>
  );
});
