import { action, computed, flow, makeObservable, observable } from "mobx";
import moment from "moment";
import { CloudAPI } from "../../api/cloud.api";
import { FlowType } from "../../types";
import { ApplicationStore } from "../application.store";
import {
  IAPIPaymentMethod,
  IMembership,
  IPaymentMethods,
  IStripePrice,
} from "../interfaces";
import { IStripeCustomer } from "../session/session.store";
import { Store } from "../store";

export class AccountUI extends Store {
  @observable plan: IMembership | null = null;
  @observable selectedMembership: string | null = null;
  @observable selectedLocationId: number = -1;
  @observable selectedAllLocations: boolean = false;
  @observable selectedPaymentId: string | null = null;
  @observable processing: boolean = false;
  @observable selectedPeriods: { [key: string]: string } = {};

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  setSelectedPeriod(plan: string, period: string) {
    this.selectedPeriods[plan.toUpperCase().trim()] = period;
  }

  @action
  reset() {
    this.setPlan(null);
    this.setSelectedMembership("");
    this.selectedLocationId = -1;
    this.selectedPaymentId = null;
  }

  isMemberOfLocation(locationId: number) {
    if (!this.userLocations || !this.locations) return false;
    return this.userLocations.some(
      (value) => Number(value) === Number(locationId)
    );
  }

  formatPrice(amount: number) {
    return (amount / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  @action
  setSelectedPaymentId(methodId: string) {
    this.selectedPaymentId = methodId;
  }

  @action
  setSelectedLocationId(locationId: number) {
    this.selectedLocationId = locationId;
  }

  @action
  setPlan(plan: IMembership | null) {
    this.plan = plan;
    if (this.plan) {
      this.selectedAllLocations = this.plan.all ? true : false;
    }
  }

  @action
  setSelectedMembership(membership: string) {
    this.selectedMembership = membership.toLowerCase();
    this.selectedAllLocations =
      this.selectedMembership === "all" ? true : false;
  }

  @action
  setSelectedAllLocations(selectedAllLocations: boolean) {
    this.selectedAllLocations = selectedAllLocations;
  }

  @flow
  *createPaymentMethod(paymentId: string): FlowType {
    if (!this.application.session.stripeCustomerId) {
      throw new Error("stripeCustomerId is required");
    }
    if (!paymentId) {
      throw new Error("paymentId is required");
    }
    const data = {
      customerId: this.application.session.stripeCustomerId,
      paymentId: paymentId,
    };
    this.processing = true;
    const results: IAPIPaymentMethod = yield CloudAPI.createPaymentMethod(
      this.application,
      data
    );
    this.processing = false;
    if (results.success) {
      return results.method;
    }
  }

  @flow
  *deletePaymentMethod(paymentId: string): FlowType {
    if (!paymentId) {
      throw new Error("paymentId is required");
    }
    const data = {
      paymentId: paymentId,
    };
    this.processing = true;
    const result: IAPIPaymentMethod = yield CloudAPI.deletePaymentMethod(
      this.application,
      data
    );
    this.processing = false;
    if (result.success) {
      return result.method;
    }
  }

  @flow
  *setDefaultPaymentId(paymentId: string): FlowType {
    if (!this.application.session.stripeCustomerId) return;
    if (!paymentId) {
      throw new Error("paymentId is required");
    }
    const data = {
      paymentId: paymentId,
      customerId: this.application.session.stripeCustomerId,
    };
    this.processing = true;
    const results = yield CloudAPI.updateStripeCustomer(this.application, data);
    this.processing = false;
    return results;
  }

  @flow
  *createSubscription(): FlowType {
    if (!this.stripeCustomerId) return;
    if (!this.price) return;
    if (!this.paymentDescription) return;
    if (!this.selectedMembership) return;
    const data = {
      customerId: this.stripeCustomerId,
      paymentId: this.selectedPaymentId
        ? this.selectedPaymentId
        : this.defaultPaymentId,
      priceId: this.price.id,
      description: this.paymentDescription,
    };
    this.processing = true;
    const results = yield CloudAPI.createSubscription(this.application, data);
    this.processing = false;
    if (!results.success) {
      throw new Error(results.error);
    }
    // Add membership data to Firebase
    const userDocFields = {
      membership: this.selectedMembership.toUpperCase(),
      stripeCustomerId: this.stripeCustomerId,
      stripeSubscriptionId: results.subscription.id,
      stripeSubscriptionStatus: "active",
      membershipLocations: this.selectedLocationIds,
    };
    this.application.session.updateFirebaseUser(userDocFields);
    this.reset();
    return results;
  }

  @flow
  *updateSubscription(): FlowType {
    if (!this.subscriptionId) return;
    if (!this.price) return;
    if (!this.subscriptionItemId) return;
    if (!this.paymentDescription) return;
    if (!this.selectedMembership) return;
    const data = {
      subscriptionId: this.subscriptionId,
      itemId: this.subscriptionItemId,
      paymentId: this.selectedPaymentId
        ? this.selectedPaymentId
        : this.defaultPaymentId,
      priceId: this.price.id,
      description: this.paymentDescription,
    };
    this.processing = true;
    const results = yield CloudAPI.updateSubscription(this.application, data);
    this.processing = false;
    // Add membership data to Firebase
    const userDocFields = {
      membership: this.selectedMembership.toUpperCase(),
      membershipLocations: this.selectedLocationIds,
    };
    this.application.session.updateFirebaseUser(userDocFields);
    this.reset();
    return results;
  }

  @computed
  get selectedLocationIds(): number[] {
    if (!this.locations) return [];
    if (this.selectedAllLocations) {
      return this.locations.map((location) => location.locationId);
    }
    if (this.selectedLocationId > 0) {
      return [this.selectedLocationId];
    }
    if (this.userLocations) {
      return this.userLocations;
    }
    return [];
  }

  @computed
  get paymentDescription(): string {
    if (!this.selectedMembership) {
      if (!this.application.session.membershipName) return "";
      return `${this.application.session.membershipName} at ${this.membershipLocationNames}`;
    }
    const selectedMembership = this.selectedMembership.toUpperCase();
    if (this.isMember && this.selectedMembership) {
      if (this.membershipLocations.length > 1) {
        return `${selectedMembership} at all locations`;
      }
      return `${selectedMembership} at ${this.membershipLocationNames}`;
    }
    if (this.selectedAllLocations) return "All locations";
    if (!this.readyToCheckout) return "";
    if (this.selectedMembership && this.selectedLocation) {
      return `${selectedMembership} at ${this.selectedLocation}`;
    }
    return "";
  }

  @computed
  get selectedLocation(): string | null {
    if (this.selectedAllLocations) return "All Locations";
    if (this.selectedLocationId <= 0) return null;
    if (!this.locations) return null;
    const location = this.locations.find(
      (location) => location.locationId === this.selectedLocationId
    );
    if (!location) return null;
    return location.name;
  }

  @computed
  get readyToCheckout(): boolean {
    if (this.price?.id) return true;
    return false;
  }

  @computed
  get selectedPlan(): boolean {
    return this.plan !== null && this.price !== null ? true : false;
  }

  @computed
  get formattedAmount(): string {
    if (!this.price) return "";
    return this.formatPrice(this.price.unit_amount);
  }

  @computed
  get amount(): number {
    if (!this.price) return 0;
    return this.price.unit_amount;
  }

  @computed
  get memberships(): IMembership[] {
    const appConfig = this.application.domain.applicationConfig.data;
    const prices = this.application.domain.membershipPrices;
    const locationId = this.selectedLocationId;
    if (!appConfig || !prices) return [];

    const memberships = new Array();
    Object.keys(appConfig.memberships).forEach((index) => {
      const originalItem = appConfig.memberships[index];
      const item = { ...originalItem };

      // Get baseline price Ids
      let monthlyPriceId = item.monthly;
      let annualPriceId = item.annually;

      // Get location specific price Ids
      if (originalItem.locations?.[locationId]) {
        monthlyPriceId = originalItem.locations[locationId].monthly;
        annualPriceId = originalItem.locations[locationId].annually;
      }

      // Get price information for each price Id
      const monthlyPrice = prices.find((price) => price.id === monthlyPriceId);
      const annualPrice = prices.find((price) => price.id === annualPriceId);

      item.key = index.toLowerCase();
      item.monthlyPrice = monthlyPrice ? monthlyPrice : null;
      item.annualPrice = annualPrice ? annualPrice : null;
      memberships.push(item);
    });
    return memberships;
  }

  @computed
  get price(): IStripePrice | null {
    const appConfig = this.application.domain.applicationConfig.data;
    const prices = this.application.domain.membershipPrices;
    if (!this.selectedLocationId) return null;
    if (!this.selectedMembership) return null;
    if (!appConfig || !prices) return null;

    // Get selected membership
    const membership = appConfig.memberships[this.selectedMembership];
    const locationId = this.selectedLocationId;
    const period =
      this.selectedPeriods[this.selectedMembership.toUpperCase().trim()];
    if (!membership) return null;

    // Get baseline price Ids
    let monthlyPriceId = membership.monthly;
    let annualPriceId = membership.annually;

    // Get location specific price Ids
    if (membership.locations?.[locationId]) {
      monthlyPriceId = membership.locations[locationId].monthly;
      annualPriceId = membership.locations[locationId].annually;
    }

    // Get price information for each price Id
    const monthlyPrice = prices.find((price) => price.id === monthlyPriceId);
    const annualPrice = prices.find((price) => price.id === annualPriceId);

    // Return the price information
    if (period === "annually" && annualPrice) return annualPrice;
    if (period === "monthly" && monthlyPrice) return monthlyPrice;
    return null;
  }

  @computed
  get everyoneFeatures(): string[] {
    if (!this.application.session.membershipSettings) return [];
    const features = this.application.session.membershipSettings.everyone;
    if (!features) return [];
    // Get hh:mm A from features.startTime
    const startTime = moment(features.startHour, "HH:mm").format("h:mm A");
    const endTime = moment(features.endHour, "HH:mm").format("h:mm A");
    return [
      `Max of ${features.maxAppointments} appointment(s) at a time`,
      `${features.maxGuests} Guests`,
      `${features.windowLength} day booking window`,
      `Book any time from ${startTime} - ${endTime}`,
    ];
  }

  @computed
  get membershipFeatures(): string[] {
    if (!this.membership) return this.everyoneFeatures;
    if (!this.memberships) return this.everyoneFeatures;
    const membership = this.memberships.find(
      (membership) => membership.key === this.membership
    );
    if (!membership) return this.everyoneFeatures;
    return membership.features;
  }

  @computed
  get memberOfAllLocations(): boolean {
    if (!this.locations) return false;
    if (!this.userLocations) return false;
    return this.userLocations.length === this.locations.length;
  }

  @computed
  get memberOfAnyLocation(): boolean {
    if (!this.locations) return false;
    if (!this.userLocations) return false;
    return this.userLocations.length > 0;
  }

  @computed
  get prices(): IStripePrice[] | [] {
    if (!this.application.domain.membershipPrices) return [];
    return this.application.domain.membershipPrices;
  }

  @computed
  get membership(): string | null {
    if (!this.application.session.userDoc) return null;
    if (!this.application.session.userDoc.membership) return null;
    return this.application.session.userDoc.membership.toLowerCase();
  }

  @computed
  get membershipLabel(): string | undefined {
    if (!this.membership) return "Non-member";
    if (!this.application.domain.applicationConfig.data) return "Non-member";
    if (
      !this.application.domain.applicationConfig.data.memberships[
        this.membership
      ]
    ) {
      return "Non-member";
    }
    return this.application.domain.applicationConfig.data.memberships[
      this.membership
    ].subtitle;
  }

  @computed
  get stripeCustomer(): IStripeCustomer | undefined {
    if (!this.application.session.stripeCustomer) return;
    return this.application.session.stripeCustomer;
  }

  @computed
  get locations() {
    if (!this.application.domain.applicationConfig.data) return null;
    return this.application.domain.applicationConfig.data.locations;
  }

  @computed
  get level(): number {
    if (!this.application.domain.applicationConfig.data) return 0;
    if (!this.membership) return 0;
    if (!this.application.session.userDoc) return 0;
    const memberships =
      this.application.domain.applicationConfig.data.memberships;
    const membership =
      this.application.session.userDoc.membership.toLowerCase();
    if (!memberships[membership]) return 0;
    const m = memberships[membership];
    return m.level;
  }

  @computed
  get userLocations(): number[] | null {
    if (!this.application.session.userDoc) return [];
    const locationList = this.application.session.userDoc.membershipLocations;
    if (!locationList) return [];
    return locationList.map(Number);
  }

  @computed
  get membershipLocationLabel() {
    let label = "Membership location";
    if (!this.membershipLocations) return label;
    if (this.membershipLocations.length > 1) label = "Membership locations";
    return label;
  }

  @computed
  get currentMembershipLabel() {
    if (!this.membership) return "Become a member";
    if (!this.memberOfAllLocations) return "Upgrade membership";
    return "Change subscription";
  }

  @computed
  get membershipLocationDescription() {
    let label =
      "You are a member of the following location. You may change your membership location at any time or upgrade to our All location membership.";
    if (!this.membershipLocations) return label;
    if (this.membershipLocations.length > 1) {
      label =
        "You are a member of more than one of our high-end indoor golf locations. You are upgraded to the max.";
    }
    return label;
  }

  @computed
  get changeSubscriptionLabel() {
    if (!this.membership) return "Become a member";
    if (!this.memberOfAllLocations) return "Upgrade to add more locations";
    return "Change subscription";
  }

  @computed
  get membershipLocationNames(): string {
    if (this.membershipLocations.length <= 0) return "";
    if (!this.userLocations) return "";
    if (this.membershipLocations.length === this.userLocations.length) {
      return "every location";
    }
    const locationNames = this.membershipLocations.map(
      (location) => location.name
    );
    return locationNames.join(", ");
  }

  @computed
  get membershipLocations() {
    if (!this.application.domain.applicationConfig) return [];
    if (!this.userLocations) return [];
    if (!this.locations) return [];
    const locationList = new Array();
    for (let i = 0; i < this.userLocations.length; i++) {
      const locationId = this.userLocations[i];
      const location = this.locations.find((l) => l.locationId === locationId);
      if (location) locationList.push(location);
    }
    return locationList;
  }

  @computed
  get isMember(): boolean {
    if (!this.membership || this.membership === "") return false;
    return true;
  }

  @computed
  get stripeCustomerId(): string | null {
    if (!this.application.session.userDoc) return null;
    return this.application.session.userDoc.stripeCustomerId;
  }

  @computed
  get paymentMethods(): IPaymentMethods {
    if (!this.application.session.paymentMethods) return [];
    return this.application.session.paymentMethods;
  }

  @computed
  get defaultPaymentId(): string {
    if (!this.stripeCustomer) return "";
    const default_method =
      this.stripeCustomer.invoice_settings.default_payment_method;
    if (!default_method) return "";
    return default_method;
  }

  @computed
  get billingCycle(): string | null {
    if (!this.price) return null;
    if (!this.plan) return null;
    if (this.price.id === this.plan.annually) return "annually";
    return "monthly";
  }

  @computed
  get clientSecret(): string {
    if (!this.application.domain.paymentIntent) return "";
    return this.application.domain.paymentIntent.clientSecret;
  }

  @computed
  get stripeSubscriptionId(): string | null {
    if (!this.application.session.userDoc) return null;
    return this.application.session.userDoc.stripeSubscriptionId;
  }

  @computed
  get subscriptions() {
    if (!this.application.session.stripeCustomer) return [];
    return this.application.session.stripeCustomer.subscriptions;
  }

  @computed
  get subscriptionPrice(): number {
    if (!this.application.session.subscription) return 0;
    if (!this.application.session.subscription.items) return 0;
    if (!this.application.session.subscription.items.data) return 0;
    return this.application.session.subscription.items.data[0].price
      .unit_amount;
  }

  @computed
  get formattedSubscriptionPrice(): string {
    if (!this.subscriptionPrice) return "";
    return this.formatPrice(this.subscriptionPrice);
  }

  @computed
  get subscriptionInterval(): string | undefined {
    if (!this.application.session.subscription) return;
    if (!this.application.session.subscription.items) return;
    if (!this.application.session.subscription.items.data) return;
    const interval =
      this.application.session.subscription.items.data[0].plan.interval;
    return interval;
  }

  @computed
  get subscriptionItemId(): string | undefined {
    if (!this.application.session.subscription) return;
    if (!this.application.session.subscription.items) return;
    if (!this.application.session.subscription.items.data) return;
    const id = this.application.session.subscription.items.data[0].id;
    return id;
  }

  @computed
  get subscriptionId(): string | undefined {
    if (!this.application.session.subscription) return;
    return this.application.session.subscription.id;
  }
}
