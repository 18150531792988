import { observer } from "mobx-react";
import * as React from "react";
import { Appointments } from "./appointments";
import { Locations } from "./locations";
import "./reservations.scss";

// UI components
import Button from "app/client/ui/button";
import Navigation from "app/client/ui/navigation";
import { ENV } from "../../env";
import { Application } from "../../store";
import { when } from "../../util/when";
import ReservationVariables from "./variables";

/**
 * An example of a page being made
 */
export const ReservationsPage = observer(() => (
  <div className="ReservationsPage">
    <Navigation />
    <div className="Panes">
      <div className="Panes-left">
        <div className="Panes-content">
          <div className="Panes-title mobile-disabled">
            Upcoming reservations
          </div>
          <div className="Panes-title mobile-enabled">
            <Button
              label={`Book reservation`}
              className={"gold"}
              route={ENV.routes.chooseLocation}
            />
          </div>
          {when(Application.domain.testMode, <ReservationVariables />)}
          <Appointments />
        </div>
      </div>
      <div className="border-line"></div>
      <div className="Panes-right">
        <div className="Panes-content">
          <div className="Panes-title mobile-disabled">Book reservation</div>
          <Locations />
        </div>
      </div>
    </div>
  </div>
));
