import { observer } from "mobx-react";
import React, { FC } from "react";
import { Application } from "../../store";

interface IRescheduleVariablesProps {
  className?: string;
}

const RescheduleVariables: FC<IRescheduleVariablesProps> = ({
  className = "",
}) => (
  <div className={`ShowValues ${className}`.trim()}>
    <div>
      <span>readyToPay</span>
      <span>{Application.ui.reschedule.readyToPay ? "true" : "false"}</span>
    </div>
    <div>
      <span>appointment.stripeId</span>
      <span>{Application.ui.reschedule.appointment?.stripeId}</span>
    </div>
    <div>
      <span>calendarId</span>
      <span>{Application.ui.reschedule.appointment?.calendarId}</span>
    </div>
    <div>
      <span>domain.timeslots.length</span>
      <span>{Application.domain.timeslots.length}</span>
    </div>
  </div>
);

export default observer(RescheduleVariables);
