import React, { FC } from "react";

interface IIconMovePrevProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const IconMovePrev: FC<IIconMovePrevProps> = ({
  className = "",
  width = 20,
  height = 20,
  opacity = 0.5,
  fill = "#4874E4",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={`Icon__MovePrev color-fill ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M6.89453 10L17.5 2.5V17.5L6.89453 10ZM2.5 2.5H5.01953V17.5H2.5V2.5Z" />
    </g>
  </svg>
);

export default IconMovePrev;
