import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { Application } from "../../store";
import { when } from "../../util/when";
import "./finalize.scss";

import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import LoadingAnimation from "../../ui/loading";

import { ENV } from "../../env";

import Button from "../../ui/button";
import { PaymentMethodPicker } from "../../ui/payment/payment-methods";
import AddPaymentMethod from "../account/add-payment-method";

const stripePromise = loadStripe(ENV.stripeKey);

interface IFinalizeReservationProps {
  onComplete?(): void;
  className?: string;
}

// Props
interface IFinalizeReservationFormProps {
  onComplete?(): void;
  className?: string;
}

// Using this as a guide
const FinalizeMembershipForm: FC<IFinalizeReservationFormProps> = ({
  onComplete,
  className = "",
}) => {
  const [newCard, setNewCard] = useState(false);

  const showNewCardForm = () => {
    setNewCard(true);
  };

  const setDefault = () => {
    Application.domain.setSelectedPaymentId(
      Application.ui.account.defaultPaymentId
    );
  };

  const hideNewCardForm = () => {
    setNewCard(false);
  };

  const handleSubmit = async () => {
    onComplete?.();
  };

  useEffect(() => {
    // Set default payment method
    setDefault();
  }, []);

  const payClasses = () => {
    const classes = new Array("gold");
    if (Application.domain.selectedPaymentId) {
      classes.push("active");
    } else {
      classes.push("disabled");
    }
    return classes.join(" ");
  };

  const addCardLabel = !Application.session.paymentMethods?.length
    ? "Add card to continue"
    : "Use new card";

  return (
    <div className={`SelectPaymentMethod ${className}`}>
      {when(
        newCard === false,
        <div className="SelectPaymentMethod--methods">
          <PaymentMethodPicker />
          <div className="SelectPaymentMethod--newCard">
            <a onClick={showNewCardForm}>{addCardLabel}</a>
          </div>
          {when(
            Application.domain.selectedPaymentId,
            <Button
              label="Pay"
              onClick={handleSubmit}
              className={payClasses()}
              block={true}
            />
          )}
        </div>
      )}
      {when(
        newCard === true,
        <div className="SelectPaymentMethod--methods">
          <AddPaymentMethod
            action="Add card & Continue"
            onComplete={hideNewCardForm}
          />
          <div className="SelectPaymentMethod--newCard">
            <a onClick={hideNewCardForm}>Use default card</a>
          </div>
        </div>
      )}
    </div>
  );
};

const FinalizeReservationCheckout: FC<IFinalizeReservationProps> = ({
  onComplete,
  className = "",
}) => {
  const wrapperClasses = () => {
    const classes = new Array("FinalizeReservation");
    if (className) classes.push(className);
    return classes.join(" ");
  };
  return (
    <div className={wrapperClasses()}>
      <div className="Due">
        <div>{Application.ui.schedule.paymentDescription}</div>
        <div>
          <span>{Application.ui.schedule.formattedAmount}</span>
        </div>
      </div>
      {when(
        Application.domain.paymentIntentLoading === true,
        <LoadingAnimation absolute={true} />
      )}
      {when(Application.domain.paymentIntent?.clientSecret, (clientSecret) => (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: clientSecret }}
        >
          <FinalizeMembershipForm onComplete={onComplete} />
        </Elements>
      ))}
    </div>
  );
};

export default observer(FinalizeReservationCheckout);
