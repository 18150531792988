import "day-slots.scss";
import { observer } from "mobx-react";
import moment from "moment";
import React, { FC } from "react";
import Moment from "react-moment";
import { Application } from "../../../client/store";
import { when } from "../../../client/util/when";
import { RescheduleUI } from "../../store/ui/reschedule.ui";
import { ScheduleUI } from "../../store/ui/schedule.ui";

interface IDaySlotsProps {
  className?: string;
  date: Date;
  condensed?: boolean;
  ui: RescheduleUI | ScheduleUI;
  loading?: boolean;
}

interface IData {
  date: string;
  time: number;
  staffId: number;
  available: boolean;
  disabled: boolean;
  selected: boolean;
  matchesAnyAppointment: boolean;
  matchesAppointment: boolean;
  matchesLocationAppointment: boolean;
}

const DaySlots: FC<IDaySlotsProps> = observer(
  ({
    className = "",
    date = new Date(),
    condensed = false,
    ui = Application.ui.schedule,
    loading = false,
  }) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");

    const openAppointments = Application.ui.appointments.counts.open;
    const completedAppointments = Application.ui.appointments.counts.completed;
    const maxAppointments =
      Application.session.membership?.maxAppointments || 0;

    const exceededMaxAppointments =
      Number(openAppointments) >= Number(maxAppointments) &&
      ui instanceof ScheduleUI;

    // All CSS classes
    const getClasses = () => {
      const classNames = ["DaySlots"];
      if (loading) classNames.push("DaySlots--loading");
      if (className) classNames.push(className);
      if (isToday()) classNames.push("DaySlots-today");
      if (condensed) classNames.push("DaySlots-condensed");
      if (exceededMaxAppointments) classNames.push("DaySlots-full");
      return classNames.join(" ");
    };

    const isToday = () =>
      new Date().toDateString() === date.toDateString() ? true : false;

    const clickHandler = (data: IData) => {
      if (data.disabled === true) return;
      if (data.matchesAnyAppointment === true) return;
      ui.setStartDate(moment(data.date).toDate());
      ui.setSelectedBayId(data.staffId);
      ui.setStartTime(data.time);
      ui.setEndTime(data.time + 100);
    };

    const sameDay = (date1: Date, date2: Date) =>
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    const bayClasses = (data: IData) => {
      const classNames = ["Timeslot__Bay"];
      if (data.selected) classNames.push("selected");
      if (data.matchesAppointment) classNames.push("being-rescheduled");
      if (data.matchesAnyAppointment) classNames.push("has-appointment");
      if (data.disabled) classNames.push("disabled");
      return classNames.join(" ");
    };

    // Whether or not to show restricted message
    const showRestrictedMessage =
      ui.insideBookingWindow(date) === false ||
      exceededMaxAppointments === true;

    const restrictedBookingMessage = (): string => {
      if (ui.insideBookingWindow(date) === false) {
        return ui.restrictedBookingMessage;
      }
      if (exceededMaxAppointments === true) {
        return ui.exceededMaxAppointmentMessage;
      }
      return "";
    };

    return (
      <div className={getClasses()}>
        {when(
          showRestrictedMessage,
          <div className="RestrictedBooking">{restrictedBookingMessage()}</div>
        )}
        <div className="DaySlots__Header">
          <div className="DaySlots__Header-day">
            {isToday() ? "Today" : <Moment format="dddd">{date}</Moment>}
          </div>
          <div className="DaySlots__Header-date">
            <Moment format="MMM D">{date}</Moment>
          </div>
        </div>
        {condensed && (
          <div className="DaySlots__Bays">
            {ui.location?.bays.map((bay, i) => (
              <div key={i}>
                <span>{bay.name.substring(0, 1)}</span>
              </div>
            ))}
          </div>
        )}
        <div className="DaySlots__Body">
          {/* Loop through each time in the times array */}
          {ui.hours.map((time: number, i) => {
            // If the first appointment
            let outsideFirstAppointmentWindow = false;
            if (
              completedAppointments <= 0 &&
              (time < Application.ui.appointments.first.startHour ||
                time > Application.ui.appointments.first.endHour)
            ) {
              outsideFirstAppointmentWindow = true;
            }

            if (outsideFirstAppointmentWindow || exceededMaxAppointments) {
              // Only if the user is not a member
              if (Application.ui.account.isMember === false) {
                return;
              }
            }

            return (
              <div className="Timeslot" key={i}>
                {/* Loop through every bay available */}
                {ui.location?.bays.map((bay, ii) => {
                  const isAvailable = ui.timeslotAvailable(
                    formattedDate,
                    time,
                    bay.staffId
                  );
                  const isSeelected =
                    sameDay(ui.startDate, moment(date).toDate()) &&
                    ui.selectedBayId === bay.staffId &&
                    ui.startTime === time;
                  let disabled = false;
                  const matchesAppointment =
                    Application.domain.appointmentMatchesTimeslot(
                      formattedDate,
                      time,
                      bay.staffId
                    );
                  const matchesAnyAppointment = ui.matchesClientAppointment(
                    formattedDate,
                    time,
                    bay.staffId
                  );
                  const matchesLocationAppointment =
                    ui.matchesLocationAppointment(formattedDate, time);

                  // Disabled status
                  if (!isAvailable && !matchesAppointment) disabled = true;
                  if (
                    exceededMaxAppointments &&
                    ui instanceof ScheduleUI &&
                    !matchesAppointment
                  ) {
                    disabled = true;
                  }

                  // If the first appointment
                  if (
                    outsideFirstAppointmentWindow &&
                    Application.ui.account.isMember === false
                  ) {
                    disabled = true;
                  }

                  if (
                    matchesLocationAppointment &&
                    ui instanceof ScheduleUI &&
                    !matchesAppointment
                  ) {
                    disabled = true;
                  }
                  const data: IData = {
                    date: formattedDate,
                    time: time,
                    staffId: bay.staffId,
                    available: isAvailable,
                    disabled: disabled,
                    selected: isSeelected,
                    matchesAnyAppointment: matchesAnyAppointment,
                    matchesAppointment: matchesAppointment,
                    matchesLocationAppointment: matchesLocationAppointment,
                  };
                  return (
                    <div
                      className={bayClasses(data)}
                      key={ii}
                      onClick={() => clickHandler(data)}
                    >
                      {!condensed && (
                        <div className="Timeslot__Bay-name">
                          {bay.name.substring(0, 1)}
                        </div>
                      )}
                      <div className="Timeslot__Bay-time">
                        <Moment format="h A" add={{ hours: time / 100 }}>
                          {date}
                        </Moment>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default DaySlots;
