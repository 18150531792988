import React, { FC } from "react";
import "submit-button.scss";

interface ISubmitButtonProps {
  label: string;
  className?: string;
  type?: string;
  name?: string;
}

const SubmitButton: FC<ISubmitButtonProps> = ({
  className = "",
  label = "Submit",
  type = "submit",
  name = "submit",
}) => (
  <div className={`SubmitButton ${className}`}>
    <input type={type} value={label} name={name} />
  </div>
);

export default SubmitButton;
