import { observer } from "mobx-react";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "text-input.scss";
import { when } from "../util/when";

interface ITextInputProps {
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  stopTyping?(event: ChangeEvent<HTMLInputElement>): void;
  placeholder?: string;
  className?: string;
  type?: string;
  name: string;
  id?: string;
  value?: string;
  required?: boolean;
  autofocus?: boolean;
}

const TextInput: FC<ITextInputProps> = ({
  onChange,
  stopTyping,
  className = "",
  placeholder = "",
  type = "text",
  name = "",
  id = "",
  value = "",
  required = false,
  autofocus = false,
}) => {
  const [theValue, setTheValue] = useState(value);
  const timeout = useRef<number | undefined>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTheValue(event.target.value);
    if (onChange) {
      onChange(event);
    }

    // Clear previous timeout and start a new one
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = window.setTimeout(() => {
      if (stopTyping) {
        stopTyping(event);
      }
    }, 500);
  };

  useEffect(() => {
    setTheValue(value);
  }, [value]);

  return (
    <div className={`TextInput ${className} ${theValue ? "hasValue" : ""}`}>
      <input
        type={type}
        name={name}
        required={required}
        id={id ? id : name}
        value={theValue}
        onChange={handleChange}
        autoFocus={autofocus}
      ></input>
      {when(placeholder, <label>{placeholder}</label>)}
    </div>
  );
};

export default observer(TextInput);
