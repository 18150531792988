import Navigation from "app/client/ui/navigation";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../env";
import { Application } from "../../store";
import { when } from "../../util/when";
import VideoVariables from "./variables";
import { VideoListItem } from "./video-list-item";

// ReactPlayer docs
// https://github.com/CookPete/react-player#props

// Styles
import "./videos.scss";

/**
 * An example of a page being made
 */
export const VideoPage = observer(() => {
  const navigate = useNavigate();

  const clickTag = (tag: string) => {
    Application.ui.video.setSelectedTag(tag);
  };

  const clickFolder = (folder: string) => {
    Application.ui.video.setSelectedFolder(folder);
  };

  const clickAll = () => {
    Application.ui.video.setSelectedTag(null);
  };

  const clickVideo = () => {
    navigate(ENV.routes.videoViewer);
  };

  useEffect(() => {
    // Do nothing
  }, []);

  return (
    <div className={`VideoPage`}>
      <Navigation />
      <div className="VideoPage__Content">
        {when(Application.domain.testMode, <VideoVariables />)}
        <div className="Videos">
          <div className="Videos__Folders">
            {Application.ui.video.folders.map((folder, key) => {
              const selected = Application.ui.video.selectedFolder === folder;
              const selectedClass = selected ? "active" : "";
              return (
                <a
                  className={selectedClass}
                  onClick={() => clickFolder(folder)}
                  key={key}
                >
                  {folder}
                </a>
              );
            })}
          </div>
          <div className="Videos__Tags">
            {when(
              Application.ui.video.tags.length > 0,
              <a
                className={Application.ui.video.allTagsClass}
                onClick={clickAll}
              >
                All
              </a>
            )}
            {Application.ui.video.tags.map((tag, key) => {
              const selected = Application.ui.video.selectedTag === tag;
              const selectedClass = selected ? "active" : "";
              return (
                <a
                  className={selectedClass}
                  onClick={() => clickTag(tag)}
                  key={key}
                >
                  {tag}
                </a>
              );
            })}
          </div>
          <div className="Videos__List">
            {Application.ui.video.taggedVideos.map((video, key) => (
              <VideoListItem onClick={clickVideo} video={video} key={key} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
