import { CloudAPI } from "app/client/api/cloud.api";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword, // Get the auth instance
  getAuth, // Listen for auth state changes
  onAuthStateChanged, // Sign in with email and password
  sendEmailVerification, // Sign out of Firebase
  sendPasswordResetEmail, // Update user profile on Firebase
  signInWithEmailAndPassword, // Update user email on Firebase
  signOut, // Update user password on Firebase
  updateEmail, // User type
  updatePassword, // Send password reset email
  updateProfile, // Using later, don't worry Typescript!!!
  User, // Send email verification
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Joi from "joi";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { Location, NavigateFunction } from "react-router-dom";
import { ENV } from "../../env";
import { FlowType } from "../../types";
import ErrorAndCode from "../../ui/error";
import { cookieParser } from "../../util/cookie-parser";
import { ApplicationStore } from "../application.store";
import {
  IMembershipSettings,
  IPaymentMethods,
  IStripeSubscription,
} from "../interfaces";
import { Store } from "../store";

// Firebase Config
export const firebaseConfig = {
  apiKey: "AIzaSyBJzJBtt6Hj5L51A44Doc73PShFgV6kAZQ",
  authDomain: "citygolfclub-production.firebaseapp.com",
  databaseURL: "https://citygolfclub-production.firebaseio.com",
  projectId: "citygolfclub-production",
  storageBucket: "citygolfclub-production.appspot.com",
  messagingSenderId: "264639526407",
  appId: "1:264639526407:web:a597781fa0e7df68831ca2",
  measurementId: "G-HW4HKBRPNQ",
};

interface IMonthlyBookingData {
  bays: number;
  lessons: number;
  guests: number;
  noShows: number;
}

interface IUserDoc {
  clientId: number | null;
  email: string;
  id: string;
  membership: string;
  monthlyBookingData: IMonthlyBookingData | null;
  name: string;
  membershipLocations?: number[];
  lastLocationId?: number;
  stripeCustomerId: string | null;
  stripeSubscriptionId: string | null;
  stripeSubscriptionStatus: string | null;
  timetapClientId: number | null;
  waivers: object | null;
}

interface IUserDocFields {
  [key: string]: any;
  email?: string;
  membership?: string;
  monthlyBookingData?: IMonthlyBookingData;
  name?: string;
  membershipLocations?: number[];
  lastLocationId?: number;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  stripeSubscriptionStatus?: string;
  timetapClientId?: number;
}

interface IUserDataFromUI {
  email: string;
  name: string;
  password: string;
}

interface IMembership {
  maxDailyAppointments: number;
  maxAppointments: number;
  windowLength: number;
  startHour: number;
  endHour: number;
  maxGuests: number;
  freeGuests: number;
  subscribed: boolean;
  name: string;
  membershipLocations?: number[];
  lastLocationId?: number;
  appointments?: {
    [key: string]: number;
  };
  window: {
    startDate: string;
    endDate: string;
    startTimestamp: number;
    endTimestamp: number;
  };
}

export interface IStripeCustomer {
  id: string;
  object: string;
  address: null | string;
  balance: number;
  created: number;
  currency: null | string;
  default_currency: null | string;
  default_source: null | string;
  delinquent: boolean;
  description: null | string;
  discount: null | any;
  email: string;
  invoice_prefix: string;
  invoice_settings: {
    custom_fields: null | any;
    default_payment_method: null | string;
    footer: null | string;
    rendering_options: null | any;
  };
  livemode: boolean;
  metadata: Record<string, any>;
  name: string;
  next_invoice_sequence: number;
  phone: null | string;
  preferred_locales: string[];
  shipping: null | any;
  sources: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  subscriptions: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  tax_exempt: string;
  tax_ids: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  test_clock: null | number;
}

export const StripeCustomerValidator = Joi.object({
  id: Joi.string().required(),
  object: Joi.string().required(),
  address: Joi.any().optional().allow(null),
  balance: Joi.number().required(),
  created: Joi.number().required(),
  currency: Joi.any().optional().allow(null),
  default_currency: Joi.any().optional().allow(null),
  default_source: Joi.any().optional().allow(null),
  delinquent: Joi.boolean().required(),
  description: Joi.any().optional().allow(null),
  discount: Joi.any().optional().allow(null),
  email: Joi.string().required(),
  invoice_prefix: Joi.string().required(),
  invoice_settings: Joi.object({
    custom_fields: Joi.any().optional().allow(null),
    default_payment_method: Joi.any().optional().allow(null),
    footer: Joi.any().optional().allow(null),
    rendering_options: Joi.any().optional().allow(null),
  }).required(),
  livemode: Joi.boolean().required(),
  metadata: Joi.object().required(),
  name: Joi.string().required(),
  next_invoice_sequence: Joi.number().required(),
  phone: Joi.any().optional().allow(null),
  preferred_locales: Joi.array().items(Joi.string()).required(),
  shipping: Joi.any().optional().allow(null),
  sources: Joi.object({
    object: Joi.string().required(),
    data: Joi.array().items(Joi.any()).required(),
    has_more: Joi.boolean().required(),
    total_count: Joi.number().required(),
    url: Joi.string().required(),
  }).required(),
  subscriptions: Joi.object({
    object: Joi.string().required(),
    data: Joi.array().items(Joi.any()).required(),
    has_more: Joi.boolean().required(),
    total_count: Joi.number().required(),
    url: Joi.string().required(),
  }).required(),
  tax_exempt: Joi.string().required(),
  tax_ids: Joi.object({
    object: Joi.string().required(),
    data: Joi.array().items(Joi.any()).required(),
    has_more: Joi.boolean().required(),
    total_count: Joi.number().required(),
    url: Joi.string().required(),
  }).required(),
  test_clock: Joi.any().optional().allow(null),
}).unknown(true);

// Information that is required for the userDoc
type RequiredUserDoc = {
  membershipLocations?: number[];
  membership?: string;
  stripeSubscriptionId?: string;
  stripeSubscriptionStatus?: string;
  lastLocationId?: number;
};

// Typeguard for IUserDoc
export function isStripeCustomer(obj: any): obj is IUserDoc {
  const { error } = StripeCustomerValidator.validate(obj);
  if (error) {
    throw new Error(error.message);
  }
  return true;
}

// JOI validator for IUSerDoc
export const UserDocValidator = Joi.object({
  clientId: Joi.number().optional(),
  email: Joi.string().required(),
  id: Joi.string().required(),
  membership: Joi.string().allow("", null).optional(),
  monthlyBookingData: Joi.alternatives()
    .try(Joi.string().allow("", null), Joi.object(), Joi.allow(null))
    .optional(),
  name: Joi.string(),
  stripeCustomerId: Joi.string().allow("", null).optional(),
  stripeSubscriptionId: Joi.string().allow("", null).optional(),
  stripeSubscriptionStatus: Joi.string().allow("", null).optional(),
  membershipLocations: Joi.array().items(Joi.number()).optional(),
  lastLocationId: Joi.number().allow("", null).optional(),
  appointments: Joi.object()
    .pattern(Joi.string(), Joi.number().allow("", null))
    .optional(),
  waivers: Joi.object({
    firstBooking: Joi.boolean().allow(null).optional(),
  })
    .allow(null)
    .optional()
    .unknown(true),
}).unknown(true);

// Typeguard for IUserDoc
export function isUserDoc(obj: any): obj is IUserDoc {
  const { error } = UserDocValidator.validate(obj);
  if (error) {
    throw new Error(error.message);
  }
  return true;
}

export class SessionStore extends Store {
  /**
   * Contains all errors encountered regarding the user's session. This will be
   * populated mostly with responses from the server from fetch calls.
   */
  @observable error: (string | Error)[] = [];
  /**
   * Retrieves the current search params from the URL.
   */
  @observable urlSearchParams: URLSearchParams = new URL(document.location.href)
    .searchParams;
  /**
   * Stores the sessions current cookie information.
   */
  @observable cookie: Record<string, string>;

  @observable user?: User | null = void 0;
  @observable userDoc?: IUserDoc | null = void 0;
  @observable membership?: IMembership | null = void 0;
  @observable membershipSettings?: IMembershipSettings | null = void 0;
  @observable loggingIn: boolean = true;
  @observable formError?: string = void 0;
  @observable formSuccess?: boolean = false;
  @observable isSubmitting?: boolean = false;
  @observable stripeCustomer?: IStripeCustomer = void 0;
  @observable paymentMethods?: IPaymentMethods = void 0;

  // Reset form error and success
  @action
  resetForms() {
    this.formError = void 0;
    this.formSuccess = false;
  }

  app = initializeApp(firebaseConfig);
  auth = getAuth();
  db = getFirestore();
  users = collection(this.db, "users");
  location: Location;
  navigate: NavigateFunction;

  authDisposer = onAuthStateChanged(this.auth, (user: User | null) => {
    if (user) {
      this.didSignIn(user);
    } else {
      this.didSignOut();
    }
  });

  @computed
  get hasError() {
    return this.error.length > 0;
  }

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  /**
   * Generates a session error. Errors with a string body will be logged into
   * the session error log. All messages will generate a toast message.
   */
  @action
  makeError(message: any) {
    console.warn("Error handling not implemented yet for this application");
    console.error(message);
  }

  /**
   * Forces the search params to be reanalyzed for new values.
   */
  @action
  updateSearchParams() {
    this.urlSearchParams = new URL(document.location.href).searchParams;
  }

  /**
   * This should be called upon Application boot up. This will initialize any
   * session related needs the user should have when exploring this application.
   */
  @flow
  *init(): FlowType {
    this.updateSearchParams();
    this.cookie = cookieParser();
    if (this.user) {
      yield this.getUserDoc();
    }
  }

  /**
   * This allows the application to respond to page changes and do sweeping
   * state updates such as clearing caches or resetting state as deemed
   * appropriate.
   */
  @action
  pageDidLoad(location: Location, navigate: NavigateFunction) {
    // Always keep our location and navigate methods up to date with the hook
    // state, and keep them within reach of session controls.
    this.location = location;
    this.navigate = navigate;
  }

  @flow
  *didSignIn(user: User): FlowType {
    this.loggingIn = false;
    this.user = user;
    // Load the user document from Firestore
    yield this.getUserDoc();
    if (!this.navigate) return;

    // For the special case of being on the login page directly, we'll want to
    // redirect to the schedule page.
    if (this.location.pathname === ENV.routes.login) {
      this.navigate(ENV.routes.reservations);
    }
  }

  @action
  didSignOut() {
    this.loggingIn = false;
    if (!this.navigate) return;
  }

  @flow
  *getUserDoc(): FlowType {
    if (!this.user) return;
    if (this.userDoc) return;

    const userRef = yield doc(this.users, this.user.uid);
    const results = yield getDoc(userRef);
    const data = yield results.data();

    try {
      if (!data) {
        throw new Error("Data is null");
      }
      if (isUserDoc(data)) {
        // TODO: Create stripe membership if does not exist

        // Create Stripe account if it does not exist
        if (!data.stripeCustomerId) {
          // Attempt to load the stripe customer by email first
          const stripeCustomerByEmail = yield CloudAPI.getStripeCustomer(
            this.application,
            {
              email: data.email,
            }
          );
          if (stripeCustomerByEmail.exists) {
            data.stripeCustomerId = stripeCustomerByEmail.customer.id;
          } else {
            const newStripeCustomer = yield CloudAPI.createStripeCustomer(
              this.application,
              {
                email: data.email,
                name: data.name,
              }
            );
            data.stripeCustomerId = newStripeCustomer.customer.id;
          }
          yield updateDoc(userRef, {
            stripeCustomerId: data.stripeCustomerId,
          });
        }

        // Create timetap account if it does not exist
        if (!data.clientId) {
          const clientByEmail = yield CloudAPI.getTimetapClient(
            this.application,
            { email: data.email }
          );
          if (clientByEmail.exists) {
            data.clientId = clientByEmail.client.clientId;
          } else {
            const { firstName, lastName } = this.splitName(data.name);
            const newClient = yield CloudAPI.createTimetapClient(
              this.application,
              {
                firstName: firstName,
                lastName: lastName,
                email: data.email,
              }
            );
            data.clientId = newClient.client.clientId;
          }
          yield updateDoc(userRef, {
            clientId: data.clientId,
          });
        }

        this.userDoc = data;
        yield this.updateMembershipSettings(data?.membership);
        yield this.application.domain.loadAppointments();
        yield this.loadStripeCustomer();

        // If lastLocationId is set, then set locationId
        if (data.lastLocationId) {
          this.application.ui.schedule.setLocationId(data.lastLocationId);
        }

        // Fix any issues with user data on Firebase
        this.addMissingData();
      } else {
        this.userDoc = null;
      }
    } catch (error) {
      console.error("UserDoc validation error: ", error.message);
      this.userDoc = null;
    }
  }

  @flow
  *updateMembershipSettings(membership: string): FlowType {
    if (!this.userDoc) return;
    const results = yield CloudAPI.getMembershipSettings(this.application);
    const membershipType = membership ? membership.toLowerCase() : "everyone";
    this.membershipSettings = results.settings;
    const membershipSettings = results.settings[membershipType];
    this.membership = {
      maxDailyAppointments: Number(membershipSettings.maxDailyAppointments),
      maxAppointments: Number(membershipSettings.maxAppointments),
      windowLength: Number(membershipSettings.windowLength),
      startHour: Number(membershipSettings.startHour),
      endHour: Number(membershipSettings.endHour),
      maxGuests: Number(membershipSettings.maxGuests),
      freeGuests: Number(membershipSettings.freeGuests),
      name: membership ? membership.toUpperCase() : "",
      subscribed: membershipSettings.subscribed,
      window: {
        startDate: String(membershipSettings.window.startDate),
        endDate: String(membershipSettings.window.endDate),
        startTimestamp: Number(membershipSettings.window.startTimestamp),
        endTimestamp: Number(membershipSettings.window.endTimestamp),
      },
    };
  }

  @flow
  *updateUserDoc(): FlowType {
    if (!this.user) return;
    const userRef = doc(this.users, this.user.uid);
    const results = yield getDoc(userRef);
    const data = results.data();

    try {
      if (isUserDoc(data)) {
        this.userDoc = data;
        yield this.updateMembershipSettings(data?.membership);
        yield this.application.domain.loadAppointments();
        yield this.loadStripeCustomer();
      } else {
        this.userDoc = null;
      }
    } catch (error) {
      console.error("UserDoc validation error: ", error.message);
      this.userDoc = null;
    }
  }

  @flow
  *sendResetEmail(email: string): FlowType {
    yield sendPasswordResetEmail(this.auth, email);
    this.formSuccess = true;
  }

  @flow
  *signIn(email: string, password: string): FlowType {
    const results = yield signInWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    if (results && results.user) {
      const user = results.user;
      if (user.emailVerified === false) {
        throw new ErrorAndCode(
          "Please verify your email address before signing in.",
          "verify-email"
        );
      }
    }
  }

  @flow
  *signOut() {
    yield signOut(this.auth).catch((error: Error) => {
      console.error(error.stack || error.message);
      this.formError = error.message;
    });
    this.reset();
  }

  @action
  reset() {
    this.user = void 0;
    this.userDoc = void 0;
    this.application.ui.schedule.reset();
    this.application.ui.appointments.reset();
    this.application.domain.reset();
    this.resetPaymentMethods();
  }

  @action
  resetPaymentMethods() {
    this.paymentMethods = void 0;
  }

  splitName(name: string) {
    const [firstName, ...lastNameArray] = name.split(" ");
    const lastName = lastNameArray.join(" ");
    return { firstName, lastName };
  }

  hasLocationMembership(locationId: number) {
    if (!this.userDoc) return false;
    if (!this.userDoc.membershipLocations) return false;
    return this.userDoc?.membershipLocations.includes(locationId);
  }

  @flow
  *checkDuplicates(email: string): FlowType {
    if (!email) {
      throw new Error("Email is required");
    }
    const timetapClient = yield CloudAPI.getTimetapClient(this.application, {
      email: email,
    });
    const stripeCustomer = yield CloudAPI.getStripeCustomer(this.application, {
      email: email,
    });
    if (timetapClient.exists || stripeCustomer.exists) {
      throw new Error("This email address is already in use.");
    }
  }

  @flow
  *createUser(data: IUserDataFromUI): FlowType {
    const { email, password, name } = data;

    // Check for existing accounts using the same email
    yield this.checkDuplicates(email);

    // Create firebase user
    const userCredential = yield createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    const { user } = userCredential;

    // Create Timetap user document: Requires firstName, lastName, email
    const { firstName, lastName } = this.splitName(name);
    const response = yield CloudAPI.createTimetapClient(this.application, {
      firstName: firstName,
      lastName: lastName,
      email: email,
    });

    if (!response || !response.success) {
      throw new Error(`Failed to create Timetap user: ${response}`);
    }
    const timetapClient = response.client;

    // Create Stripe user account: Requires name, email
    const results = yield CloudAPI.createStripeCustomer(this.application, {
      name: name,
      email: email,
      firebaseUserId: String(user.uid),
      timetapClientId: String(timetapClient.clientId),
    });

    if (!results || !results.success) {
      throw new Error("Failed to create Stripe user");
    }
    const stripeClient = results.customer;

    // Update Firebase user info with timetap and stripe IDs
    const setFields: IUserDoc = {
      clientId: Number(timetapClient.clientId),
      email: email,
      id: user.uid,
      membership: "",
      monthlyBookingData: null,
      name: name,
      membershipLocations: [],
      timetapClientId: Number(timetapClient.clientId),
      stripeCustomerId: String(stripeClient.id),
      stripeSubscriptionId: null,
      stripeSubscriptionStatus: null,
      waivers: {},
    };
    const userRef = yield doc(this.users, user.uid);
    yield setDoc(userRef, setFields);
    // yield this.getUserDoc();

    // Finally sign in when all done
    // this.signIn(email, password);

    // Send verification email before allowing the user to login
    sendEmailVerification(user);

    // Navigate to the success page
    this.navigate(ENV.routes.signupSuccess);
  }

  resendVerificationEmail() {
    if (!this.user) return;
    sendEmailVerification(this.user);
  }

  /**
   *
   * Ensure userDoc has all required fields for the application to run. This
   * is primarily for legacy users who were created prior to the new app.
   * - membershipLocations
   * - membership
   * - stripeSubscriptionId
   *
   * @param locationId
   * @returns
   */

  @flow
  *addMissingData(): FlowType {
    if (!this.auth.currentUser) return;
    if (!this.userDoc) return;
    try {
      // Update the Firestore user document for members
      if (this.user && this.userDoc.membership) {
        const userRef = doc(this.users, this.user.uid);
        const data: RequiredUserDoc = {};

        // If membership is "ALL", then count membershipLocations to see if it
        // matches the count of locations in the application.
        if (
          this.userDoc.membership.toUpperCase().trim() === "ALL" &&
          this.userDoc.membershipLocations
        ) {
          const memberOf = this.userDoc.membershipLocations.length;
          const totalLocatrions = this.application.domain.locations.length;
          if (memberOf < totalLocatrions) {
            data.membershipLocations = this.application.domain.locations.map(
              (location) => location.locationId
            );
          }
        }

        // Update membership locations if missing
        if (
          !this.userDoc.membershipLocations ||
          !this.userDoc.membershipLocations.length
        ) {
          data.membershipLocations = [453695]; // Add Crescent locationId
        }

        // If there is no lastLocationId, use the first locationId
        if (!this.userDoc.lastLocationId) {
          data.lastLocationId = 453695; // Default last locationId is Crescent
        }

        // If data.membership is not all uppsercase
        if (this.userDoc.membership !== this.userDoc.membership.toUpperCase()) {
          data.membership = this.userDoc.membership.toUpperCase().trim();
        }

        // If the user has a stripe subscription and it does not match the
        // userDoc, update the userDoc.
        if (
          this.stripeCustomer?.subscriptions?.data &&
          this.stripeCustomer.subscriptions.data.length
        ) {
          if (
            this.userDoc.stripeSubscriptionId !==
            this.stripeCustomer.subscriptions.data[0].id
          ) {
            data.stripeSubscriptionId =
              this.stripeCustomer.subscriptions.data[0].id;
            data.stripeSubscriptionStatus = "active";
          }
        }

        // Update if there is data to update
        if (Object.keys(data).length) {
          yield updateDoc(userRef, data);
        }
      }

      // Update the user document in this session
      yield this.updateUserDoc();
    } catch (error) {
      console.error(error);
    }
  }

  @flow
  *updateFirebaseUser(data: IUserDocFields): FlowType {
    this.formSuccess = false;
    if (!this.auth.currentUser) return;
    try {
      // Update the Firebase user
      if (data.name) {
        yield updateProfile(this.auth.currentUser, { displayName: data.name });
      }

      // Update the Firestore user document
      if (this.user) {
        const userRef = doc(this.users, this.user.uid);
        yield updateDoc(userRef, data);
      }

      // Update the user document in this session
      yield this.updateUserDoc();

      this.formSuccess = true;
    } catch (error) {
      console.error(error);
    }
  }

  @flow
  *updateDisplayName(displayName: string): FlowType {
    this.formSuccess = false;
    if (!this.auth.currentUser) return;
    try {
      // Update the Firebase user
      yield updateProfile(this.auth.currentUser, { displayName: displayName });

      // Update Timetap first and last name
      const { firstName, lastName } = this.splitName(displayName);
      yield CloudAPI.updateTimetapClient(this.application, {
        clientId: String(this.timetapClientId),
        firstName: firstName,
        lastName: lastName,
      });

      // Update the Firestore user document
      if (this.user) {
        const userRef = doc(this.users, this.user.uid);
        yield updateDoc(userRef, {
          name: displayName,
        });
      }

      // Update the user document in this session
      yield this.updateUserDoc();

      this.formSuccess = true;
    } catch (error) {
      console.error(error);
    }
  }

  @flow
  *updateEmail(email: string): FlowType {
    this.formSuccess = false;
    if (!this.auth.currentUser) return;
    try {
      yield updateEmail(this.auth.currentUser, email);
      this.formSuccess = true;
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/email-already-in-use":
          this.formError = "That email is already in use";
          break;
        case "auth/invalid-email":
          this.formError = "The email you provided is not valid";
          break;
        case "auth/requires-recent-login":
          this.formError = "You must login again to update your email";
          break;
        default:
          this.formError = "Form error";
          break;
      }
    }
  }

  @flow
  *updateLogin(password: string): FlowType {
    this.formSuccess = false;
    if (!this.auth.currentUser) return;
    try {
      yield updatePassword(this.auth.currentUser, password);
      this.formSuccess = true;
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/requires-recent-login":
          this.formError = "You must login again to update your password";
          break;
        case "auth/weak-password":
          this.formError = "Your password is too weak";
          break;
        case "auth/invalid-password":
          this.formError = "Your password is invalid";
          break;
        case "auth/user-mismatch":
          this.formError = "User mismatch";
          break;
        case "auth/user-not-found":
          this.formError = "User does not exist";
          break;
        case "auth/network-request-failed":
          this.formError = "Network error";
          break;
        default:
          this.formError = "Form error";
          break;
      }
    }
  }

  @flow
  *loginAndUpdatePassword(
    currentPassword: string,
    newPassword: string
  ): FlowType {
    if (!this.auth.currentUser) return;
    if (!this.auth.currentUser.email) return;
    yield this.signIn(this.auth.currentUser.email, currentPassword);
    yield this.updateLogin(newPassword);
  }

  @flow
  *loadStripeCustomer(): FlowType {
    if (!this.stripeCustomerId) return;
    try {
      const customer = yield CloudAPI.getStripeCustomer(this.application, {
        customerId: this.stripeCustomerId,
      });
      if (!customer) {
        throw new Error(
          `Error loading Stripe Customer: ${this.stripeCustomerId}`
        );
      }
      if (isStripeCustomer(customer.customer)) {
        this.stripeCustomer = customer.customer;
        this.loadPaymentMethods();
        if (this.stripeCustomer?.invoice_settings?.default_payment_method) {
          this.application.domain.setSelectedPaymentId(
            this.stripeCustomer?.invoice_settings?.default_payment_method
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  @flow
  *loadPaymentMethods(): FlowType {
    if (!this.stripeCustomerId) return;
    try {
      const data = {
        customerId: String(this.stripeCustomerId),
      };
      const results = yield CloudAPI.getPaymentMethods(this.application, data);
      if (!results) {
        throw new Error("Error loading payment methods");
      }
      this.paymentMethods = results.methods;
    } catch (error) {
      console.error(error);
    }
  }

  @computed
  get displayName(): string {
    if (!this.userDoc) return "";
    return this.userDoc.name;
  }

  @computed
  get displayFirstName(): string {
    if (!this.userDoc) return "";
    return this.userDoc.name.split(" ")[0].trim();
  }

  @computed
  get subscription(): IStripeSubscription | undefined {
    if (!this.stripeCustomer) return;
    if (this.stripeCustomer.subscriptions.data.length <= 0) return;
    return this.stripeCustomer.subscriptions.data[0];
  }

  @computed
  get stripePaymentMethods(): IPaymentMethods {
    if (!this.paymentMethods) return [];
    return this.paymentMethods;
  }

  @computed
  get customer() {
    if (!this.stripeCustomer) return;
    return this.stripeCustomer;
  }

  @computed
  get stripeCustomerId() {
    if (!this.userDoc) return;
    return this.userDoc.stripeCustomerId;
  }

  @computed
  get timetapClientId() {
    if (!this.userDoc) return;
    return this.userDoc.clientId;
  }

  @computed
  get membershipName() {
    if (!this.userDoc) return;
    return this.userDoc.membership;
  }
}
