import React, { FC } from "react";

interface IIconArrowRightProps {
  className?: string;
  size?: number;
}

const IconArrowRight: FC<IIconArrowRightProps> = ({
  className = "",
  size = 14,
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 14 14`}
    className={`Icon__ArrowRight color-stroke ${className}`}
  >
    <path d="M7 0.988281L13.0117 7L7 13.0117L5.94531 11.957L10.1289 7.73828H0.988281V6.26172H10.1289L5.94531 2.04297L7 0.988281Z" />
  </svg>
);

export default IconArrowRight;
