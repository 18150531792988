import { Application } from "app/client/store";
import IconMinus from "app/client/svg/minus";
import IconPlus from "app/client/svg/plus";
import "guest-picker.scss";
import { observer } from "mobx-react";
import React, { FC } from "react";

interface IGuestPickerProps {
  className?: string;
  min?: number;
}

const GuestPicker: FC<IGuestPickerProps> = ({ className = "", min = 0 }) => {
  const incrementGuest = (direction: "up" | "down") => {
    if (direction === "up") {
      Application.ui.schedule.guestsUp();
    }
    if (direction === "down") {
      Application.ui.schedule.guestsDown();
    }
  };

  return (
    <div className={`GuestPicker ${className}`}>
      <div
        className={`GuestPicker-minus ${
          Application.ui.schedule.guests === min ? "disabled" : ""
        }`}
        onClick={() => incrementGuest("down")}
      >
        <IconMinus />
      </div>
      <div className="GuestPicker-count">{Application.ui.schedule.guests}</div>
      <div
        className={`GuestPicker-plus ${
          Application.ui.schedule.guests === Application.ui.schedule.maxGuests
            ? "disabled"
            : ""
        }`}
        onClick={() => incrementGuest("up")}
      >
        <IconPlus />
      </div>
    </div>
  );
};

export default observer(GuestPicker);
