import { ENV } from "app/client/env";
import BackButton from "app/client/ui/back-button";
import Navigation from "app/client/ui/navigation";
import SubmitButton from "app/client/ui/submit-button";
import TextInput from "app/client/ui/text-input";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";

// Styles
import { Application } from "app/client/store";
import FormError from "app/client/ui/form-error";
import FormSuccess from "app/client/ui/form-success";
import { when } from "app/client/util/when";
import "./change-password.scss";

/**
 * An example of a page being made
 */
export const ChangePasswordPage = observer(() => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!formRef.current) return;
    if (password !== retypePassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setPasswordError("");
    Application.session.loginAndUpdatePassword(currentPassword, password);
  }

  // Reset the forms on load
  useEffect(() => {
    Application.session.resetForms();
  }, []);

  return (
    <div className={`ChangePassword`}>
      <Navigation />
      <div className="ChangePassword__Content">
        <BackButton route={ENV.routes.account} label="Back to Account" />
        <h2>Update password</h2>
        {when(
          Application.session.formSuccess,
          <FormSuccess label="Email successfully updated!" />
        )}
        {when(
          !Application.session.formSuccess,
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="ChangePassword__Form">
              {when(passwordError, (message) => (
                <FormError label={message} />
              ))}
              {when(Application.session.formError, (message) => (
                <FormError label={message} />
              ))}
              <TextInput
                placeholder="Current password"
                name="currentPassword"
                value={currentPassword}
                type="password"
                required={true}
                onChange={(e) => setCurrentPassword(e.target.value)}
                autofocus
              />
              <TextInput
                placeholder="New Password"
                name="newPassword"
                value={password}
                type="password"
                required={true}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextInput
                placeholder="Retype Password"
                name="retypePassword"
                value={retypePassword}
                type="password"
                required={true}
                onChange={(e) => setRetypePassword(e.target.value)}
              />
              <SubmitButton label="Save password" />
            </div>
          </form>
        )}
      </div>
    </div>
  );
});
