import { action, computed, flow, makeObservable, observable } from "mobx";
import moment from "moment";
import { NavigateFunction } from "react-router-dom";
import { CloudAPI } from "../../api/cloud.api";
import { FlowType } from "../../types";
import { ApplicationStore } from "../application.store";
import { IAppointmentRecord, ILocation } from "../interfaces";
import { Store } from "../store";
import { IAppointment } from "./schedule.ui";

interface ITimeslotsByDate {
  [date: string]: boolean;
}

export interface IValidateAppointment extends Record<string, string> {
  membership: string;
  date: string;
  startTime: string;
  appointments: string;
}

export class RescheduleUI extends Store {
  @observable calendarId: number = -1;
  @observable selectedLocationId: number = -1;
  @observable selectedBayId: number = -1;
  @observable selectedReasonId: number = -1;
  @observable startDate: Date = new Date();
  @observable startTime: number = 0;
  @observable endTime: number = 0;
  @observable guests: number = 0;
  @observable due: number = 0;
  @observable defaultDays: number = 7;
  @observable screenWidth: number = 0;
  @observable viewDate: Date = new Date();
  @observable rescheduling: boolean = false;
  @observable appointment: IAppointmentRecord | null = null;
  @observable error: string | null = "Nothing to reschedule";

  // Time window for loading timeslots
  @observable timeslotStartDate: Date = new Date();
  @observable timeslotEndDate: Date = new Date();

  emptyLocation: ILocation = {
    locationId: -1,
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    image: "",
    bays: [],
  };

  emptyAppointment: IAppointmentRecord = {
    calendarId: 0,
    businessId: 0,
    locationId: 0,
    status: "",
    date: "",
    timestamp: 0,
    startTime: 0,
    endTime: 0,
    note: "",
    createDate: "",
    reasonId: 0,
    reason: "",
    clientId: 0,
    client: "",
    staffId: 0,
    staff: "",
    location: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    guests: "",
    imageUrl: "",
    stripeId: "",
  };

  // Navigate function
  navigate: NavigateFunction;

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  reset() {
    this.calendarId = -1;
    this.startDate = new Date();
    this.startTime = 0;
    this.endTime = 0;
    this.guests = 0;
    this.application.domain.resetTimeslots();
    this.appointment = null;
    this.error = null;
  }

  @action
  changedLocation() {
    this.selectedBayId = -1;
    this.startTime = 0;
    this.endTime = 0;
  }

  @action
  resetCalendarId() {
    this.calendarId = -1;
  }

  @action
  setCalendarId(calendarId: number) {
    this.calendarId = calendarId;
  }

  @action
  setScreenWidth(width: number) {
    this.screenWidth = width;
  }

  @action
  setViewDate(date: Date) {
    this.viewDate = date;
  }

  @action
  setAppointment(appointment: IAppointmentRecord) {
    this.appointment =
      this.application.domain.normalizeAppointment(appointment);
  }

  @flow
  *setRescheduleView(calendarId: number): FlowType {
    this.calendarId = calendarId;
    const appointment = yield this.application.domain.loadAppointment(
      calendarId
    );
    this.setAppointment(appointment);
    if (!this.appointment) return;

    this.selectedBayId = Number(this.appointment.staffId);
    this.selectedLocationId = Number(this.appointment.locationId);
    this.selectedReasonId = Number(this.appointment.reasonId);
    const newDate = moment(this.appointment.date).toDate();
    this.viewDate = newDate;
    this.setViewWindow();
  }

  @computed
  get viewDateIsToday() {
    return new Date().toDateString() === this.viewDate.toDateString()
      ? true
      : false;
  }

  move = (direction: string) => {
    const moveDayCount =
      direction === "left" ? this.displayDays * -1 : this.displayDays;

    let newDate = new Date(
      this.viewDate.getTime() + moveDayCount * 24 * 60 * 60 * 1000
    );

    // Preview the user from navigating to a date in the past
    if (newDate < new Date()) {
      newDate = new Date();
    }

    // Set the view date
    this.setViewDate(newDate);

    // Set the timeslot window
    this.setViewWindow();
  };

  @computed
  get displayDays() {
    let numDays = this.defaultDays;
    if (this.bays <= 3) {
      if (this.screenWidth <= 768) numDays = 1;
      if (this.screenWidth > 768 && this.screenWidth <= 820) numDays = 2;
      if (this.screenWidth > 820 && this.screenWidth <= 1180) numDays = 3;
      if (this.screenWidth > 1180 && this.screenWidth <= 1400) numDays = 5;
      if (this.screenWidth >= 1400) numDays = 7;
    }
    if (this.bays >= 4) {
      if (this.screenWidth <= 768) numDays = 1;
      if (this.screenWidth > 768 && this.screenWidth <= 1000) numDays = 2;
      if (this.screenWidth > 1000 && this.screenWidth <= 1200) numDays = 3;
      if (this.screenWidth > 1200 && this.screenWidth <= 1500) numDays = 4;
      if (this.screenWidth >= 1500) numDays = 5;
    }
    return numDays;
  }

  @computed
  get displayHelp() {
    return moment(this.viewDate).format("YYYY-MM-DD");
  }

  @computed
  get days() {
    const dates = [];
    for (let i = 0; i < this.displayDays; i++) {
      const date = new Date(this.viewDate.getTime() + i * 24 * 60 * 60 * 1000);
      date.setHours(0, 0, 0, 0);
      dates.push(date);
    }
    return dates;
  }

  @action
  setViewWindow() {
    let startDate = new Date(this.viewDate.getTime());
    if (!this.appointment) {
      return;
    }

    // If startDate is before today, set to today
    if (startDate < new Date()) {
      startDate = new Date();
    }

    // Add days to startDate
    const endDate = moment(startDate).add(this.displayDays, "days").toDate();

    this.timeslotStartDate = startDate;
    this.timeslotEndDate = endDate;

    const data = {
      locationId: Number(this.appointment.locationId),
      reasonId: Number(this.appointment.reasonId),
      startDate: this.timeslotStartDate,
      endDate: this.timeslotEndDate,
    };

    this.application.domain.loadTimeslots(data);
  }

  @action
  setLocationId(locationId: number) {
    this.selectedLocationId = locationId;
    this.application.domain.loadTimeslots({
      locationId: this.selectedLocationId,
      reasonId: this.selectedReasonId,
      startDate: this.timeslotStartDate,
      endDate: this.timeslotEndDate,
    });
  }

  @action
  setSelectedBayId(bayId: number) {
    this.selectedBayId = bayId;
  }

  @action
  setSelectedReasonId(typeId: number) {
    const oldTypeId = this.selectedReasonId;
    // Reset if changing type
    if (typeId !== oldTypeId) {
      this.startTime = 0;
      this.endTime = 0;
    }
    this.selectedReasonId = typeId;
    this.application.domain.loadTimeslots({
      locationId: this.selectedLocationId,
      reasonId: this.selectedReasonId,
      startDate: this.timeslotStartDate,
      endDate: this.timeslotEndDate,
    });
  }

  @action
  setStartDate(date: Date) {
    this.startDate = date;
  }

  @action
  setStartTime(time: number) {
    this.startTime = time;
  }

  @action
  setEndTime(time: number) {
    this.endTime = time;
  }

  @action
  setGuests(guests: number) {
    this.guests = guests;
  }

  @computed
  get finalizeButtonLabel() {
    let label = "Book Now";
    if (this.application.session.userDoc?.membership === "") {
      label = "Finalize";
    }
    if (this.calendarId > 0) {
      label = "Reschedule";
    }
    if (this.due) {
      label = `${label} & Pay`;
    }
    return label;
  }

  @computed
  get startTimeDisplay() {
    if (this.startTime <= 0) return "00:00";
    return moment(this.startDate)
      .startOf("date")
      .add(this.startTime / 100, "hours")
      .format("h:mm A");
  }

  @computed
  get endTimeDisplay() {
    if (!this.endTime) return "00:00";
    return moment(this.startDate)
      .startOf("date")
      .add(this.endTime / 100, "hours")
      .format("h:mm A");
  }

  @flow
  *updateAppointment(): FlowType {
    // Make sure the user's information is loaded
    if (!this.application.session.userDoc) return;
    this.rescheduling = true;
    this.error = null;
    const verifyData: IValidateAppointment = {
      membership: this.application.session.userDoc.membership,
      date: moment(this.startDate).format("YYYY-MM-DD"),
      startTime: String(this.startTime),
      appointments: String(this.appointments.length),
    };
    // Book appointment here
    const data: IAppointment = {
      calendarId: this.calendarId,
      clientId: Number(this.application.session.userDoc.clientId),
      staffId: Number(this.selectedBayId),
      startDate: moment(this.startDate).format("YYYY-MM-DD"),
      startTime: this.startTime,
      endTime: this.endTime,
    };

    try {
      // Verify the appointment is still available
      const verify = yield CloudAPI.validateAppointment(
        this.application,
        verifyData
      );
      if (verify.error) {
        throw new Error(verify.error);
      }
      // Book the appointment
      const results = yield CloudAPI.updateAppointment(this.application, data);
      yield this.application.domain.loadAppointments();
      if (results.appointment) {
        this.setAppointment(results.appointment);
      }
      this.rescheduling = false;
    } catch (error) {
      console.error(error);
      this.error = error.error;
      this.rescheduling = false;
    }
  }

  @flow
  *setDefaults(): FlowType {
    if (this.selectedLocationId === -1 || this.selectedReasonId === -1) {
      yield this.application.domain.loadApplicationConfigOnce();
      this.setLocationId(
        this.application.domain.applicationConfig.data?.locations[0]
          .locationId || -1
      );
      this.setSelectedReasonId(
        this.application.domain.applicationConfig.data?.types[0].reasonId || -1
      );
    }
  }

  timeslotAvailable(startDate: string, startTime: number, staffId: number) {
    const key = `${startDate}.${startTime}.${staffId}`;
    return key in this.timeslots ? true : false;
  }

  matchesClientAppointment(
    startDate: string,
    startTime: number,
    staffId: number
  ) {
    const key = `${startDate}.${startTime}.${staffId}`;
    if (!this.appointments) return false;
    return key in this.appointments ? true : false;
  }

  matchesLocationAppointment(startDate: string, startTime: number) {
    const key = `${startDate}.${startTime}.${this.selectedLocationId}`;
    return key in this.appointmentsByLocation ? true : false;
  }

  insideBookingWindow(date: Date) {
    if (!this.application.session.membership?.window.endDate) return true;
    const day = moment(date);
    const lastBookableDay = moment(
      this.application.session.membership.window.endDate
    );
    return day.isSameOrBefore(lastBookableDay);
  }

  @computed
  get maxGuests() {
    if (!this.application.session.membership?.maxGuests) return 1;
    return this.application.session.membership?.maxGuests;
  }

  @computed
  get appointmentDateLabel() {
    if (this.startTime === 0) {
      return "Please select a time";
    }
    // If this.startDate is today, return "Today"
    if (moment(this.startDate).isSame(new Date(), "day")) {
      return "Today";
    }
    // If this.startDate is tomorrow, return "Tomorrow"
    const tomorrow = moment().add(1, "day");
    if (moment(this.startDate).isSame(tomorrow, "day")) {
      return "Tomorrow";
    }
    // Otherwise, return the date in the format "Monday, 1st January"
    return moment(this.startDate).format("dddd, MMMM Do");
  }

  @computed
  get exceededMaxAppointmentMessage(): string {
    let message = "";
    const openAppointments = this.application.ui.appointments.counts.open;
    const maxAppointments =
      this.application.session.membership?.maxAppointments || 0;
    const exceededMaxAppointments =
      Number(openAppointments) >= Number(maxAppointments);

    if (exceededMaxAppointments) {
      message = `Exceeded max appointment${
        maxAppointments > 1 ? "s" : ""
      } of ${maxAppointments}`;
    }
    return message;
  }

  @computed
  get restrictedBookingMessage(): string {
    let message = "Become a member to book on this day";
    if (!this.application.session.userDoc) {
      return message;
    }
    if (!this.application.session.userDoc.membership === null) {
      return message;
    }

    switch (
      String(this.application.session.userDoc?.membership).toLowerCase()
    ) {
      case "hogan":
        message = "Outside booking window for your membership";
        break;
      case "snead":
        message = "Outside booking window for your membership";
        break;
      case "nelson":
        message = "Outside booking window for your membership";
        break;
    }
    return message;
  }

  @computed
  get bays() {
    if (!this.location?.bays) return 0;
    return this.location?.bays.length;
  }

  @computed
  get readyToPay() {
    return this.startTime > 0 && this.endTime > 0;
  }

  @computed
  get appointmentCountByDate() {
    const appointments = this.application.domain.appointments;
    if (!appointments.length) {
      return {};
    }

    const appointmentsRekey: {
      [key: string]: number;
    } = {};

    appointments.forEach((appointment) => {
      const key = `${appointment.date}`;
      if (key in appointmentsRekey) {
        appointmentsRekey[key]++;
      } else {
        appointmentsRekey[key] = 1;
      }
    });

    return appointmentsRekey;
  }

  @computed
  get appointments(): {
    [key: string]: boolean;
  } {
    const appointments = this.application.domain.appointments;
    if (!appointments.length) {
      return {};
    }

    const appointmentsRekey: {
      [key: string]: boolean;
    } = {};

    appointments.forEach((appointment) => {
      const key = `${appointment.date}.${appointment.startTime}.${appointment.staffId}`;
      appointmentsRekey[key] = true;
    });

    return appointmentsRekey;
  }

  @computed
  get appointmentsByLocation(): {
    [key: string]: boolean;
  } {
    const appointments = this.application.domain.appointments;
    if (!appointments.length) {
      return {};
    }

    const appointmentsRekey: {
      [key: string]: boolean;
    } = {};

    appointments.forEach((appointment) => {
      const key = `${appointment.date}.${appointment.startTime}.${appointment.locationId}`;
      appointmentsRekey[key] = true;
    });

    return appointmentsRekey;
  }

  @computed
  get location(): ILocation {
    if (!this.application.domain.applicationConfig.data) {
      return this.emptyLocation;
    }
    const location =
      this.application.domain.applicationConfig.data.locations.find(
        (location) => {
          if (!this.appointment) return false;
          return location.locationId === this.appointment.locationId;
        }
      );
    if (!location) return this.emptyLocation;
    return location;
  }

  @computed
  get timeslots(): ITimeslotsByDate {
    const timeslots = this.application.domain.timeslots;
    if (!timeslots.length) {
      return {};
    }

    const availableTimeSlots: {
      [key: string]: boolean;
    } = {};

    timeslots.forEach((slot) => {
      const key = `${slot.date}.${slot.startTime}.${slot.staffId}`;
      availableTimeSlots[key] = true;
    });

    return availableTimeSlots;
  }

  @computed
  get timeslotCountByDate(): ITimeslotsByDate {
    const timeslots = this.application.domain.timeslots;
    if (!timeslots.length) {
      return {};
    }

    const availableTimeSlots: {
      [key: string]: boolean;
    } = {};

    timeslots.forEach((slot) => {
      const key = `${slot.date}`;
      availableTimeSlots[key] = true;
    });

    return availableTimeSlots;
  }

  @computed
  get bay() {
    if (this.selectedBayId < 0) {
      return this.location?.bays[0] ?? null;
    }

    return (
      this.location?.bays.find((b) => b.staffId === this.selectedBayId) ?? null
    );
  }

  @computed
  get type() {
    if (this.selectedReasonId < 0) {
      return this.application.domain.applicationConfig.data?.types[0] ?? null;
    }

    return (
      this.application.domain.applicationConfig.data?.types.find(
        (l) => l.reasonId === this.selectedReasonId
      ) ?? null
    );
  }

  @computed
  get amCount() {
    const count = this.hours.filter((value) => value <= 1200).length;
    return count;
  }

  @computed
  get pmCount() {
    const count = this.hours.filter((value) => value > 1200).length;
    return count;
  }

  @computed
  get hours() {
    if (
      !this.application.session.membership?.startHour &&
      !this.application.session.membership?.endHour
    ) {
      return [1300, 1400, 1500, 1600, 1700];
    }

    const startHour = this.application.session.membership.startHour;
    const endHour = this.application.session.membership.endHour;

    const hoursArray = [];
    for (let i = startHour; i <= endHour; i += 100) {
      hoursArray.push(i);
    }

    return hoursArray;
  }

  @computed
  get paymentId() {
    return this.application.domain.selectedPaymentId;
  }
}
