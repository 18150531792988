import { StripeCardElementOptions } from "@stripe/stripe-js";
import { action, computed, makeObservable, observable } from "mobx";
import { ApplicationStore } from "../application.store";
import { Store } from "../store";

export class PaymentUI extends Store {
  @observable cardOptions: StripeCardElementOptions = {
    iconStyle: "solid",
    classes: {
      base: "StripeCardElement",
      complete: "StripeCardElement--complete",
      empty: "StripeCardElement--empty",
      focus: "StripeCardElement--focus",
      invalid: "StripeCardElement--invalid",
      webkitAutofill: "StripeCardElement--webkit-autofill",
    },
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#87bbfd" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  @observable cardFieldOptions: StripeCardElementOptions = {
    iconStyle: "solid",
    classes: {
      base: "StripeCardElement",
      complete: "StripeCardElement--complete",
      empty: "StripeCardElement--empty",
      focus: "StripeCardElement--focus",
      invalid: "StripeCardElement--invalid",
      webkitAutofill: "StripeCardElement--webkit-autofill",
    },
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#4874E4",
        fontWeight: 700,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "18px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#CDD1D5" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#EC1958",
      },
    },
  };

  @observable cardOptionsDefault: StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "20px",
        "::placeholder": {
          color: "#030F1E",
        },
      },
      invalid: {
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        color: "#EC1958",
        iconColor: "#EC1958",
      },
    },
  };

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }

  @action
  reset() {
    // Reset variables
  }

  @computed
  get styles() {
    return this.cardOptionsDefault;
  }
}
