import { makeObservable, observable } from "mobx";
import { ApplicationStore } from "../application.store";
import { Store } from "../store";
import { AccountUI } from "./account.ui";
import { AppointmentsUI } from "./appointments.ui";
import { LoginUI } from "./login.ui";
import { PaymentUI } from "./payment.ui";
import { RescheduleUI } from "./reschedule.ui";
import { ScheduleUI } from "./schedule.ui";
import { SignupUI } from "./signup.ui";
import { VideoUI } from "./video.ui";

/**
 * This is the main container of UI specific state management. This should
 * mostly be transformations of the domain store into something the UI can
 * understand easily.
 *
 * This can also contain application UI state which affects the transformation
 * of domain data, such as search or sort preferences or other user input state.
 */
export class UIStore extends Store {
  @observable schedule = new ScheduleUI(this.application);
  @observable reschedule = new RescheduleUI(this.application);
  @observable signup = new SignupUI(this.application);
  @observable login = new LoginUI(this.application);
  @observable appointments = new AppointmentsUI(this.application);
  @observable account = new AccountUI(this.application);
  @observable payment = new PaymentUI(this.application);
  @observable video = new VideoUI(this.application);

  constructor(app: ApplicationStore) {
    super(app);
    makeObservable(this);
  }
}
