import { observer } from "mobx-react";
import "navigation.scss";
import React, { FC, ReactNode } from "react";
import { ENV } from "../env";
import { Application } from "../store";
import IconCalendar from "../svg/calendar";
import IconLogo from "../svg/logo";
import IconPerson from "../svg/person";
import IconVideo from "../svg/video";
import Button from "./button";

interface INavigationProps {
  onChange?(): void;
  children?: ReactNode;
  className?: string;
}

const Navigation: FC<INavigationProps> = observer(({ className = "" }) => (
  <div className={`Navigation ${className}`}>
    <div className="Logo">
      <IconLogo width={40} height={40} />
    </div>
    <div className="Navigation__Content">
      <div className="Navigation__Content-left">
        <div className="Navigation__Content-links">
          <Button
            route={ENV.routes.reservations}
            label="Reservations"
            style="nav"
            leftArrow={true}
            leftIcon={<IconCalendar />}
          />
          <Button
            route={ENV.routes.videos}
            label="Video"
            style="nav"
            leftArrow={true}
            leftIcon={<IconVideo />}
          />
        </div>
      </div>
      <div className="Navigation__Content-right">
        <Button
          style="nav"
          inline={true}
          route={ENV.routes.account}
          label={Application.session.displayName}
          rightArrow={true}
          rightIcon={<IconPerson />}
        />
      </div>
    </div>
  </div>
));

export default Navigation;
