import "check-button.scss";
import { observer } from "mobx-react";
import React, { FC } from "react";
import IconCheckmark from "../../svg/checkmark";

interface ICheckButtonProps {
  onClick?(): void;
  className?: string;
  title: string;
  subtitle?: string;
  selected?: boolean;
  active?: boolean;
  clickable?: boolean;
}

const CheckButton: FC<ICheckButtonProps> = ({
  onClick,
  className = "",
  title = "",
  subtitle = "",
  selected = false,
  active = false,
  clickable = true,
}) => {
  const classNames = () => {
    const classes = new Array();
    classes.push("CheckButton");
    if (className) classes.push(className);
    if (selected) classes.push("selected");
    if (active) classes.push("active");
    if (!clickable) classes.push("not-clickable");
    return classes.join(" ");
  };
  const handleClick = () => {
    if (!clickable) return;
    onClick?.();
  };
  return (
    <div onClick={handleClick} className={classNames()}>
      <div className="CheckButton-name">{title}</div>
      {subtitle && <div className="CheckButton-address">{subtitle}</div>}
      <IconCheckmark width={20} height={20} />
    </div>
  );
};

export default observer(CheckButton);
