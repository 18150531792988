import "bay-circle.scss";
import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import { ITimetapStaff } from "../../store/interfaces";
import { when } from "../../util/when";
import CloseButton from "../close-button";
import { Modal } from "../modal";

interface IBayCircleProps {
  onClick?(bay: ITimetapStaff): void;
  className?: string;
  name: string;
  bay: ITimetapStaff;
}

const BayCircle: FC<IBayCircleProps> = observer(
  ({ onClick, className = "", bay }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleClick = () => {
      setModalOpen(true);

      // Pass the clicked bay if onClick event provided
      onClick?.(bay);
    };

    const closeModal = (e?: React.MouseEvent) => {
      if (e) {
        e.preventDefault();
      }

      setModalOpen(false);
    };

    return (
      <div className={`BayCircle ${className}`}>
        {when(
          modalOpen,
          <Modal onClose={closeModal}>
            <div className="ModalContainer">
              <CloseButton onClick={closeModal} />
              <div className="ModalContainer-title">Bay information</div>
              <div className="ModalContainer-location">
                <div className="ModalContainer-location-name">{bay.name}</div>
                <div className="ModalContainer-location-description">
                  {bay.description}
                </div>
                <div className="ModalContainer-location-address">
                  Located at <span>CGC {bay.locationName}</span>
                </div>
              </div>
              <div className="ModalContainer-image">
                <img src={bay.floorplan} />
              </div>
            </div>
          </Modal>
        )}
        <div onClick={handleClick} className="BayCircle-click">
          <div className="BayCircle-circle">{bay.name.substring(1, 0)}</div>
          <div className="BayCircle-name">{bay.name}</div>
        </div>
      </div>
    );
  }
);

export default BayCircle;
