import React from "react";
import { useNavigate } from "react-router-dom";
7;
import IconLogo from "app/client/svg/logo";
import { observer } from "mobx-react";
import { ENV } from "../../../client/env";

/**
 * Inform the user that the password reset email has been sent
 */
export const PasswordResetSentPage = observer(() => {
  const navigate = useNavigate();
  return (
    <div className="LoginPage FormPage full-screen">
      <div className="FormPage-left"></div>
      <div className="FormPage-right">
        <div className="FormPage-form">
          <div className="FormPage-form-title">
            <div className="FormPage-form-logo">
              <IconLogo />
            </div>
            <h1>Password reset link sent</h1>
          </div>
        </div>
        <div className="FormPage-links">
          <a onClick={() => navigate(ENV.routes.login)}>Back to login</a>
        </div>
        <div className="FormPage-copyright">
          Copyright © City Golf Club 2023.
        </div>
      </div>
    </div>
  );
});
