import Button from "app/client/ui/button";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../env";
import { Application } from "../../store";
import { when } from "../../util/when";
import Appointment from "../account/appointment";

// SCSS
import "./confirmation.scss";

/**
 * Book a new reservation
 */
export const RescheduleConfirmationPage = observer(() => {
  const navigate = useNavigate();

  useEffect(
    () => () => {
      Application.ui.reschedule.reset();
    },
    []
  );

  return (
    <div className="Confirmation">
      <div className="Confirmation-center">
        {when(
          !Application.ui.reschedule.error,
          <>
            <div className="Confirmation-title">
              {Application.ui.reschedule.rescheduling
                ? "Rescheduling..."
                : "Appointment rescheduled"}
            </div>
            <div
              className={`Confirmation-appointment ${
                Application.ui.reschedule.rescheduling ? "gradient-border" : ""
              }`}
            >
              <Appointment
                allowChange={false}
                appointment={Application.ui.reschedule.appointment}
              />
            </div>
            <div className="Confirmation-button">
              <Button
                onClick={() => navigate(ENV.routes.reservations)}
                label="Back to account"
                className="gold"
                rightArrow
              />
            </div>
          </>
        )}
        {when(
          Application.ui.reschedule.error,
          <div className="Confirmation__Error">
            <div className="Confirmation__ErrorMessage">
              {Application.ui.reschedule.error}
            </div>
            <div className="Confirmation__Actions">
              <Button
                onClick={() => navigate(ENV.routes.reservations)}
                label="Back to account"
                className="gold"
                rightArrow
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
