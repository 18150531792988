import "loading.scss";
import React, { FC } from "react";

interface IButtonProps {
  className?: string;
  absolute?: boolean;
}

const LoadingAnimation: FC<IButtonProps> = ({
  className = "",
  absolute = true,
}) => {
  const loadingClasses = () => {
    const classes = new Array("LoadingAnimation");
    if (className) classes.push(className);
    if (absolute) classes.push("absolute");
    return classes.join(" ");
  };
  return (
    <div className={loadingClasses()}>
      <div className="LoadingAnimation-circles">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
