import React, { FC } from "react";

interface IPlayIconProps {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
}

const PlayIcon: FC<IPlayIconProps> = ({
  className = "",
  width = 40,
  height = 40,
  opacity = 1,
  fill = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    className={`Icon__Calendar color-fill ${className}`}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={fill} opacity={opacity}>
      <path d="M13.8594 8.35938L32.1406 20L13.8594 31.6406V8.35938Z" />
    </g>
  </svg>
);

export default PlayIcon;
