import { Application } from "app/client/store";
import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../env";
import { ILocation, IMembership } from "../../store/interfaces";
import BackButton from "../../ui/back-button";
import Button from "../../ui/button";
import CloseButton from "../../ui/close-button";
import LoadingAnimation from "../../ui/loading";
import { Modal } from "../../ui/modal";
import CheckButton from "../../ui/schedule/check-button";
import { when } from "../../util/when";
import FinalizeMembershipCheckout from "./finalize";

// Styles
import "./manage.scss";
import PricePicker from "./price-picker";
import MembershipVariables from "./variables";

/**
 * An example of a page being made
 */
export const ManageMembershipPage = observer(() => {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleFinalize = () => {
    if (!Application.ui.account.membership) {
      // If becoming a member for the first time
      newMembership();
    } else {
      // If already a member and changing
      changeMembershipModal();
    }
  };

  const newMembership = () => {
    if (!Application.ui.account.readyToCheckout) return;
    if (!Application.ui.account.price) return;
    if (!Application.ui.account.stripeCustomerId) return;
    if (!Application.ui.account.selectedMembership) return;
    const priceId = Application.ui.account.price.id;
    const customerId = Application.ui.account.stripeCustomerId;
    const description = `${Application.ui.account.selectedMembership.toUpperCase()} membership`;
    Application.domain.loadPaymentIntent(priceId, customerId, description);
    setPaymentModalOpen(true);
  };

  const changeMembershipModal = () => {
    if (!Application.ui.account.price) return;
    setChangeModalOpen(true);
  };

  const changeMembership = async () => {
    await Application.ui.account.updateSubscription();
    setSuccess(true);
  };

  const closeModal = () => {
    Application.domain.resetPaymentIntent();
    setPaymentModalOpen(false);
    setChangeModalOpen(false);
  };

  const registered = () => {
    navigate(ENV.routes.account);
  };

  const isSelected = () => {
    if (!Application.ui.account.plan) return false;
    const title = Application.ui.account.plan.title.toLowerCase();
    return Application.ui.account.selectedMembership === title;
  };

  const membershipSelected = () => {
    if (
      Application.ui.account.selectedMembership &&
      Application.ui.account.selectedMembership !== ""
    ) {
      return true;
    }
    return false;
  };

  const finalizeClasses = () => {
    const classes = new Array();
    if (Application.ui.account.readyToCheckout === true) classes.push("active");
    if (Application.ui.account.readyToCheckout === false) {
      classes.push("deactivated");
    }
    return classes.join(" ").trim();
  };

  const pageTitle = () => {
    const label = "Upgrade or change membership";
    if (!Application.ui.account.selectedMembership) return label;
    const prefix = isSelected() ? "Change to" : "Upgrade to";
    const title = membershipSelected()
      ? Application.ui.account.selectedMembership.toUpperCase()
      : "";
    return (
      <div className="ManageMembership__Title-text">
        {prefix}
        <span>{title}</span>
      </div>
    );
  };

  const isAll = () => Application.ui.account.selectedAllLocations;

  const selectLocation = (location: ILocation) => {
    Application.ui.account.setSelectedLocationId(location.locationId);
  };

  // Reset things when appropriate
  useEffect(() => {
    Application.domain.resetPaymentIntent();

    // Unmount
    return () => {
      Application.ui.account.reset();
    };
  }, []);

  return (
    <div className={`ManageMembership`}>
      {when(Application.domain.testMode, <MembershipVariables />)}
      {when(
        paymentModalOpen,
        <Modal onClose={closeModal}>
          <div className="ModalContainer">
            <CloseButton onClick={closeModal} />
            <div className="ModalContainer-title">Finalize membership</div>
            <div className="ModalContainer-list">
              <FinalizeMembershipCheckout onComplete={registered} />
            </div>
          </div>
        </Modal>
      )}
      {when(
        changeModalOpen,
        <Modal onClose={closeModal}>
          {when(
            success,
            <div className="ModalContainer">
              <div className="ModalContainer-title">Successfully updated</div>
              <div className="ModalContainer-text">
                Your membership has been successfully updated according to your
                selection.
              </div>
              <div className="ModalConatiner-button">
                <Button label="Return to account" onClick={registered} />
              </div>
            </div>
          )}
          {when(
            !success,
            <div className="ModalContainer">
              {when(
                Application.ui.account.processing,
                <LoadingAnimation absolute={true} />
              )}
              <CloseButton onClick={closeModal} />
              <div className="ModalContainer-title">
                Confirm membership change
              </div>
              <div className="ModalContainer-text">
                Please confirm the following changes are accurate. If you are
                upgrading your membership, you will be charged the difference
                between your current membership and your new membership. If you
                are downgrading, you will be credited the difference.
              </div>
              <div className="ModalContainer-values">
                <div className="KeyValue">
                  <div className="KeyValue-title">Current membership</div>
                  <div className="KeyValue-items">
                    <span className="uppercase">
                      {Application.ui.account.membership}
                    </span>
                    <span>
                      {`${Application.ui.account.formattedSubscriptionPrice} / ${Application.ui.account.subscriptionInterval}`}
                    </span>
                  </div>
                </div>
                <div className="KeyValue">
                  <div className="KeyValue-title">New membership</div>
                  <div className="KeyValue-items">
                    <span className="uppercase">
                      {Application.ui.account.selectedMembership}
                    </span>
                    <span>
                      {`${Application.ui.account.formattedAmount} / ${Application.ui.account.billingCycle}`}
                    </span>
                  </div>
                </div>
              </div>
              <Button onClick={changeMembership} label="Confirm" block={true} />
            </div>
          )}
        </Modal>
      )}
      {/* Top bar */}
      <div className="ManageMembership__Title">
        <div className="ManageMembership__Title-left">
          <BackButton route={ENV.routes.account} label="Back to Account" />
        </div>
        <div className="ManageMembership__Title-center">{pageTitle()}</div>
        <div className="ManageMembership__Title-right">
          <Button
            label={"Finalize"}
            rightArrow={true}
            inline
            onClick={handleFinalize}
            className={finalizeClasses()}
          />
        </div>
      </div>
      {/* Content */}
      <div className="ManageMembership__Content">
        <div className="ManageMembership__Content-top">
          <div className="Locations">
            <div className="Locations-title">CHOOSE MEMBERSHIP LOCATION</div>
            <div className="Locations-description">
              Choose to become a member at one of our locations or become a
              member at every location
            </div>
            <div className="Locations-list">
              {Application.ui.account.locations?.map((item, index) => {
                const isMember = Application.ui.account.isMemberOfLocation(
                  item.locationId
                );
                // If there is only one location, select it
                if (Application.ui.account.locations?.length === 1) {
                  selectLocation(item);
                }
                const memberOfAnyLocation =
                  Application.ui.account.memberOfAnyLocation;
                const isSelected =
                  Application.ui.account.selectedLocationId === item.locationId
                    ? true
                    : false;
                return (
                  <CheckButton
                    key={index}
                    title={item.name}
                    subtitle={item.address}
                    active={isMember}
                    onClick={() => selectLocation(item)}
                    selected={isAll() || isSelected}
                    clickable={memberOfAnyLocation || isAll() ? false : true}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="ManageMembership__Content-bottom">
          {Application.ui.account.memberships.map((plan, index) => (
            <MembershipPlan plan={plan} key={index} />
          ))}
        </div>
      </div>
      {/* Mobile Finalize */}
      <div className="ManageMembership__Mobile">
        <Button
          label={"Finalize"}
          rightArrow={true}
          inline
          onClick={handleFinalize}
          className={finalizeClasses()}
        />
      </div>
    </div>
  );
});

interface IMembershipPlan {
  plan: IMembership;
}

const MembershipPlan: FC<IMembershipPlan> = observer(({ plan }) => {
  // Set the default period
  const period = plan.monthlyPrice ? "monthly" : "annually";

  const getMembershipClasses = () => {
    const classes = new Array();
    const title = plan.title.toLowerCase();
    classes.push("MembershipPlan");
    if (Application.ui.account.membership === title) classes.push("subscribed");
    if (Application.ui.account.selectedMembership === title) {
      classes.push("selected");
    }
    return classes.join(" ").trim();
  };

  const buttonLabel = () => {
    // return `${plan.level} - ${Application.ui.account.level}`;
    let label = "Upgrade Now";
    if (Application.ui.account.level) {
      const level = Number(plan.level);
      const currentLevel = Number(Application.ui.account.level);
      if (level > currentLevel) label = "Upgrade Now";
      if (level < currentLevel) label = "Downgrade Now";
    }
    if (isSubscribed()) label = "Subscribed";
    return label;
  };

  const getButtonClasses = () => {
    const classes = new Array();
    const title = plan.title.toLowerCase();
    if (Application.ui.account.membership === title) classes.push("active");
    if (Application.ui.account.selectedMembership === title) {
      classes.push("selected");
    }
    return classes.join(" ").trim();
  };

  const isSubscribed = () => {
    const title = plan.title.toLowerCase();
    return Application.ui.account.membership === title;
  };

  const isSelected = () => {
    const title = plan.title.toLowerCase();
    return Application.ui.account.selectedMembership === title;
  };

  const selectMembership = () => {
    if (isSubscribed()) return;
    Application.ui.account.setSelectedMembership(plan.title);

    const newPeriod = Application.ui.account.selectedPeriods[plan.title]
      ? Application.ui.account.selectedPeriods[plan.title]
      : period;
    Application.ui.account.setSelectedPeriod(plan.title, newPeriod);
  };

  return (
    <div className={getMembershipClasses()}>
      <div className="MembershipPlan-title">
        <div className="MembershipPlan-title-left">{plan.subtitle}</div>
        <div className="MembershipPlan-title-right">
          <PricePicker
            annually={plan.annualPrice}
            monthly={plan.monthlyPrice}
            title={plan.title}
          />
        </div>
      </div>
      <div className="MembershipPlan-features">
        <ul>
          {plan.features.map((feature: string, index: number) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
      <div className="MembershipPlan-button">
        <CheckButton
          onClick={selectMembership}
          title={buttonLabel()}
          selected={isSelected()}
          className={getButtonClasses()}
        />
      </div>
    </div>
  );
});
